import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { forwardRef, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TextField, ThemeProvider, createTheme } from '@mui/material';
import { ArrowBackIosNew } from '@mui/icons-material';
import { ref } from 'yup';


interface Place {
    place_id: number;
    display_name: string;
    name: string;
    state: string;
    country: string;
    lat: string;
    long: string;
  }

function sleep(duration: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
}

export const LocationInput = (props:any)=> {
    let active = true;
    const [value, setValue] = useState<Place | null>(null) //<null | string>;
    const [searchText, setSearchText] = useState("") 
    const [options, setOptions] = useState([]); //<readonly Place[]>
    const [open, setOpen] = useState(false);

    const {data, error , isLoading, refetch} = useQuery(
        {
        queryKey:['place', searchText],
        queryFn: () =>  fetch(`https://nominatim.openstreetmap.org/search?q=${searchText}&format=json&addressdetails=1`).then((res) => res.json()),
        enabled: false && !!searchText || searchText != '',
        staleTime: Infinity
        }
    )

    const loading = open && (data == undefined || data?.length == 0);

    const handleOnChange  = (newInputValue: string ) => { 

        setSearchText(newInputValue);
        setOpen(true);
        // if (!loading) {
        // return undefined;
        // }
        (async () => {
        await refetch()
        //const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');
        //await sleep(1e3); // For demo purposes.
        //const countries = await response.json();
        
        //if (active) {
            //setPlaces(data);
           //if (data != undefined) {setPlaces([data])}
        //}
        //active = false;
       })();
       
      }

 
    console.log(searchText)
    console.log(value) 


    //if (options?.length != 0) {console.log(options)}
    
    useEffect(() => {
  
            let active = true;
            
            // if (!loading) {
            // return undefined;
            // }
        
            (async () => {
            //await sleep(1e3); //For demo purposes.
        
            if (active) {
                setOptions(data);
                
            }
            })();
        
            return () => {
            active = false;
            };
        }, [loading, 'places']);

        
        
        useEffect(() => {
            if (!open) {
            setOptions([]);
            }
        }, [open]);
        

      return (
        // <ThemeProvider theme={theme}>
          <Autocomplete
            id="custom-input-demo"
            options={options === undefined ? [] : options}
            open={open}  
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) => option === value}
            getOptionLabel={(option: any) => option.display_name}
            noOptionsText="No locations"
            loading={loading}
            popupIcon={null}
            filterOptions={(x) => x == undefined ? []  : x }
            onChange={(event: any, newValue: Place | null) => {
                props.setPlace(newValue ? newValue:        {
                    place_id: data.place_id,
                    display_name: data.display_name,
                    name: data.name,
                    state: data.address.state,
                    country: data.address.country,
                    lat: data.lat,
                    long: data.lon
                  })
                // setValue(newValue ? [newValue, ...data] : data );
                // setValue(newValue);
              }}
            onInputChange={(event, newInputValue) => {
               handleOnChange(newInputValue)
              }}
            
            renderInput={(params) => (
            <div ref={params.InputProps.ref}>
            
                <TextField {...params} 
                required 
                sx={{ m: 1 , width: "100%"}}
                InputLabelProps={{ shrink: true }}
                label='Location'
                placeholder='Search'
                type="text"
                value={searchText}
                className={props.className}

                 />
              </div>
            )}
          />
          // </ThemeProvider>
      );
      
      
    }


  //   const theme = createTheme({
  //     components: {
  //       // Name of the component
  //       MuiTextField:{
  //         styleOverrides:{
  //           root:{
  //             margin: '10px'
  //           }
  //       }
  //       },
  //       MuiOutlinedInput: {
  //         styleOverrides: {
  //           // Name of the slot
  //           root: {
  //             // Some CSS
  //             backgroundColor: 'rgba(255, 255, 255, 1)',
              
  
  //           },
  //         },
  //       },
  //       MuiAutocomplete: {
  //         styleOverrides: {
  //           // Name of the slot
  //           root: {
  //             "& .MuiOutlinedInput-root":{
  //             // Some CSS
  //             padding: '0px !important', 
  //           }
  //         },
  //         input:{
  //           paddingLeft: '8px !important',
  //           paddingRight: '3px !important',
  //           paddingTop: '8px !important',
  //           paddingBottom: '8px !important',
  //           minWidth: '30px !important'
  //         }
  //       },
  //   },
  // }
  // });