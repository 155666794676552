
import '../../css/chart.css'
import '../../css/chart-info.css'
import { useState } from "react"
import { NewForm } from "./new-form"
import { PedroForm } from "./pedro-form"
import { Chart } from "../../components/chart"




interface ChartSummaryProps{
    name: string;
    utc: string;
    local: string;
    location: string;
    chart_type: string;
    lat: string;
    long: string;
    timezone: string;

}

interface SolarReturnSummaryProps{
    utc: string;
    local: string;
    location: string;
    solar_return: string;
}

export const NewChart = () => {


    
    const [ isVisible, setIsVisible] = useState(true)

    const [ isSolarReturn, setIsSolarReturn] = useState(false)

    const [chartSummary, setChartSummary] = useState({
        name: '',
        utc: '',
        local: '',
        location: '',
        chart_type: '',
        lat: '',
        long: '',
        timezone: ''
    })
    
    const [solarReturnSummary, setSolarReturnSummary] = useState({
        utc: '',
        local: '',
        location: '',
        solar_return: '',
    })


    const getSuccess = (success: boolean) => { 
        setIsVisible(false)
    }

    const getChartSummary = (chartSummary: ChartSummaryProps) => {
        setChartSummary(chartSummary)
    }

    const getSolarReturnSummary = (solarReturnSummary: SolarReturnSummaryProps) => {
        setSolarReturnSummary(solarReturnSummary)
    }


    const [DegreeSym, setDegreeSym] = useState('0')

    const[signVals, setSignVals] = useState({
        AriSignX : '-7',
        AriSignY : '604',
        TauSignX : '121',
        TauSignY : '828',
        GemSignX : '345',
        GemSignY : '957',
        CanSignX : '604',
        CanSignY : '957',
        LeoSignX : '828',
        LeoSignY : '828',
        VirSignX : '957',
        VirSignY : '604',
        LibSignX : '957',
        LibSignY : '345',
        ScoSignX : '828',
        ScoSignY : '121',
        SagSignX : '604',
        SagSignY : '-7',
        CapSignX : '345',
        CapSignY : '-7',
        AquSignX : '121',
        AquSignY : '121',
        PisSignX : '-7',
        PisSignY : '345'
    })

    const [moVals, setMoVals] = useState({
        MoSym:"",
        MoPlanetX: "",
        MoPlanetY: "",
        MoSign: "",
        MoSignSym: "",
        MoSignX: "",
        MoSignY: "",
        MoDegree: "",
        MoDegreeX: "",
        MoDegreeY: "",
        MoMinute: "",
        MoMinuteX: "",
        MoMinuteY: "",

        })

    const [meVals, setMeVals] = useState({
        MeSym:"",
        MePlanetX: "",
        MePlanetY: "",
        MeSign: "",
        MeSignSym: "",
        MeSignX: "",
        MeSignY: "",
        MeDegree: "",
        MeDegreeX: "",
        MeDegreeY: "",
        MeMinute: "",
        MeMinuteX: "",
        MeMinuteY: "",
        MeRX: true,
        MeRXX: "",
        MeRXY: "",
        })
    const [veVals, setVeVals] = useState({
        VeSym:"",
        VePlanetX: "",
        VePlanetY: "",
        VeSign: "",
        VeSignSym: "",
        VeSignX: "",
        VeSignY: "",
        VeDegree: "",
        VeDegreeX: "",
        VeDegreeY: "",
        VeMinute: "",
        VeMinuteX: "",
        VeMinuteY: "",
        VeRX: false,
        VeRXX: "",
        VeRXY: ""
    })
    const [suVals, setSuVals] = useState({
        SuSym:"",
        SuPlanetX: "",
        SuPlanetY: "",
        SuSign: "",
        SuSignSym: "",
        SuSignX: "",
        SuSignY: "",
        SuDegree: "",
        SuDegreeX: "",
        SuDegreeY: "",
        SuMinute: "",
        SuMinuteX: "",
        SuMinuteY: "",
        SuRX: "",
        SuRY: ""
    })
    const [maVals, setMaVals] = useState({
        MaSym:"",
        MaPlanetX: "",
        MaPlanetY: "",
        MaSign: "",
        MaSignSym: "",
        MaSignX: "",
        MaSignY: "",
        MaDegree: "",
        MaDegreeX: "",
        MaDegreeY: "",
        MaMinute: "",
        MaMinuteX: "",
        MaMinuteY: "",
        MaRX: false,
        MaRXX: "",
        MaRXY: ""
    })

    const [juVals, setJuVals] = useState({
        JuSym:"",
        JuPlanetX: "",
        JuPlanetY: "",
        JuSign: "",
        JuSignSym: "",
        JuSignX: "",
        JuSignY: "",
        JuDegree: "",
        JuDegreeX: "",
        JuDegreeY: "",
        JuMinute: "",
        JuMinuteX: "",
        JuMinuteY: "",
        JuRX: false,
        JuRXX: "",
        JuRXY: ""
    })
    const [saVals, setSaVals] = useState({
        SaSym:"",
        SaPlanetX: "",
        SaPlanetY: "",
        SaSign: "",
        SaSignSym: "",
        SaSignX: "",
        SaSignY: "",
        SaDegree: "",
        SaDegreeX: "",
        SaDegreeY: "",
        SaMinute: "",
        SaMinuteX: "",
        SaMinuteY: "",
        SaRX: false,
        SaRXX: "",
        SaRXY: ""
    })
    const [urVals, setUrVals] = useState({
        UrSym:"",
        UrPlanetX: "",
        UrPlanetY: "",
        UrSign: "",
        UrSignSym: "",
        UrSignX: "",
        UrSignY: "",
        UrDegree: "",
        UrDegreeX: "",
        UrDegreeY: "",
        UrMinute: "",
        UrMinuteX: "",
        UrMinuteY: "",
        UrRX: false,
        UrRXX: "",
        UrRXY: ""
    })
    const [neVals, setNeVals] = useState({
        NeSym:"",
        NePlanetX: "",
        NePlanetY: "",
        NeSign: "",
        NeSignSym: "",
        NeSignX: "",
        NeSignY: "",
        NeDegree: "",
        NeDegreeX: "",
        NeDegreeY: "",
        NeMinute: "",
        NeMinuteX: "",
        NeMinuteY: "",
        NeRX: false,
        NeRXX: "",
        NeRXY: ""
    })
    const [plVals, setPlVals] = useState({
        PlSym:"",
        PlPlanetX: "",
        PlPlanetY: "",
        PlSign: "",
        PlSignSym: "",
        PlSignX: "",
        PlSignY: "",
        PlDegree: "",
        PlDegreeX: "",
        PlDegreeY: "",
        PlMinute: "",
        PlMinuteX: "",
        PlMinuteY: "",
        PlRX: false,
        PlRXX: "",
        PlRXY: ""
    })
    const [raVals, setRaVals] = useState({
        RaSym:"",
        RaPlanetX: "",
        RaPlanetY: "",
        RaSign: "",
        RaSignSym: "",
        RaSignX: "",
        RaSignY: "",
        RaDegree: "",
        RaDegreeX: "",
        RaDegreeY: "",
        RaMinute: "",
        RaMinuteX: "",
        RaMinuteY: "",
        RaRX: true,
        RaRXX: "",
        RaRXY: "",
        RXSym: ""
    })
    const [keVals, setKeVals] = useState({
        KeSym:"",
        KePlanetX: "",
        KePlanetY: "",
        KeSign: "",
        KeSignSym: "",
        KeSignX: "",
        KeSignY: "",
        KeDegree: "",
        KeDegreeX: "",
        KeDegreeY: "",
        KeMinute: "",
        KeMinuteX: "",
        KeMinuteY: "",
        KeRX:  true,
        KeRXX: "",
        KeRXY: ""
    })
    const [chVals, setChVals] = useState({
        ChSym:"",
        ChPlanetX: "",
        ChPlanetY: "",
        ChSign: "",
        ChSignSym: "",
        ChSignX: "",
        ChSignY: "",
        ChDegree: "",
        ChDegreeX: "",
        ChDegreeY: "",
        ChMinute: "",
        ChMinuteX: "",
        ChMinuteY: "",
        ChRX:false,
        ChRXX: "",
        ChRXY: ""
    })
    const [ascVals, setASCVals] = useState({
        ASCSym:"",
        ASCPlanetX: "",
        ASCPlanetY: "",
        ASCSign: "",
        ASCSignSym: "",
        ASCSignX: "",
        ASCSignY: "",
        ASCDegree: "",
        ASCDegreeX: "",
        ASCDegreeY: "",
        ASCMinute: "",
        ASCMinuteX: "",
        ASCMinuteY: "",

    })
    const [dscVals, setDSCVals] = useState({
        DSCSym:"",
        DSCPlanetX: "",
        DSCPlanetY: "",
        DSCSign: "",
        DSCSignSym: "",
        DSCSignX: "",
        DSCSignY: "",
        DSCDegree: "",
        DSCDegreeX: "",
        DSCDegreeY: "",
        DSCMinute: "",
        DSCMinuteX: "",
        DSCMinuteY: "",

    })
    const [icVals, setICVals] = useState({
        ICSym:"",
        ICPlanetX: "",
        ICPlanetY: "",
        ICSign: "",
        ICSignSym: "",
        ICSignX: "",
        ICSignY: "",
        ICDegree: "",
        ICDegreeX: "",
        ICDegreeY: "",
        ICMinute: "",
        ICMinuteX: "",
        ICMinuteY: "",

    })
    const [mcVals, setMCVals] = useState({
        MCSym:"",
        MCPlanetX: "",
        MCPlanetY: "",
        MCSign: "",
        MCSignSym: "",
        MCSignX: "",
        MCSignY: "",
        MCDegree: "",
        MCDegreeX: "",
        MCDegreeY: "",
        MCMinute: "",
        MCMinuteX: "",
        MCMinuteY: "",

    })


    return (
        <>
    { isVisible && <div className="form-container">
         
        <NewForm 
           moVals={moVals} setMoVals={setMoVals} 
           meVals={meVals} setMeVals={setMeVals} 
           veVals={veVals} setVeVals={setVeVals} 
           suVals={suVals} setSuVals={setSuVals} 
           setMaVals={setMaVals} maVals={maVals}
           setJuVals={setJuVals} juVals={juVals}
           setSaVals={setSaVals} saVals={saVals}  
           setUrVals={setUrVals} urVals={urVals}
           setNeVals={setNeVals} neVals={neVals}   
           setPlVals={setPlVals} plVals={plVals}
           setRaVals={setRaVals} raVals={raVals}  
           setKeVals={setKeVals} keVals={keVals}  
           setChVals={setChVals} chVals={chVals}  
           setASCVals={setASCVals} ascVals={ascVals} 
           setDSCVals={setDSCVals} dscVals={dscVals} 
           setICVals={setICVals} icVals={icVals} 
           setMCVals={setMCVals} mcVals={mcVals}
           setSignVals={setSignVals} signVals={signVals}
           setDegreeSym={setDegreeSym}  DegreeSym={DegreeSym}
           getSuccess={getSuccess} getChartSummary={getChartSummary}
           getSolarReturnSummary={getSolarReturnSummary}
           setIsSolarReturn={setIsSolarReturn}
       />
      </div>}

    
    {/* <div className="new-chart"> */}
        
       
    {!isVisible &&
       <div className="chart-view">

        <div className="chart-info">

       <div className="chart-summary libre-franklin-chart">
        <p>{chartSummary.name}<br />
        {chartSummary.local}<br />
        {chartSummary.utc} <em>(GMT)</em> <br />
        {chartSummary.location}<br />
        Long {chartSummary.long}  Lat {chartSummary.lat}<br />
        </p>
        </div>
        

        {isSolarReturn &&
        <div className="solar-summary libre-franklin-chart">
        <p>{solarReturnSummary.solar_return} Solar Return<br />
        {solarReturnSummary.local}<br />
        {solarReturnSummary.utc} <em>(GMT)</em> <br />
        Ref place: {solarReturnSummary.location}<br />
        {/* latitude: {Math.round(Number(chartSummary.lat))}° */}
        </p>
        </div>
        }
         
        </div>
        

         
        <Chart moVals={moVals} 
                meVals={meVals}  
                veVals={veVals} 
                suVals={suVals} 
                maVals={maVals}
                juVals={juVals}
                saVals={saVals}  
                urVals={urVals}
                neVals={neVals}   
                plVals={plVals}
                raVals={raVals}  
                keVals={keVals}  
                chVals={chVals}  
                ascVals={ascVals} 
                dscVals={dscVals} 
                icVals={icVals} 
                mcVals={mcVals}
                signVals={signVals}
                DegreeSym={DegreeSym}
            
            />
         
         
       
    
    </div>}
    {/* </div> */}

    </>
    
    )
  
}