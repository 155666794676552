import '../css/chart.css'
import { ReactComponent as ChartSvg } from "../assets/chartSvg"
import { useState, useEffect, useRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import fetchNewPlanets from '../api/fetchNewPlanets';
import useGetFetchNewPlanets from '../api/useGetFetchNewPlanets';


export const Chart = (props: any) => {

return  (
    <>
   
        <div className="chart-container">
  
        <ChartSvg
        centerX='475' 
        centerY='475'  
        signRadius = '575'
        signBorderRadius = '576' 
        planetRadius = '500' 
        houseRadius = '205' 
        centerRadius = '110'
        DegreeSym = {props.DegreeSym}
        AriSignX = {props.signVals.AriSignX}
        AriSignY = {props.signVals.AriSignY}
        TauSignX = {props.signVals.TauSignX}
        TauSignY = {props.signVals.TauSignY}
        GemSignX = {props.signVals.GemSignX}
        GemSignY = {props.signVals.GemSignY}
        CanSignX = {props.signVals.CanSignX}
        CanSignY = {props.signVals.CanSignY}
        LeoSignX = {props.signVals.LeoSignX}
        LeoSignY = {props.signVals.LeoSignY}
        VirSignX = {props.signVals.VirSignX}
        VirSignY = {props.signVals.VirSignY}
        LibSignX = {props.signVals.LibSignX}
        LibSignY = {props.signVals.LibSignY}
        ScoSignX = {props.signVals.ScoSignX}
        ScoSignY = {props.signVals.ScoSignY}
        SagSignX = {props.signVals.SagSignX}
        SagSignY = {props.signVals.SagSignY}
        CapSignX = {props.signVals.CapSignX}
        CapSignY = {props.signVals.CapSignY}
        AquSignX = {props.signVals.AquSignX}
        AquSignY = {props.signVals.AquSignY}
        PisSignX = {props.signVals.PisSignX}
        PisSignY = {props.signVals.PisSignY}
        RXSym = {props.raVals.RXSym}
        MoSym = {props.moVals.MoSym}
        MoPlanetX = {props.moVals.MoPlanetX}
        MoPlanetY = {props.moVals.MoPlanetY}
        MoSign = {props.moVals.MoSign}
        MoSignSym = {props.moVals.MoSignSym}
        MoSignX = {props.moVals.MoSignX}
        MoSignY = {props.moVals.MoSignY}
        MoDegree = {props.moVals.MoDegree}
        MoDegreeX = {props.moVals.MoDegreeX}
        MoDegreeY = {props.moVals.MoDegreeY}
        MoMinute = {props.moVals.MoMinute}
        MoMinuteX = {props.moVals.MoMinuteX}
        MoMinuteY = {props.moVals.MoMinuteY}
        VeSym = {props.veVals.VeSym}
        VePlanetX = {props.veVals.VePlanetX}
        VePlanetY = {props.veVals.VePlanetY}
        VeSign = {props.veVals.VeSign}
        VeSignSym = {props.veVals.VeSignSym}
        VeSignX = {props.veVals.VeSignX}
        VeSignY = {props.veVals.VeSignY}
        VeDegree = {props.veVals.VeDegree}
        VeDegreeX = {props.veVals.VeDegreeX}
        VeDegreeY = {props.veVals.VeDegreeY}
        VeMinute = {props.veVals.VeMinute}
        VeMinuteX = {props.veVals.VeMinuteX}
        VeMinuteY = {props.veVals.VeMinuteY}
        VeRX = {props.veVals.VeRX}
        VeRXX = {props.veVals.VeRXX}
        VeRXY = {props.veVals.VeRXY}
        ASCSym = {props.ascVals.ASCSym}
        ASCPlanetX = {props.ascVals.ASCPlanetX}
        ASCPlanetY = {props.ascVals.ASCPlanetY}
        ASCSign = {props.ascVals.ASCSign}
        ASCSignSym = {props.ascVals.ASCSignSym}
        ASCSignX = {props.ascVals.ASCSignX}
        ASCSignY = {props.ascVals.ASCSignY}
        ASCDegree = {props.ascVals.ASCDegree}
        ASCDegreeX = {props.ascVals.ASCDegreeX}
        ASCDegreeY = {props.ascVals.ASCDegreeY}
        ASCMinute = {props.ascVals.ASCMinute}
        ASCMinuteX = {props.ascVals.ASCMinuteX}
        ASCMinuteY = {props.ascVals.ASCMinuteY}
        RaSym = {props.raVals.RaSym}
        RaPlanetX = {props.raVals.RaPlanetX}
        RaPlanetY = {props.raVals.RaPlanetY}
        RaSign = {props.raVals.RaSign}
        RaSignSym = {props.raVals.RaSignSym}
        RaSignX = {props.raVals.RaSignX}
        RaSignY = {props.raVals.RaSignY}
        RaDegree = {props.raVals.RaDegree}
        RaDegreeX = {props.raVals.RaDegreeX}
        RaDegreeY = {props.raVals.RaDegreeY}
        RaMinute = {props.raVals.RaMinute}
        RaMinuteX = {props.raVals.RaMinuteX}
        RaMinuteY = {props.raVals.RaMinuteY}
        RaRX = {props.raVals.RaRX}
        RaRXX = {props.raVals.RaRXX}
        RaRXY = {props.raVals.RaRXY}
        SuSym = {props.suVals.SuSym}
        SuPlanetX = {props.suVals.SuPlanetX}
        SuPlanetY = {props.suVals.SuPlanetY}
        SuSign = {props.suVals.SuSign}
        SuSignSym = {props.suVals.SuSignSym}
        SuSignX = {props.suVals.SuSignX}
        SuSignY = {props.suVals.SuSignY}
        SuDegree = {props.suVals.SuDegree}
        SuDegreeX = {props.suVals.SuDegreeX}
        SuDegreeY = {props.suVals.SuDegreeY}
        SuMinute = {props.suVals.SuMinute}
        SuMinuteX = {props.suVals.SuMinuteX}
        SuMinuteY = {props.suVals.SuMinuteY}
        ChSym = {props.chVals.ChSym}
        ChPlanetX = {props.chVals.ChPlanetX}
        ChPlanetY = {props.chVals.ChPlanetY}
        ChSign = {props.chVals.ChSign}
        ChSignSym = {props.chVals.ChSignSym}
        ChSignX = {props.chVals.ChSignX}
        ChSignY = {props.chVals.ChSignY}
        ChDegree = {props.chVals.ChDegree}
        ChDegreeX = {props.chVals.ChDegreeX}
        ChDegreeY = {props.chVals.ChDegreeY}
        ChMinute = {props.chVals.ChMinute}
        ChMinuteX = {props.chVals.ChMinuteX}
        ChMinuteY = {props.chVals.ChMinuteY}
        ChRX = {props.chVals.ChRX}
        ChRXX = {props.chVals.ChRXX}
        ChRXY = {props.chVals.ChRXY}
        MeSym = {props.meVals.MeSym}
        MePlanetX = {props.meVals.MePlanetX}
        MePlanetY = {props.meVals.MePlanetY}
        MeSign = {props.meVals.MeSign}
        MeSignSym = {props.meVals.MeSignSym}
        MeSignX = {props.meVals.MeSignX}
        MeSignY = {props.meVals.MeSignY}
        MeDegree = {props.meVals.MeDegree}
        MeDegreeX = {props.meVals.MeDegreeX}
        MeDegreeY = {props.meVals.MeDegreeY}
        MeMinute = {props.meVals.MeMinute}
        MeMinuteX = {props.meVals.MeMinuteX}
        MeMinuteY = {props.meVals.MeMinuteY}
        MeRX = {props.meVals.MeRX}
        MeRXX = {props.meVals.MeRXX}
        MeRXY = {props.meVals.MeRXY}
        JuSym = {props.juVals.JuSym}
        JuPlanetX = {props.juVals.JuPlanetX}
        JuPlanetY = {props.juVals.JuPlanetY}
        JuSign = {props.juVals.JuSign}
        JuSignSym = {props.juVals.JuSignSym}
        JuSignX = {props.juVals.JuSignX}
        JuSignY = {props.juVals.JuSignY}
        JuDegree = {props.juVals.JuDegree}
        JuDegreeX = {props.juVals.JuDegreeX}
        JuDegreeY = {props.juVals.JuDegreeY}
        JuMinute = {props.juVals.JuMinute}
        JuMinuteX = {props.juVals.JuMinuteX}
        JuMinuteY = {props.juVals.JuMinuteY}
        JuRX = {props.juVals.JuRX}
        JuRXX = {props.juVals.JuRXX}
        JuRXY = {props.juVals.JuRXY}
        UrSym = {props.urVals.UrSym}
        UrPlanetX = {props.urVals.UrPlanetX}
        UrPlanetY = {props.urVals.UrPlanetY}
        UrSign = {props.urVals.UrSign}
        UrSignSym = {props.urVals.UrSignSym}
        UrSignX = {props.urVals.UrSignX}
        UrSignY = {props.urVals.UrSignY}
        UrDegree = {props.urVals.UrDegree}
        UrDegreeX = {props.urVals.UrDegreeX}
        UrDegreeY = {props.urVals.UrDegreeY}
        UrMinute = {props.urVals.UrMinute}
        UrMinuteX = {props.urVals.UrMinuteX}
        UrMinuteY = {props.urVals.UrMinuteY}
        UrRX = {props.urVals.UrRX}
        UrRXX = {props.urVals.UrRXX}
        UrRXY = {props.urVals.UrRXY}
        ICSym = {props.icVals.ICSym}
        ICPlanetX = {props.icVals.ICPlanetX}
        ICPlanetY = {props.icVals.ICPlanetY}
        ICSign = {props.icVals.ICSign}
        ICSignSym = {props.icVals.ICSignSym}
        ICSignX = {props.icVals.ICSignX}
        ICSignY = {props.icVals.ICSignY}
        ICDegree = {props.icVals.ICDegree}
        ICDegreeX = {props.icVals.ICDegreeX}
        ICDegreeY = {props.icVals.ICDegreeY}
        ICMinute = {props.icVals.ICMinute}
        ICMinuteX = {props.icVals.ICMinuteX}
        ICMinuteY = {props.icVals.ICMinuteY}
        MCSym = {props.mcVals.MCSym}
        MCPlanetX = {props.mcVals.MCPlanetX}
        MCPlanetY = {props.mcVals.MCPlanetY}
        MCSign = {props.mcVals.MCSign}
        MCSignSym = {props.mcVals.MCSignSym}
        MCSignX = {props.mcVals.MCSignX}
        MCSignY = {props.mcVals.MCSignY}
        MCDegree = {props.mcVals.MCDegree}
        MCDegreeX = {props.mcVals.MCDegreeX}
        MCDegreeY = {props.mcVals.MCDegreeY}
        MCMinute = {props.mcVals.MCMinute}
        MCMinuteX = {props.mcVals.MCMinuteX}
        MCMinuteY = {props.mcVals.MCMinuteY}
        KeSym = {props.keVals.KeSym}
        KePlanetX = {props.keVals.KePlanetX}
        KePlanetY = {props.keVals.KePlanetY}
        KeSign = {props.keVals.KeSign}
        KeSignSym = {props.keVals.KeSignSym}
        KeSignX = {props.keVals.KeSignX}
        KeSignY = {props.keVals.KeSignY}
        KeDegree = {props.keVals.KeDegree}
        KeDegreeX = {props.keVals.KeDegreeX}
        KeDegreeY = {props.keVals.KeDegreeY}
        KeMinute = {props.keVals.KeMinute}
        KeMinuteX = {props.keVals.KeMinuteX}
        KeMinuteY = {props.keVals.KeMinuteY}
        KeRX = {props.keVals.KeRX}
        KeRXX = {props.keVals.KeRXX}
        KeRXY = {props.keVals.KeRXY}
        DSCSym = {props.dscVals.DSCSym}
        DSCPlanetX = {props.dscVals.DSCPlanetX}
        DSCPlanetY = {props.dscVals.DSCPlanetY}
        DSCSign = {props.dscVals.DSCSign}
        DSCSignSym = {props.dscVals.DSCSignSym}
        DSCSignX = {props.dscVals.DSCSignX}
        DSCSignY = {props.dscVals.DSCSignY}
        DSCDegree = {props.dscVals.DSCDegree}
        DSCDegreeX = {props.dscVals.DSCDegreeX}
        DSCDegreeY = {props.dscVals.DSCDegreeY}
        DSCMinute = {props.dscVals.DSCMinute}
        DSCMinuteX = {props.dscVals.DSCMinuteX}
        DSCMinuteY = {props.dscVals.DSCMinuteY}
        PlSym = {props.plVals.PlSym}
        PlPlanetX = {props.plVals.PlPlanetX}
        PlPlanetY = {props.plVals.PlPlanetY}
        PlSign = {props.plVals.PlSign}
        PlSignSym = {props.plVals.PlSignSym}
        PlSignX = {props.plVals.PlSignX}
        PlSignY = {props.plVals.PlSignY}
        PlDegree = {props.plVals.PlDegree}
        PlDegreeX = {props.plVals.PlDegreeX}
        PlDegreeY = {props.plVals.PlDegreeY}
        PlMinute = {props.plVals.PlMinute}
        PlMinuteX = {props.plVals.PlMinuteX}
        PlMinuteY = {props.plVals.PlMinuteY}
        PlRX = {props.plVals.PlRX}
        PlRXX = {props.plVals.PlRXX}
        PlRXY = {props.plVals.PlRXY}
        MaSym = {props.maVals.MaSym}
        MaPlanetX = {props.maVals.MaPlanetX}
        MaPlanetY = {props.maVals.MaPlanetY}
        MaSign = {props.maVals.MaSign}
        MaSignSym = {props.maVals.MaSignSym}
        MaSignX = {props.maVals.MaSignX}
        MaSignY = {props.maVals.MaSignY}
        MaDegree = {props.maVals.MaDegree}
        MaDegreeX = {props.maVals.MaDegreeX}
        MaDegreeY = {props.maVals.MaDegreeY}
        MaMinute = {props.maVals.MaMinute}
        MaMinuteX = {props.maVals.MaMinuteX}
        MaMinuteY = {props.maVals.MaMinuteY}
        MaRX = {props.maVals.MaRX}
        MaRXX = {props.maVals.MaRXX}
        MaRXY = {props.maVals.MaRXY}
        SaSym = {props.saVals.SaSym}
        SaPlanetX = {props.saVals.SaPlanetX}
        SaPlanetY = {props.saVals.SaPlanetY}
        SaSign = {props.saVals.SaSign}
        SaSignSym = {props.saVals.SaSignSym}
        SaSignX = {props.saVals.SaSignX}
        SaSignY = {props.saVals.SaSignY}
        SaDegree = {props.saVals.SaDegree}
        SaDegreeX = {props.saVals.SaDegreeX}
        SaDegreeY = {props.saVals.SaDegreeY}
        SaMinute = {props.saVals.SaMinute}
        SaMinuteX = {props.saVals.SaMinuteX}
        SaMinuteY = {props.saVals.SaMinuteY}
        SaRX = {props.saVals.SaRX}
        SaRXX = {props.saVals.SaRXX}
        SaRXY = {props.saVals.SaRXY}
        NeSym = {props.neVals.NeSym}
        NePlanetX = {props.neVals.NePlanetX}
        NePlanetY = {props.neVals.NePlanetY}
        NeSign = {props.neVals.NeSign}
        NeSignSym = {props.neVals.NeSignSym}
        NeSignX = {props.neVals.NeSignX}
        NeSignY = {props.neVals.NeSignY}
        NeDegree = {props.neVals.NeDegree}
        NeDegreeX = {props.neVals.NeDegreeX}
        NeDegreeY = {props.neVals.NeDegreeY}
        NeMinute = {props.neVals.NeMinute}
        NeMinuteX = {props.neVals.NeMinuteX}
        NeMinuteY = {props.neVals.NeMinuteY}
        NeRX = {props.neVals.NeRX}
        NeRXX = {props.neVals.NeRXX}
        NeRXY = {props.neVals.NeRXY}
  />
        </div>
    </>
        
    )  
    
}


