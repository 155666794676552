import * as React from 'react';
import '../css/chart.css'
import dayjs, { Dayjs } from 'dayjs';
import {
  useTheme as useMaterialTheme,
  useColorScheme as useMaterialColorScheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import {
  extendTheme as extendJoyTheme,
  useColorScheme,
  CssVarsProvider,
  THEME_ID,
} from '@mui/joy/styles';
import Input, { InputProps } from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import {
  unstable_useDateField as useDateField,
  UseDateFieldProps,
} from '@mui/x-date-pickers/DateField';
import { useClearableField } from '@mui/x-date-pickers/hooks';
import {
  BaseSingleInputFieldProps,
  DateTimeValidationError,
  DateValidationError,
  FieldSection,
  FieldSectionType,
  FieldSelectedSections,
} from '@mui/x-date-pickers/models';
import {
    unstable_useNumberInput as useNumberInput,
    UseNumberInputParameters,
  } from '@mui/base/unstable_useNumberInput';
  import { styled } from '@mui/system';
  import { unstable_useForkRef as useForkRef } from '@mui/utils';
import { DateTimeField, DateTimePicker, UseDateTimeFieldProps } from '@mui/x-date-pickers';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { createRef, useEffect, useRef, useState } from 'react';
import { parseSelectedSections } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';
import { useQuery } from '@tanstack/react-query';
// import fetchNewPlanets from '../api/fetchNewPlanets';
import { Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline'

// const joyTheme = extendJoyTheme();

// interface JoyFieldProps extends InputProps {
//   label?: React.ReactNode;
//   inputRef?: React.Ref<HTMLInputElement>;
//   enableAccessibleFieldDOMStructure?: boolean;
//   InputProps?: {
//     ref?: React.Ref<any>;
//     endAdornment?: React.ReactNode;
//     startAdornment?: React.ReactNode;
//   };
//   formControlSx?: InputProps['sx'];
// }

// type JoyFieldComponent = ((
//   props: JoyFieldProps & React.RefAttributes<HTMLDivElement>,
// ) => React.JSX.Element) & { propTypes?: any };

// const JoyField = React.forwardRef(
//   (props: JoyFieldProps, ref: React.Ref<HTMLDivElement>) => {
//     const {
//       // Should be ignored
//       enableAccessibleFieldDOMStructure,

//       disabled,
//       id,
//       label,
//       InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
//       formControlSx,
//       endDecorator,
//       startDecorator,
//       slotProps,
//       inputRef,
//       ...other
//     } = props;

//     return (
//         <>
//       <FormControl
//         disabled={disabled}
//         id={id}
//         sx={[...(Array.isArray(formControlSx) ? formControlSx : [formControlSx])]}
//         ref={ref}
//       >
//         <FormLabel>{label}</FormLabel>
//         <Input
//           ref={ref}
//           disabled={disabled}
//           startDecorator={
//             <React.Fragment>
//               {startAdornment}
//               {startDecorator}
//             </React.Fragment>
//           }
//           endDecorator={
//             <React.Fragment>
//               {endAdornment}
//               {endDecorator}
//             </React.Fragment>
//           }
//           slotProps={{
//             ...slotProps,
//             root: { ...slotProps?.root, ref: containerRef },
//             input: { ...slotProps?.input, ref: inputRef },
//           }}
//           {...other}
//         />

//       </FormControl>

//       </>
//     );
//   },
// ) as JoyFieldComponent;

// // 
// interface JoyDateTimeFieldProps
//   extends UseDateTimeFieldProps<Dayjs, false>, // The headless field props
//     BaseSingleInputFieldProps<
//       Dayjs | null,
//       Dayjs,
//       FieldSection,
//       false, // `true` for `enableAccessibleFieldDOMStructure`
//       DateTimeValidationError
//     > {} // The DOM field props

// const JoyDateField = React.forwardRef(
//   (fieldProps: JoyDateTimeFieldProps, ref: React.Ref<HTMLDivElement>) => {
//     const { slots, slotProps, ...textFieldProps } = fieldProps;

//     const fieldResponse = useDateField<Dayjs, false, typeof textFieldProps>({
//       ...textFieldProps,
//       enableAccessibleFieldDOMStructure: false,
//     });

//     /* If you don't need a clear button, you can skip the use of this hook */
//     const processedFieldProps = useClearableField({
//       ...fieldResponse,
//       slots,
//       slotProps,
//     });

//     return <JoyField  ref={ref}{...processedFieldProps}
//     />;
//   },
// );

// const JoyDatePicker = React.forwardRef(
//   (props: DatePickerProps<Dayjs, false>, ref: React.Ref<HTMLDivElement>) => {
//     return (
//       <DatePicker
//         ref={ref}
//         {...props}
//         slots={{ ...props.slots, field: JoyDateField }}
//         slotProps={{
//           ...props.slotProps,
//           field: {
//             ...props.slotProps?.field,
//             formControlSx: {
//               flexDirection: 'row',
//             },
//           } as any,
//         }}
//       />
//     );
//   },
// );

// interface DefaultDateProps{
//   defaultDate: typeof React.useState<Dayjs | null>

// }

const CustomNumberInput = React.forwardRef(

  

  function CustomNumberInput(
    props: UseNumberInputParameters & any & React.InputHTMLAttributes<HTMLInputElement>, 
    ref: React.ForwardedRef<HTMLInputElement>, 
    
  ) {

    let varTid = ''

    const {
      getRootProps,
      getInputProps,
      getIncrementButtonProps,
      getDecrementButtonProps,
      focused,
    } = useNumberInput(props);

  
    const inputProps = getInputProps();
    const [value, setValue] = React.useState<Dayjs | null>(null);
    const [validation, setValidation] = React.useState<DateTimeValidationError>(null);


    // Make sure that both the forwarded ref and the ref returned from the getInputProps are applied on the input element
    inputProps.ref = useForkRef(inputProps.ref, ref);

    const [selectedSections, setSelectedSections] =
    useState<FieldSelectedSections>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    let date_test = null


    // const setSelectedSectionType = (selectedSectionType: FieldSectionType) => {
    // inputRef.current?.focus();
    // setSelectedSections(selectedSectionType);
    // };

    const handleOnChange  = async  (date: Dayjs | null) => {
            
      if(date == null || date.format('L') == 'Invalid Date'){
      }
      else{  
        setValue(date)
        date_test= date
        props.getDateSelected(date)
        // props.setDateInput(date)
        props.setAllowedToFetch(true)
        props.refetch()
        console.log(date)

        }
    
    }
    
    console.log(date_test)
    // console.log(dayjs().utc().year(1).month(0).date(1).hour(0).minute(0).second(0).millisecond(0))
    
    return (
      
      <StyledInputRoot {...getRootProps()} label="root" className={focused ? 'focused input root' : 'input root'}>
      <div className='increment-button-group'>
        <div className='increment-date-button-group'></div>
        <div className='increment-time-button-group'>
        <StyledStepperButton {...getIncrementButtonProps()} className="increment-hour" 
          onClick={() => {
            props.setSelectedSection("hours");
        }}
        >
          ▴
        </StyledStepperButton>
        
        <StyledStepperButton {...getIncrementButtonProps()} className="increment-minute"
        onClick={() => {props.setSelectedSection("minutes");
     
      }}
        >
          ▴
        </StyledStepperButton>
      </div></div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={DateTimePickerControl}>
        {/* <CssBaseline /> */}
        <DateTimePicker sx={{ minWidth: '100px'}}
          views={['year', 'month', 'day', 'hours', 'minutes']}
          inputRef={props.inpRef}
          format="MM/DD/YYYY hh:mm A" 
          value={dayjs(props.dateInput)}
          minDate={dayjs().utc().year(1).month(0).date(1).hour(0).minute(0).second(0).millisecond(0)}
          // onAccept={(newValue: any) =>{
          //   if(validation.dateError==false){
          //     //handleOnChange(newValue)
          //     console.log(validation.dateError)
          //   }
          // }}
          onChange={(newValue) => {
            //setValue(newValue)
            handleOnChange(dayjs(newValue))
          }}
          onError={(reason) => setValidation(reason)} 
          // slotProps={{
          //   textField:{
          //     error: validation.dateError
          //   }
          // }} 
          //selectedSections={props.selectedSection}
          // onSelectedSectionsChange={(newValue: any) =>  {setSelectedSections(newValue); selectedSec.current=newValue}}
          />
        </ThemeProvider>
        </LocalizationProvider>
        <div className='decrement-button-group'>
        <div className='increment-date-button-group'></div>
        <div className='increment-time-button-group'>
        <StyledStepperButton {...getDecrementButtonProps()} className="decrement-hour"
        onClick={() => {props.setSelectedSection("hours");;
        // props.setDateInput(dayjs(props.dateInput).subtract(1,'hour').local())
      }}
        >
          ▾
        </StyledStepperButton>
        <StyledStepperButton {...getDecrementButtonProps()} className="decrement-minute"
        onClick={() => {props.setSelectedSection("minutes");
        // props.setDateInput(dayjs(props.dateInput).subtract(1,'minute').local())
      }}
        >
          ▾
        </StyledStepperButton>
        </div>
        </div> 
        <HiddenInput {...inputProps} />
        <Typography>{value == null ? 'Null' : String(value)}</Typography>
     </StyledInputRoot>
     
      // <div className='hour-button-group'> 
     //<label className="button-name">H</label>  
      // <div className='minute-button-group'> 
      // <label className="button-name">M</label> //
    // defaultValue={dayjs(props.defaultDate)}  
     // onSelectedSectionsChange={(newValue: any) =>  {setSelectedSections(newValue); selectedSec.current=newValue}}
          // {if(selectedSections == 'hours'){
          //   props.setDefaultDate(dayjs(props.defaultDate).add(Number(getInputProps()['aria-valuetext']),'hour').local())}
          // else{
          //   props.setDefaultDate(dayjs(props.defaultDate).add(Number(getInputProps()['aria-valuetext']),'minute').local())}}}
          //(selectedSections == 'hours' ? setSelectedSectionType('hours') :setSelectedSectionType('minutes') )}
        // onSelectedSectionsChange={(selectedSections) => (selectedSections == 'hours' ? setSelectedSectionType('hours') :setSelectedSectionType('minutes') )}
        // value={{selectedSections == ['hours'] ?  (dayjs(props.defaultDate).add(Number(getInputProps()['aria-valuetext']),'hour').local()) :
        // (dayjs(props.defaultDate).add(Number(getInputProps()['aria-valuetext']),'minute').local())}}
        //setSelectedSectionType('hours'): setSelectedSectionType('minutes')
      // value = {dayjs(dateInput).add(Number(getInputProps()['aria-valuetext']), 'hour').local()}
        /*paddingTop: 2 */
        // value={ selectedSections == 0 || selectedSections == "empty" ? dayjs(props.defaultDate).add(Number(getInputProps()['aria-valuetext']), 'hour').local() :
        // dayjs(props.defaultDate).add(Number(getInputProps()['aria-valuetext']), 'minute').local()} 
      // label={selectedSec.current}
      // selectedSections={"hours"}
      // if(selectedSections == 'hours'){
        //     props.setDateInput(dayjs(props.defaultDate).add(Number(getInputProps()['aria-valuetext']),'hour').local())}
        //   else{
        //     props.setDateInput(dayjs(props.defaultDate).add(Number(getInputProps()['aria-valuetext']),'minute').local())}}
        //   }
        // setDateInput(dayjs(dateInput).add(Number(getInputProps()['aria-valuetext']), 'hour').local())}
      // selectedSec.current == 0 ? setDateInput(dayjs(dateInput).add(Number(getInputProps()['aria-valuetext']), 'hour').local()) :
      // setDateInput(dayjs(dateInput).add(Number(getInputProps()['aria-valuetext']), 'minute').local())

        // {...selectedSections == 0 ? dayjs(props.defaultDate).add(Number(getInputProps()['aria-valuetext']), 'hour').local() :
        //  setDateInput(dayjs(props.defaultDate).add(Number(getInputProps()['aria-valuetext']), 'minute').local())} 
     
    );

    
    
  });
  
/**
 * This component is for syncing the theme mode of this demo with the MUI docs mode.
 * You might not need this component in your project.
 */
function SyncThemeMode({ mode }: { mode: 'light' | 'dark' }) {
  const { setMode } = useColorScheme();
  const { setMode: setMaterialMode } = useMaterialColorScheme();
  React.useEffect(() => {
    setMode(mode);
    setMaterialMode(mode);
  }, [mode, setMode, setMaterialMode]);
  return null;
}

const blue = {
    100: '#DAECFF',
    200: '#B6DAFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const DateTimePickerControl = createTheme({
    components: {
      // Name of the component
      MuiOutlinedInput: {
        styleOverrides: {
          input:
       {
          padding: '10px 15px 10px 15px'
       }
        ,
        root:
        `
        @font-face {
        font-family: 'Libre Franklin';
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/librefranklin/v18/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsWkANDJTedX18mE.woff2) format('woff2');
      }`
         
        },
      },
      // MuiInputBase: {
      //   styleOverrides:{
      //      // Name of the slot
      //      input: 
          
      //      {
      //        // Some CSS
      //        padding: '10px 15px 10px 15px',
 
      //      },
      //     },
      // },
    },
  });

  const MuiFormControl = createTheme({
    components: {
      // Name of the component
      MuiFormControl: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS

          },
        },
      },
    },
  });
  
  const StyledInputRoot: React.ElementType = styled('div')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    //box-shadow: 0px 2px 4px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
    };
    //display: grid;
    //grid-template-columns: 1fr 19px;
    //grid-template-rows: 1fr 1fr;
    overflow: hidden;
    column-gap: 8px;
    padding: 4px;
  
      &.root:hover {
        border-color: ${blue[400]};
        //box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
  
        // & button:hover {
        //   background: ${blue[400]};
        // }
        // firefox
        &:focus-visible {
          outline: 0;
      }
    `,
  );
  
  const StyledInputElement = styled('input')(
    ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    grid-column: 1/2;
    grid-row: 1/3;
    grid-column: 1/2;
    grid-row: 1/3;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: inherit;
    border: none;
    border-radius: inherit;
    padding: 8px 12px;
    outline: 0;
  `,
  );

  const HiddenInput = styled('input')`
  visibility: hidden;
  position: absolute;
`;
  
  const StyledStepperButton = styled('button')(
    ({ theme }) => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    appearance: none;
    padding-bottom: 8px;
    margin-right:4px;
    margin-left:4px;
    width: 19px;
    height: 7px;
    font-family: system-ui, sans-serif;
    font-size: 1.25rem;
    line-height: 1;
    box-sizing: border-box;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 0;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
      &.increment-minute,&.increment-hour {
        //grid-column: 1/10;
        //grid-row: 1/1;
        // grid-column: 2/3;
        // grid-row: 1/2;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: 1px solid;
        border-bottom: 0;
        border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
        color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  
        &.increment-minute:hover, &.increment-hour:hover{
          cursor: pointer;
          color: #FFF;
          background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
          border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
        }
      }
  
      &.decrement-minute, &.decrement-hour {
        //grid-column: 1/10;
        //grid-row: 3/3;
        // grid-column: 2/3;
        // grid-row: 2/3;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid;
        border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
        color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  
        &.decrement-minute:hover, &.decrement-hour:hover{
          cursor: pointer;
          color: #FFF;
          background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
          border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
        }
    }
    `,
  );

  const Pre = styled('pre')`
  font-size: 0.75rem;
`;
  

export default function DateTimeInput(props: any ) {
  

  //const [timeCount, setTimeCount] = useState(0);

  // const [dateInput, setDateInput] = useState<Dayjs | null>(props.defaultDate);

  const materialTheme = useMaterialTheme();

  let planet = null
  let degree = null
  let sign = null
  let minute = null
  let rx = null
  let varTid=''
  let direction=''
  let interval=''

 

  interface ChartInfo  {
    
      utc_date: any;
      signs: any;
      planets: any;
  
}

  async function fetchNewPlanets(direction: string, interval: string, tid: string, lat: string, long: string): Promise<ChartInfo> {
    const response = (process.env.NODE_ENV=='production') ?
    await fetch(`http://planetgazer.net:8000/${direction}/${interval}?tid=${tid}&lat=${lat}&long=${long}`)
    :
    await fetch(`http://localhost:8000/${direction}/${interval}?tid=${tid}&lat=${lat}&long=${long}`);

    //await fetch(`http://localhost/api/${direction}/${interval}?tid=${tid}&lat=${lat}&long=${long}`);
    return response.json();
  }
    
  const {data: qtransits , error, isLoading, isSuccess, refetch} = useQuery<ChartInfo>(
    { 
    // placeholderData: "",
    queryKey: ['qtransits',direction,interval,varTid],
    queryFn: () => fetchNewPlanets(direction,interval,varTid, props.lat.current, props.long.current),
    enabled: false,
    staleTime: Infinity

    });

  
  const handleOnClick  = async  (d: string, i: string) => { 
      if (d == 'forward'){ 
        if (i == 'hour')
          {props.setDefaultDate(dayjs(props.defaultDate).add(1,'hour').local())
          varTid=dayjs(props.defaultDate).add(1,'hour').local().toISOString()}
        else{
          props.setDefaultDate(dayjs(props.defaultDate).add(1,'minute').local())
          varTid=dayjs(props.defaultDate).add(1,'minute').local().toISOString()}
        }
      else{
        if (i == 'hour')
          {props.setDefaultDate(dayjs(props.defaultDate).subtract(1,'hour').local())
          varTid=dayjs(props.defaultDate).subtract(1,'hour').local().toISOString()}
          else{
            props.setDefaultDate(dayjs(props.defaultDate).subtract(1,'minute').local())
            varTid=dayjs(props.defaultDate).subtract(1,'minute').local().toISOString()}
      }
        
      direction = d
      interval = i
      if(varTid && direction && interval &&  props.lat.current && props.long.current) {refetch()}
  };

  useEffect(() => {
       
    console.log("tid:" + props.tid.current)
  
    console.log(qtransits)

    props.setDegreeSym('°')
    if (isSuccess)
        {props.tid.current = qtransits?.utc_date }


    props.setSignVals({
        AriSignX: qtransits?.signs[0].coord[0],
        AriSignY: qtransits?.signs[0].coord[1],
        TauSignX: qtransits?.signs[1].coord[0],
        TauSignY: qtransits?.signs[1].coord[1],
        GemSignX: qtransits?.signs[2].coord[0],
        GemSignY: qtransits?.signs[2].coord[1],
        CanSignX: qtransits?.signs[3].coord[0],
        CanSignY: qtransits?.signs[3].coord[1],
        LeoSignX: qtransits?.signs[4].coord[0],
        LeoSignY: qtransits?.signs[4].coord[1],
        VirSignX: qtransits?.signs[5].coord[0],
        VirSignY: qtransits?.signs[5].coord[1],
        LibSignX: qtransits?.signs[6].coord[0],
        LibSignY: qtransits?.signs[6].coord[1],
        ScoSignX: qtransits?.signs[7].coord[0],
        ScoSignY: qtransits?.signs[7].coord[1],
        SagSignX: qtransits?.signs[8].coord[0],
        SagSignY: qtransits?.signs[8].coord[1],
        CapSignX: qtransits?.signs[9].coord[0],
        CapSignY: qtransits?.signs[9].coord[1],
        AquSignX: qtransits?.signs[10].coord[0],
        AquSignY: qtransits?.signs[10].coord[1],
        PisSignX: qtransits?.signs[11].coord[0],
        PisSignY: qtransits?.signs[11].coord[1]

    })
  
    
    qtransits?.planets.map((pl: any) => { 
  
  
        if (pl.planet == "Mo") {
            props.setMoVals( {
                MoPlanetX : pl.coord[0][0], MoPlanetY : pl.coord[0][1], MoSignX : pl.coord[2][0], MoSignY : pl.coord[2][1], MoDegreeX : pl.coord[1][0], MoDegreeY : pl.coord[1][1], MoMinuteX : pl.coord[3][0], MoMinuteY : pl.coord[3][1],
                MoSign: pl.newSign, MoSignSym: pl.newSignSymbol, MoDegree: pl.newDegree, MoMinute: pl.newMinute } )
        }
  
        
        if (pl.planet == "Me") {
            props.setMeVals( {
                MePlanetX : pl.coord[0][0], MePlanetY : pl.coord[0][1], MeSignX : pl.coord[2][0], MeSignY : pl.coord[2][1], MeDegreeX : pl.coord[1][0], MeDegreeY : pl.coord[1][1], MeMinuteX : pl.coord[3][0], MeMinuteY : pl.coord[3][1], MeRXX: pl.coord[4][0], MeRXY: pl.coord[4][1],
                MeSign: pl.newSign, MeSignSym: pl.newSignSymbol, MeDegree: pl.newDegree, MeMinute: pl.newMinute, MeRX:pl.rx } )
        }
  
        if (pl.planet == "Ve") {
            props.setVeVals( {
                VePlanetX : pl.coord[0][0], VePlanetY : pl.coord[0][1], VeSignX : pl.coord[2][0], VeSignY : pl.coord[2][1], VeDegreeX : pl.coord[1][0], VeDegreeY : pl.coord[1][1], VeMinuteX : pl.coord[3][0], VeMinuteY : pl.coord[3][1], VeRXX: pl.coord[4][0], VeRXY: pl.coord[4][1],
                VeSign: pl.newSign, VeSignSym: pl.newSignSymbol, VeDegree: pl.newDegree, VeMinute: pl.newMinute, VeRX:pl.rx } )
        }
  
        if (pl.planet == "Su") {
            props.setSuVals( {
                SuPlanetX : pl.coord[0][0], SuPlanetY : pl.coord[0][1], SuSignX : pl.coord[2][0], SuSignY : pl.coord[2][1], SuDegreeX : pl.coord[1][0], SuDegreeY : pl.coord[1][1], SuMinuteX : pl.coord[3][0], SuMinuteY : pl.coord[3][1],
                SuSign: pl.newSign, SuSignSym: pl.newSignSymbol, SuDegree: pl.newDegree, SuMinute: pl.newMinute } )
        }
  
        if (pl.planet == "Ma") {
            props.setMaVals( {
                MaPlanetX : pl.coord[0][0], MaPlanetY : pl.coord[0][1], MaSignX : pl.coord[2][0], MaSignY : pl.coord[2][1], MaDegreeX : pl.coord[1][0], MaDegreeY : pl.coord[1][1], MaMinuteX : pl.coord[3][0], MaMinuteY : pl.coord[3][1], MaRXX: pl.coord[4][0], MaRXY: pl.coord[4][1],
                MaSign: pl.newSign, MaSignSym: pl.newSignSymbol, MaDegree: pl.newDegree, MaMinute: pl.newMinute, MaRX:pl.rx } )
        }
        if (pl.planet == "Ju") {
            props.setJuVals( {
                JuPlanetX : pl.coord[0][0], JuPlanetY : pl.coord[0][1], JuSignX : pl.coord[2][0], JuSignY : pl.coord[2][1], JuDegreeX : pl.coord[1][0], JuDegreeY : pl.coord[1][1], JuMinuteX : pl.coord[3][0], JuMinuteY : pl.coord[3][1], JuRXX: pl.coord[4][0], JuRXY: pl.coord[4][1],
                JuSign: pl.newSign, JuSignSym: pl.newSignSymbol, JuDegree: pl.newDegree, JuMinute: pl.newMinute, JuRX:pl.rx } )
        }
        if (pl.planet == "Sa") {
            props.setSaVals( {
                SaPlanetX : pl.coord[0][0], SaPlanetY : pl.coord[0][1], SaSignX : pl.coord[2][0], SaSignY : pl.coord[2][1], SaDegreeX : pl.coord[1][0], SaDegreeY : pl.coord[1][1], SaMinuteX : pl.coord[3][0], SaMinuteY : pl.coord[3][1], SaRXX: pl.coord[4][0], SaRXY: pl.coord[4][1],
                SaSign: pl.newSign, SaSignSym: pl.newSignSymbol, SaDegree: pl.newDegree, SaMinute: pl.newMinute, SaRX:pl.rx } )
        }
        
        if (pl.planet == "Ur") {
            props.setUrVals( {
                UrPlanetX : pl.coord[0][0], UrPlanetY : pl.coord[0][1], UrSignX : pl.coord[2][0], UrSignY : pl.coord[2][1], UrDegreeX : pl.coord[1][0], UrDegreeY : pl.coord[1][1], UrMinuteX : pl.coord[3][0], UrMinuteY : pl.coord[3][1], UrRXX: pl.coord[4][0], UrRXY: pl.coord[4][1],
                UrSign: pl.newSign, UrSignSym: pl.newSignSymbol, UrDegree: pl.newDegree, UrMinute: pl.newMinute, UrRX:pl.rx } )
        }
        if (pl.planet == "Ne") {
            props.setNeVals( {
                NePlanetX : pl.coord[0][0], NePlanetY : pl.coord[0][1], NeSignX : pl.coord[2][0], NeSignY : pl.coord[2][1], NeDegreeX : pl.coord[1][0], NeDegreeY : pl.coord[1][1], NeMinuteX : pl.coord[3][0], NeMinuteY : pl.coord[3][1], NeRXX: pl.coord[4][0], NeRXY: pl.coord[4][1],
                NeSign: pl.newSign, NeSignSym: pl.newSignSymbol, NeDegree: pl.newDegree, NeMinute: pl.newMinute, NeRX:pl.rx } )
        }
        if (pl.planet == "Pl") {
            props.setPlVals( {
                PlPlanetX : pl.coord[0][0], PlPlanetY : pl.coord[0][1], PlSignX : pl.coord[2][0], PlSignY : pl.coord[2][1], PlDegreeX : pl.coord[1][0], PlDegreeY : pl.coord[1][1], PlMinuteX : pl.coord[3][0], PlMinuteY : pl.coord[3][1], PlRXX: pl.coord[4][0], PlRXY: pl.coord[4][1],
                PlSign: pl.newSign, PlSignSym: pl.newSignSymbol, PlDegree: pl.newDegree, PlMinute: pl.newMinute, PlRX:pl.rx } )
        }
        if (pl.planet == "Ra") {
            props.setRaVals( {
                RaPlanetX : pl.coord[0][0], RaPlanetY : pl.coord[0][1], RaSignX : pl.coord[2][0], RaSignY : pl.coord[2][1], RaDegreeX : pl.coord[1][0], RaDegreeY : pl.coord[1][1], RaMinuteX : pl.coord[3][0], RaMinuteY : pl.coord[3][1], RaRXX: pl.coord[4][0], RaRXY: pl.coord[4][1],
                RaSign: pl.newSign, RaSignSym: pl.newSignSymbol, RaDegree: pl.newDegree, RaMinute: pl.newMinute} )
        }
        if (pl.planet == "Ke") {
            props.setKeVals( {
                KePlanetX : pl.coord[0][0], KePlanetY : pl.coord[0][1], KeSignX : pl.coord[2][0], KeSignY : pl.coord[2][1], KeDegreeX : pl.coord[1][0], KeDegreeY : pl.coord[1][1], KeMinuteX : pl.coord[3][0], KeMinuteY : pl.coord[3][1], KeRXX: pl.coord[4][0], KeRXY: pl.coord[4][1],
                KeSign: pl.newSign, KeSignSym: pl.newSignSymbol, KeDegree: pl.newDegree, KeMinute: pl.newMinute } )
        }
        if (pl.planet == "Ch") {
            props.setChVals( {
                ChPlanetX : pl.coord[0][0], ChPlanetY : pl.coord[0][1], ChSignX : pl.coord[2][0], ChSignY : pl.coord[2][1], ChDegreeX : pl.coord[1][0], ChDegreeY : pl.coord[1][1], ChMinuteX : pl.coord[3][0], ChMinuteY : pl.coord[3][1], ChRXX: pl.coord[4][0], ChRXY: pl.coord[4][1],
                ChSign: pl.newSign, ChSignSym: pl.newSignSymbol, ChDegree: pl.newDegree, ChMinute: pl.newMinute, ChRX:pl.rx } )
        }
        if (pl.planet == "ASC") {
            props.setASCVals( {
                ASCPlanetX : pl.coord[0][0], ASCPlanetY : pl.coord[0][1], ASCSignX : pl.coord[2][0], ASCSignY : pl.coord[2][1], ASCDegreeX : pl.coord[1][0], ASCDegreeY : pl.coord[1][1], ASCMinuteX : pl.coord[3][0], ASCMinuteY : pl.coord[3][1],
                ASCSign: pl.newSign, ASCSignSym: pl.newSignSymbol, ASCDegree: pl.newDegree, ASCMinute: pl.newMinute } )
        }
        if (pl.planet == "DSC") {
            props.setDSCVals( {
                DSCPlanetX : pl.coord[0][0], DSCPlanetY : pl.coord[0][1], DSCSignX : pl.coord[2][0], DSCSignY : pl.coord[2][1], DSCDegreeX : pl.coord[1][0], DSCDegreeY : pl.coord[1][1], DSCMinuteX : pl.coord[3][0], DSCMinuteY : pl.coord[3][1],
                DSCSign: pl.newSign, DSCSignSym: pl.newSignSymbol, DSCDegree: pl.newDegree, DSCMinute: pl.newMinute } )
        }
        if (pl.planet == "IC") {
            props.setICVals( {
                ICPlanetX : pl.coord[0][0], ICPlanetY : pl.coord[0][1], ICSignX : pl.coord[2][0], ICSignY : pl.coord[2][1], ICDegreeX : pl.coord[1][0], ICDegreeY : pl.coord[1][1], ICMinuteX : pl.coord[3][0], ICMinuteY : pl.coord[3][1],
                ICSign: pl.newSign, ICSignSym: pl.newSignSymbol, ICDegree: pl.newDegree, ICMinute: pl.newMinute } )
        }
        if (pl.planet == "MC") {
            props.setMCVals( {
                MCPlanetX : pl.coord[0][0], MCPlanetY : pl.coord[0][1], MCSignX : pl.coord[2][0], MCSignY : pl.coord[2][1], MCDegreeX : pl.coord[1][0], MCDegreeY : pl.coord[1][1], MCMinuteX : pl.coord[3][0], MCMinuteY : pl.coord[3][1],
                MCSign: pl.newSign, MCSignSym: pl.newSignSymbol, MCDegree: pl.newDegree, MCMinute: pl.newMinute } )
        }
        
  
        // let planet = document.getElementById('Ve-planet');
        // if (planet != null) planet.setAttribute("transform", `translate(3, 33)`);
        planet = document.getElementById(pl.planet + "-planet");
        degree = document.getElementById(pl.planet + "-degree");
        sign = document.getElementById(pl.planet + "-sign");
        minute = document.getElementById(pl.planet + "-minute");
        rx = document.getElementById(pl.planet + "-rx");
        if (planet != null) planet.setAttribute("transform", "translate(" + pl.coord_changed[0][0] + "," + pl.coord_changed[0][1] + ")");
        if (degree != null) degree.setAttribute("transform", "translate(" + pl.coord_changed[1][0] + "," + pl.coord_changed[1][1] + ")");
        if (sign != null ) sign.setAttribute("transform", "translate(" + pl.coord_changed[2][0] + "," + pl.coord_changed[2][1] + ")");
        if (minute != null) minute.setAttribute("transform", "translate(" + pl.coord_changed[3][0] + "," + pl.coord_changed[3][1] + ")");
        if (rx != null) rx.setAttribute("transform", "translate(" + pl.coord_changed[4][0] + "," + pl.coord_changed[4][1] + ")"); 
                   
    })
  
  },[qtransits])



//console.log("Custom number input " + dayjs(props.defaultDate).local().format("MM/DD/YYYY hh:mm a"))
//console.log("Tid: " + dayjs(props.defaultDate).toISOString())



    return(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomNumberInput 
        dateInput={props.defaultDate} setDateInput={props.setDefaultDate} 
        setSelectedSection={props.setSelectedSectionType} selectedSection={props.selectedSections} 
        inpRef={props.inputRef} tid={props.tid} setAllowedToFetch={props.setAllowedToFetch} refetch={props.refetch}
        lat={props.lat} long={props.long} getDateSelected={props.getDateInput}
        props={props}
        aria-label="increment decrement hours minutes" 
        //value={timeCount}
        onClick={(event: any, val: number) => {
          const btnClass = event.target.classList[0]
          if(btnClass !== undefined && event.target.localName=='button'){
          const btn = btnClass.split('-')[1]
          console.log(btn)
          if ( btn != "root"){
            props.setAllowedToFetch(false) 
            //const btnClass = event.target.classList[0] 
            direction=btnClass.split('-')[0] == 'increment' ? 'forward' : 'back'
            interval=btnClass.split('-')[1]
            handleOnClick(direction,interval);

          } }
          }}
            
           />
    </LocalizationProvider>
    )
  }

  // return (
  //   <MaterialCssVarsProvider>
  //     <CssVarsProvider theme={{ [THEME_ID]: joyTheme }}>
  //       <SyncThemeMode mode={materialTheme.palette.mode} />
        
  //       <ThemeProvider theme={MuiOutlinedInput}>
  //         <LocalizationProvider dateAdapter={AdapterDayjs}>
  //         <DatePicker sx={{ minWidth: '100px',paddingTop: 3}}
  //         slotProps={{ textField: { size: 'small' } }} 
  //         format="MM/DD/YYYY hh:mm A"
  //         value={dayjs(props.defaultDate)}
  //         // onChange={(newValue:any) => props.setDefaultDate(newValue)}
  //          /> 
  //          <JoyDatePicker
  //           slotProps={{
  //             field: { clearable: true, 
  //                   value: dayjs(props.defaultDate).local(),
  //                   // onChange: (newValue) => props.setDefaultDate(newValue)
  //               },
  //           }}
  //         /> 
            //   <CustomNumberInput dateInput={props.defaultDate} setDateInput={props.setDefaultDate} props={props}
            //   aria-label="Demo number input" 
            //   placeholder="Type a number…"
            //   value={timeCount}
            //   onChange={(event: any, val: number) => {
            //   console.log(`Event type: ${event.type}`);
            //   setTimeCount(val);
            //   props.setDefaultDate(dateInput)

            // }}
              
            //   />


          
  //       </LocalizationProvider>
  //      </ThemeProvider>
  //     // </CssVarsProvider>
  //   // </MaterialCssVarsProvider>
  // );

  


// export default function UseNumberInput() {
//   return (
//     <CustomNumberInput aria-label="Demo number input" placeholder="Type a number…" />
//   );
// }

// const IncrementButton = React.forwardRef(
//   function IncrementButton(
//     props: UseNumberInputParameters & React.InputHTMLAttributes<HTMLInputElement>,
//     ref: React.ForwardedRef<HTMLInputElement>,
//   ) {
//     const {
//       getIncrementButtonProps,
//     } = useNumberInput(props);

  
//     return (
    
//         <StyledStepperButton {...getIncrementButtonProps()} className="increment">
//           ▴
//         </StyledStepperButton>
//     );
//   });
  
//   const DecrementButton = React.forwardRef(

//     function DecrementButton(
//       props: UseNumberInputParameters & React.InputHTMLAttributes<HTMLInputElement>,
//       ref: React.ForwardedRef<HTMLInputElement>,
//     ) {
//       const {
//         getDecrementButtonProps,
//         focused,
//       } = useNumberInput(props);

    
//       return (
//           <StyledStepperButton {...getDecrementButtonProps()} className="decrement">
//             ▾
//           </StyledStepperButton>
//       );
//     });

//     const StyledInput = React.forwardRef(

//       function StyledInput(
//         props: UseNumberInputParameters & React.InputHTMLAttributes<HTMLInputElement>,
//         ref: React.ForwardedRef<HTMLInputElement>,
//       ) {
//         const {

//           getInputProps
//         } = useNumberInput(props);
      
//         const inputProps = getInputProps();
    
//         // Make sure that both the forwarded ref and the ref returned from the getInputProps are applied on the input element
//         inputProps.ref = useForkRef(inputProps.ref, ref);
      
//         return (
          
//             <StyledInputElement {...inputProps} />
//         );
//       });

//       const StyledRoot = React.forwardRef(

//         function StyledRoot(
//           props: UseNumberInputParameters & React.InputHTMLAttributes<HTMLInputElement>,
//           ref: React.ForwardedRef<HTMLInputElement>,
//         ) {
//           const {
//             getRootProps,
//             focused,
//           } = useNumberInput(props);
        
//           return (
//             <StyledInputRoot {...getRootProps()} className={focused ? 'focused' : null}>
        
//            </StyledInputRoot>
//           );
//         });
