import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { dayjsSetup } from './utility/dayjs.helper'
import { Main } from './pages/main';
import { NewChart } from './pages/new-chart/newchart';
import { Login } from './pages/login';
import { Navbar } from './components/navbar';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Transit } from './pages/chart-display/transit';



function App() {
  const queryClient = new QueryClient();
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Main />}/>
            <Route path="/login" element={<Login />}/>
            <Route path="new-chart/newchart" element={<NewChart />}/>
            <Route path="chart-display/transit" element={<Transit/>}/>
          </Routes>
        </Router>
      </QueryClientProvider>
    </div>
  );
}
dayjsSetup()
export default App;
