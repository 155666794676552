import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react";

export const Login = () => {
    const [value, setValue] = useState<Dayjs | null>(null);

    console.log(value)
    return (
    <>
    <div>Login</div>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker  sx={{ minWidth: '100px'}}
            //views={['year', 'month', 'day', 'hours', 'minutes']}
            //inputRef={props.inpRef}
            format="MM/DD/YYYY hh:mm A" 
            onChange={(newValue) => {
                setValue(dayjs(newValue))
                //console.log(newValue)
            
            
          }}
        ></DateTimePicker>
    </LocalizationProvider>
    </>  
    ) 
}