import '../../css/chart.css'
import '../../css/chart-controls.css'
import { ReactComponent as ChartSvg } from "../../assets/chartSvg"
import { ArrowForward } from '../../components/arrow-forward';
import { useState, useEffect, useRef } from 'react';
import { UseQueryResult, useQuery, QueryClient } from '@tanstack/react-query';
import { DoubleArrowBack } from '../../components/double-arrow-back';
import dayjs, { Dayjs } from 'dayjs';
import DateTimeInput from '../../components/datetimeinput';
import { FieldSectionType, FieldSelectedSections } from '@mui/x-date-pickers';
import fetchNewPlanets from '../../api/fetchNewPlanets';
import useGetFetchNewPlanets from '../../api/useGetFetchNewPlanets';
import { DoubleArrowForward } from '../../components/double-arrow-forward';
import { ArrowBack } from '../../components/arrow-back';
import { CircleArrowBack } from '../../components/circle-arrow-back';
import { CircleArrowForward } from '../../components/circle-arrow-forward';
import { Chart } from '../../components/chart';
//import { DateTimePickerValue } from '../../components/datetime-input';


export const Transit = () => {


const [defaultDate, setDefaultDate] = useState<Dayjs | null>(dayjs(Date.now()));

const [allowedToFetch, setAllowedToFetch] = useState(true)

const queryClient = new QueryClient();

const tid = useRef('')

const ptid = useRef('')

const lat =  useRef('')

const long = useRef('')

let dayCount = useRef(0)

const [DegreeSym, setDegreeSym] = useState('0')

const[signVals, setSignVals] = useState({
    AriSignX : '-7',
    AriSignY : '604',
    TauSignX : '121',
    TauSignY : '828',
    GemSignX : '345',
    GemSignY : '957',
    CanSignX : '604',
    CanSignY : '957',
    LeoSignX : '828',
    LeoSignY : '828',
    VirSignX : '957',
    VirSignY : '604',
    LibSignX : '957',
    LibSignY : '345',
    ScoSignX : '828',
    ScoSignY : '121',
    SagSignX : '604',
    SagSignY : '-7',
    CapSignX : '345',
    CapSignY : '-7',
    AquSignX : '121',
    AquSignY : '121',
    PisSignX : '-7',
    PisSignY : '345'
})

const [moVals, setMoVals] = useState({
    MoSym:"",
    MoPlanetX: "",
    MoPlanetY: "",
    MoSign: "",
    MoSignSym: "",
    MoSignX: "",
    MoSignY: "",
    MoDegree: "",
    MoDegreeX: "",
    MoDegreeY: "",
    MoMinute: "",
    MoMinuteX: "",
    MoMinuteY: "",

    })

const [meVals, setMeVals] = useState({
    MeSym:"",
    MePlanetX: "",
    MePlanetY: "",
    MeSign: "",
    MeSignSym: "",
    MeSignX: "",
    MeSignY: "",
    MeDegree: "",
    MeDegreeX: "",
    MeDegreeY: "",
    MeMinute: "",
    MeMinuteX: "",
    MeMinuteY: "",
    MeRX: true,
    MeRXX: "",
    MeRXY: "",
    })
const [veVals, setVeVals] = useState({
    VeSym:"",
    VePlanetX: "",
    VePlanetY: "",
    VeSign: "",
    VeSignSym: "",
    VeSignX: "",
    VeSignY: "",
    VeDegree: "",
    VeDegreeX: "",
    VeDegreeY: "",
    VeMinute: "",
    VeMinuteX: "",
    VeMinuteY: "",
    VeRX: false,
    VeRXX: "",
    VeRXY: ""
})
const [suVals, setSuVals] = useState({
    SuSym:"",
    SuPlanetX: "",
    SuPlanetY: "",
    SuSign: "",
    SuSignSym: "",
    SuSignX: "",
    SuSignY: "",
    SuDegree: "",
    SuDegreeX: "",
    SuDegreeY: "",
    SuMinute: "",
    SuMinuteX: "",
    SuMinuteY: "",
    SuRX: "",
    SuRY: ""
})
const [maVals, setMaVals] = useState({
    MaSym:"",
    MaPlanetX: "",
    MaPlanetY: "",
    MaSign: "",
    MaSignSym: "",
    MaSignX: "",
    MaSignY: "",
    MaDegree: "",
    MaDegreeX: "",
    MaDegreeY: "",
    MaMinute: "",
    MaMinuteX: "",
    MaMinuteY: "",
    MaRX: false,
    MaRXX: "",
    MaRXY: ""
})

const [juVals, setJuVals] = useState({
    JuSym:"",
    JuPlanetX: "",
    JuPlanetY: "",
    JuSign: "",
    JuSignSym: "",
    JuSignX: "",
    JuSignY: "",
    JuDegree: "",
    JuDegreeX: "",
    JuDegreeY: "",
    JuMinute: "",
    JuMinuteX: "",
    JuMinuteY: "",
    JuRX: false,
    JuRXX: "",
    JuRXY: ""
})
const [saVals, setSaVals] = useState({
    SaSym:"",
    SaPlanetX: "",
    SaPlanetY: "",
    SaSign: "",
    SaSignSym: "",
    SaSignX: "",
    SaSignY: "",
    SaDegree: "",
    SaDegreeX: "",
    SaDegreeY: "",
    SaMinute: "",
    SaMinuteX: "",
    SaMinuteY: "",
    SaRX: false,
    SaRXX: "",
    SaRXY: ""
})
const [urVals, setUrVals] = useState({
    UrSym:"",
    UrPlanetX: "",
    UrPlanetY: "",
    UrSign: "",
    UrSignSym: "",
    UrSignX: "",
    UrSignY: "",
    UrDegree: "",
    UrDegreeX: "",
    UrDegreeY: "",
    UrMinute: "",
    UrMinuteX: "",
    UrMinuteY: "",
    UrRX: false,
    UrRXX: "",
    UrRXY: ""
})
const [neVals, setNeVals] = useState({
    NeSym:"",
    NePlanetX: "",
    NePlanetY: "",
    NeSign: "",
    NeSignSym: "",
    NeSignX: "",
    NeSignY: "",
    NeDegree: "",
    NeDegreeX: "",
    NeDegreeY: "",
    NeMinute: "",
    NeMinuteX: "",
    NeMinuteY: "",
    NeRX: false,
    NeRXX: "",
    NeRXY: ""
})
const [plVals, setPlVals] = useState({
    PlSym:"",
    PlPlanetX: "",
    PlPlanetY: "",
    PlSign: "",
    PlSignSym: "",
    PlSignX: "",
    PlSignY: "",
    PlDegree: "",
    PlDegreeX: "",
    PlDegreeY: "",
    PlMinute: "",
    PlMinuteX: "",
    PlMinuteY: "",
    PlRX: false,
    PlRXX: "",
    PlRXY: ""
})
const [raVals, setRaVals] = useState({
    RaSym:"",
    RaPlanetX: "",
    RaPlanetY: "",
    RaSign: "",
    RaSignSym: "",
    RaSignX: "",
    RaSignY: "",
    RaDegree: "",
    RaDegreeX: "",
    RaDegreeY: "",
    RaMinute: "",
    RaMinuteX: "",
    RaMinuteY: "",
    RaRX: true,
    RaRXX: "",
    RaRXY: "",
    RXSym: ""
})
const [keVals, setKeVals] = useState({
    KeSym:"",
    KePlanetX: "",
    KePlanetY: "",
    KeSign: "",
    KeSignSym: "",
    KeSignX: "",
    KeSignY: "",
    KeDegree: "",
    KeDegreeX: "",
    KeDegreeY: "",
    KeMinute: "",
    KeMinuteX: "",
    KeMinuteY: "",
    KeRX:  true,
    KeRXX: "",
    KeRXY: ""
})
const [chVals, setChVals] = useState({
    ChSym:"",
    ChPlanetX: "",
    ChPlanetY: "",
    ChSign: "",
    ChSignSym: "",
    ChSignX: "",
    ChSignY: "",
    ChDegree: "",
    ChDegreeX: "",
    ChDegreeY: "",
    ChMinute: "",
    ChMinuteX: "",
    ChMinuteY: "",
    ChRX:false,
    ChRXX: "",
    ChRXY: ""
})
const [ascVals, setASCVals] = useState({
    ASCSym:"",
    ASCPlanetX: "",
    ASCPlanetY: "",
    ASCSign: "",
    ASCSignSym: "",
    ASCSignX: "",
    ASCSignY: "",
    ASCDegree: "",
    ASCDegreeX: "",
    ASCDegreeY: "",
    ASCMinute: "",
    ASCMinuteX: "",
    ASCMinuteY: "",

})
const [dscVals, setDSCVals] = useState({
    DSCSym:"",
    DSCPlanetX: "",
    DSCPlanetY: "",
    DSCSign: "",
    DSCSignSym: "",
    DSCSignX: "",
    DSCSignY: "",
    DSCDegree: "",
    DSCDegreeX: "",
    DSCDegreeY: "",
    DSCMinute: "",
    DSCMinuteX: "",
    DSCMinuteY: "",

})
const [icVals, setICVals] = useState({
    ICSym:"",
    ICPlanetX: "",
    ICPlanetY: "",
    ICSign: "",
    ICSignSym: "",
    ICSignX: "",
    ICSignY: "",
    ICDegree: "",
    ICDegreeX: "",
    ICDegreeY: "",
    ICMinute: "",
    ICMinuteX: "",
    ICMinuteY: "",

})
const [mcVals, setMCVals] = useState({
    MCSym:"",
    MCPlanetX: "",
    MCPlanetY: "",
    MCSign: "",
    MCSignSym: "",
    MCSignX: "",
    MCSignY: "",
    MCDegree: "",
    MCDegreeX: "",
    MCDegreeY: "",
    MCMinute: "",
    MCMinuteX: "",
    MCMinuteY: "",

})

const [selectedSections, setSelectedSections] =
useState<FieldSelectedSections>(null);
const inputRef = useRef<HTMLInputElement>(null);

const setSelectedSectionType = (selectedSectionType: FieldSectionType) => {
inputRef.current?.focus();
setSelectedSections(selectedSectionType);
};


const {data:ipq, error: err, isLoading: load} = useQuery(
    {
   
    queryKey:['ip'],
    queryFn: () => getIP(),
    enabled: allowedToFetch,
    staleTime: Infinity
        
    },
    
)

const getIP = () => fetch(`https://api.ipify.org?format=json`).then((res) => res.json())
    //fetch('http://worldtimeapi.org/api/ip').then((res) => res.json())


const ip = ipq?.ip
let varTid = defaultDate?.utc().format()
//const dst = ipq?.dst
const getDateInput = (date:Dayjs | undefined) => { 
    if(date !== undefined){
        setDefaultDate(date)
        varTid = date.utc().format()
        console.log(date.utc().format())
    }
    
} 



const {data: geoloc, error: err2, isLoading: load2} = useQuery(
    {
    queryKey:['geoloc', ip],
    queryFn: () => getGeoLoc(ip),
    enabled: !!ipq && allowedToFetch
    }
)

// Fetch a query from the cache
// const loc_query:any = queryClient.getQueryData('geoloc');


const getGeoLoc = (ip: string) => fetch(`http://ip-api.com/json/${ip}`).then((res) => res.json())

lat.current = geoloc?.lat
long.current = geoloc?.lon


const {data: chart, error, isLoading, refetch} = useQuery(
    { 
    queryKey: ['chart', varTid],
    queryFn: () => loadPlanets(varTid),
    enabled: !!ipq && !!geoloc && allowedToFetch
    });

const loadPlanets = (varTid:string | undefined) =>
    (process.env.NODE_ENV=='production') ?
    fetch(`http://planetgazer.net:8000/chart/?date=${varTid}&lat=${lat.current}&long=${long.current}`).then((res) => res.json())
    :
    fetch(`http://localhost:8000/chart/?date=${varTid}&lat=${lat.current}&long=${long.current}`).then((res) => res.json());


console.log(defaultDate?.utc().format())
console.log(chart)
console.log(ipq)
console.log(lat.current + " " + long.current) 
console.log(process.env.NODE_ENV)
console.log( (process.env.NODE_ENV=='development') ? process.env.REACT_APP_API_URL_DEV: process.env.REACT_APP_API_URL_PROD)
// console.log("Fetching: " + allowedToFetch)
// console.log("default date: " + dayjs(defaultDate).format('MM/DD/YYYY hh:mm a'))
//console.log(varTid)

useEffect(() => {

    setDegreeSym('°')
    console.log(chart)
    if (chart !== null || chart !== undefined)
        
        {tid.current = chart?.utc_date }


    setSignVals({
        AriSignX: chart?.signs[0].coord[0],
        AriSignY: chart?.signs[0].coord[1],
        TauSignX: chart?.signs[1].coord[0],
        TauSignY: chart?.signs[1].coord[1],
        GemSignX: chart?.signs[2].coord[0],
        GemSignY: chart?.signs[2].coord[1],
        CanSignX: chart?.signs[3].coord[0],
        CanSignY: chart?.signs[3].coord[1],
        LeoSignX: chart?.signs[4].coord[0],
        LeoSignY: chart?.signs[4].coord[1],
        VirSignX: chart?.signs[5].coord[0],
        VirSignY: chart?.signs[5].coord[1],
        LibSignX: chart?.signs[6].coord[0],
        LibSignY: chart?.signs[6].coord[1],
        ScoSignX: chart?.signs[7].coord[0],
        ScoSignY: chart?.signs[7].coord[1],
        SagSignX: chart?.signs[8].coord[0],
        SagSignY: chart?.signs[8].coord[1],
        CapSignX: chart?.signs[9].coord[0],
        CapSignY: chart?.signs[9].coord[1],
        AquSignX: chart?.signs[10].coord[0],
        AquSignY: chart?.signs[10].coord[1],
        PisSignX: chart?.signs[11].coord[0],
        PisSignY: chart?.signs[11].coord[1]

    })

    chart?.planets.map((p: any) => { 


        if (p.planet == "Mo") {
            setMoVals({  
                MoSym:"R", 
                MoPlanetX: p.coord[0][0],
                MoPlanetY: p.coord[0][1],
                MoSign: p.sign,
                MoSignSym: p.signSymbol,
                MoSignX: p.coord[2][0],
                MoSignY: p.coord[2][1],
                MoDegree: p.degree,
                MoDegreeX: p.coord[1][0],
                MoDegreeY: p.coord[1][1],
                MoMinute: p.minute,
                MoMinuteX: p.coord[3][0],
                MoMinuteY: p.coord[3][1],
            })
        }
        if (p.planet == "Me") { 
            setMeVals({
                MeSym:"S",          
                MePlanetX: p.coord[0][0],
                MePlanetY: p.coord[0][1],
                MeSign: p.sign,
                MeSignSym: p.signSymbol,
                MeSignX: p.coord[2][0],
                MeSignY: p.coord[2][1],
                MeDegree: p.degree,
                MeDegreeX: p.coord[1][0],
                MeDegreeY: p.coord[1][1],
                MeMinute: p.minute,
                MeMinuteX: p.coord[3][0],
                MeMinuteY: p.coord[3][1],
                MeRX:  p.rx,
                MeRXX: p.coord[4][0],
                MeRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ve") { 
            setVeVals({    
                VeSym:"T",      
                VePlanetX: p.coord[0][0],
                VePlanetY: p.coord[0][1],
                VeSign: p.sign,
                VeSignSym: p.signSymbol,
                VeSignX: p.coord[2][0],
                VeSignY: p.coord[2][1],
                VeDegree: p.degree,
                VeDegreeX: p.coord[1][0],
                VeDegreeY: p.coord[1][1],
                VeMinute: p.minute,
                VeMinuteX: p.coord[3][0],
                VeMinuteY: p.coord[3][1],
                VeRX:  p.rx,
                VeRXX: p.coord[4][0],
                VeRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Su") { 
            setSuVals({ 
                SuSym:p.planetSymbol,         
                SuPlanetX: p.coord[0][0],
                SuPlanetY: p.coord[0][1],
                SuSign: p.sign,
                SuSignSym: p.signSymbol,
                SuSignX: p.coord[2][0],
                SuSignY: p.coord[2][1],
                SuDegree: p.degree,
                SuDegreeX: p.coord[1][0],
                SuDegreeY: p.coord[1][1],
                SuMinute: p.minute,
                SuMinuteX: p.coord[3][0],
                SuMinuteY: p.coord[3][1],
                SuRX: p.coord[4][0],
                SuRY: p.coord[4][1]
            })
        }
        
        if (p.planet == "Ma") { 
            setMaVals({
                MaSym:"U",          
                MaPlanetX: p.coord[0][0],
                MaPlanetY: p.coord[0][1],
                MaSign: p.sign,
                MaSignSym: p.signSymbol,
                MaSignX: p.coord[2][0],
                MaSignY: p.coord[2][1],
                MaDegree: p.degree,
                MaDegreeX: p.coord[1][0],
                MaDegreeY: p.coord[1][1],
                MaMinute: p.minute,
                MaMinuteX: p.coord[3][0],
                MaMinuteY: p.coord[3][1],
                MaRX: p.rx,
                MaRXX: p.coord[4][0],
                MaRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ju") { 
            setJuVals({ 
                JuSym:"V",         
                JuPlanetX: p.coord[0][0],
                JuPlanetY: p.coord[0][1],
                JuSign: p.sign,
                JuSignSym: p.signSymbol,
                JuSignX: p.coord[2][0],
                JuSignY: p.coord[2][1],
                JuDegree: p.degree,
                JuDegreeX: p.coord[1][0],
                JuDegreeY: p.coord[1][1],
                JuMinute: p.minute,
                JuMinuteX: p.coord[3][0],
                JuMinuteY: p.coord[3][1],
                JuRX: p.rx,
                JuRXX: p.coord[4][0],
                JuRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Sa") { 
            setSaVals({
                SaSym:"W",          
                SaPlanetX: p.coord[0][0],
                SaPlanetY: p.coord[0][1],
                SaSign: p.sign,
                SaSignSym: p.signSymbol,
                SaSignX: p.coord[2][0],
                SaSignY: p.coord[2][1],
                SaDegree: p.degree,
                SaDegreeX: p.coord[1][0],
                SaDegreeY: p.coord[1][1],
                SaMinute: p.minute,
                SaMinuteX: p.coord[3][0],
                SaMinuteY: p.coord[3][1],
                SaRX: p.rx,
                SaRXX: p.coord[4][0],
                SaRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ur") { 
            setUrVals({  
                UrSym:"X",        
                UrPlanetX: p.coord[0][0],
                UrPlanetY: p.coord[0][1],
                UrSign: p.sign,
                UrSignSym: p.signSymbol,
                UrSignX: p.coord[2][0],
                UrSignY: p.coord[2][1],
                UrDegree: p.degree,
                UrDegreeX: p.coord[1][0],
                UrDegreeY: p.coord[1][1],
                UrMinute: p.minute,
                UrMinuteX: p.coord[3][0],
                UrMinuteY: p.coord[3][1],
                UrRX: p.rx,
                UrRXX: p.coord[4][0],
                UrRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ne") { 
            setNeVals({  
                NeSym:"Y",        
                NePlanetX: p.coord[0][0],
                NePlanetY: p.coord[0][1],
                NeSign: p.sign,
                NeSignSym: p.signSymbol,
                NeSignX: p.coord[2][0],
                NeSignY: p.coord[2][1],
                NeDegree: p.degree,
                NeDegreeX: p.coord[1][0],
                NeDegreeY: p.coord[1][1],
                NeMinute: p.minute,
                NeMinuteX: p.coord[3][0],
                NeMinuteY: p.coord[3][1],
                NeRX: p.rx,
                NeRXX: p.coord[4][0],
                NeRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Pl") { 
            setPlVals({   
                PlSym:"Z",       
                PlPlanetX: p.coord[0][0],
                PlPlanetY: p.coord[0][1],
                PlSign: p.sign,
                PlSignSym: p.signSymbol,
                PlSignX: p.coord[2][0],
                PlSignY: p.coord[2][1],
                PlDegree: p.degree,
                PlDegreeX: p.coord[1][0],
                PlDegreeY: p.coord[1][1],
                PlMinute: p.minute,
                PlMinuteX: p.coord[3][0],
                PlMinuteY: p.coord[3][1],
                PlRX: p.rx,
                PlRXX: p.coord[4][0],
                PlRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ra") { 
            setRaVals({
                RaSym:"<",          
                RaPlanetX: p.coord[0][0],
                RaPlanetY: p.coord[0][1],
                RaSign: p.sign,
                RaSignSym: p.signSymbol,
                RaSignX: p.coord[2][0],
                RaSignY: p.coord[2][1],
                RaDegree: p.degree,
                RaDegreeX: p.coord[1][0],
                RaDegreeY: p.coord[1][1],
                RaMinute: p.minute,
                RaMinuteX: p.coord[3][0],
                RaMinuteY: p.coord[3][1],
                RaRX: p.rx,
                RaRXX: p.coord[4][0],
                RaRXY: p.coord[4][1],
                RXSym: 'M' 
            })
        }
        if (p.planet == "Ke") { 
            setKeVals({ 
                KeSym:">",         
                KePlanetX: p.coord[0][0],
                KePlanetY: p.coord[0][1],
                KeSign: p.sign,
                KeSignSym: p.signSymbol,
                KeSignX: p.coord[2][0],
                KeSignY: p.coord[2][1],
                KeDegree: p.degree,
                KeDegreeX: p.coord[1][0],
                KeDegreeY: p.coord[1][1],
                KeMinute: p.minute,
                KeMinuteX: p.coord[3][0],
                KeMinuteY: p.coord[3][1],
                KeRX: p.rx,
                KeRXX: p.coord[4][0],
                KeRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ch") { 
            setChVals({  
                ChSym:"t",        
                ChPlanetX: p.coord[0][0],
                ChPlanetY: p.coord[0][1],
                ChSign: p.sign,
                ChSignSym: p.signSymbol,
                ChSignX: p.coord[2][0],
                ChSignY: p.coord[2][1],
                ChDegree: p.degree,
                ChDegreeX: p.coord[1][0],
                ChDegreeY: p.coord[1][1],
                ChMinute: p.minute,
                ChMinuteX: p.coord[3][0],
                ChMinuteY: p.coord[3][1],
                ChRX: p.rx,
                ChRXX: p.coord[4][0],
                ChRXY: p.coord[4][1]
            })
        }
        if (p.planet == "ASC") { 
            setASCVals({
                ASCSym:"a",          
                ASCPlanetX: p.coord[0][0],
                ASCPlanetY: p.coord[0][1],
                ASCSign: p.sign,
                ASCSignSym: p.signSymbol,
                ASCSignX: p.coord[2][0],
                ASCSignY: p.coord[2][1],
                ASCDegree: p.degree,
                ASCDegreeX: p.coord[1][0],
                ASCDegreeY: p.coord[1][1],
                ASCMinute: p.minute,
                ASCMinuteX: p.coord[3][0],
                ASCMinuteY: p.coord[3][1]
            })

        }
        if (p.planet == "DSC") { 
            setDSCVals({
                DSCSym:"DC",          
                DSCPlanetX: p.coord[0][0],
                DSCPlanetY: p.coord[0][1],
                DSCSign: p.sign,
                DSCSignSym: p.signSymbol,
                DSCSignX: p.coord[2][0],
                DSCSignY: p.coord[2][1],
                DSCDegree: p.degree,
                DSCDegreeX: p.coord[1][0],
                DSCDegreeY: p.coord[1][1],
                DSCMinute: p.minute,
                DSCMinuteX: p.coord[3][0],
                DSCMinuteY: p.coord[3][1]
            })
        }
        if (p.planet == "IC") { 
            setICVals({ 
                ICSym:"c",         
                ICPlanetX: p.coord[0][0],
                ICPlanetY: p.coord[0][1],
                ICSign: p.sign,
                ICSignSym: p.signSymbol,
                ICSignX: p.coord[2][0],
                ICSignY: p.coord[2][1],
                ICDegree: p.degree,
                ICDegreeX: p.coord[1][0],
                ICDegreeY: p.coord[1][1],
                ICMinute: p.minute,
                ICMinuteX: p.coord[3][0],
                ICMinuteY: p.coord[3][1]
            })
        }
        if (p.planet == "MC") { 
            setMCVals({ 
                MCSym:"b",         
                MCPlanetX: p.coord[0][0],
                MCPlanetY: p.coord[0][1],
                MCSign: p.sign,
                MCSignSym: p.signSymbol,
                MCSignX: p.coord[2][0],
                MCSignY: p.coord[2][1],
                MCDegree: p.degree,
                MCDegreeX: p.coord[1][0],
                MCDegreeY: p.coord[1][1],
                MCMinute: p.minute,
                MCMinuteX: p.coord[3][0],
                MCMinuteY: p.coord[3][1]
            })
        }
   
    })

    

},[chart])
    

return  (
    <>
    
    <div className='arrow-controls'>
   <div className='arrow-back-group'>
    <ArrowBack moVals={moVals} setMoVals={setMoVals}  
            meVals={meVals} setMeVals={setMeVals} 
            veVals={veVals} setVeVals={setVeVals} 
            suVals={suVals} setSuVals={setSuVals} 
            setMaVals={setMaVals} maVals={maVals}
            setJuVals={setJuVals} juVals={juVals}
            setSaVals={setSaVals} saVals={saVals}  
            setUrVals={setUrVals} urVals={urVals}
            setNeVals={setNeVals} neVals={neVals}   
            setPlVals={setPlVals} plVals={plVals}
            setRaVals={setRaVals} raVals={raVals}  
            setKeVals={setKeVals} keVals={keVals}  
            setChVals={setChVals} chVals={chVals}  
            setASCVals={setASCVals} ascVals={ascVals} 
            setDSCVals={setDSCVals} dscVals={dscVals} 
            setICVals={setICVals} icVals={icVals} 
            setMCVals={setMCVals} mcVals={mcVals}
            setDegreeSym={setDegreeSym}  DegreeSym={DegreeSym}
            setSignVals={setSignVals} signVals={signVals}
            tid={tid} lat={lat} long={long}
            ptid={ptid}
            allowedToFetch={allowedToFetch} setAllowedToFetch={setAllowedToFetch}
            dayCount={dayCount} setDefaultDate={setDefaultDate} defaultDate={defaultDate}
            setSelectedSectionType={setSelectedSectionType} 
            selectedSections={selectedSections} 
            inputRef={inputRef}

        />
            <DoubleArrowBack moVals={moVals} setMoVals={setMoVals}  
            meVals={meVals} setMeVals={setMeVals} 
            veVals={veVals} setVeVals={setVeVals} 
            suVals={suVals} setSuVals={setSuVals} 
            setMaVals={setMaVals} maVals={maVals}
            setJuVals={setJuVals} juVals={juVals}
            setSaVals={setSaVals} saVals={saVals}  
            setUrVals={setUrVals} urVals={urVals}
            setNeVals={setNeVals} neVals={neVals}   
            setPlVals={setPlVals} plVals={plVals}
            setRaVals={setRaVals} raVals={raVals}  
            setKeVals={setKeVals} keVals={keVals}  
            setChVals={setChVals} chVals={chVals}  
            setASCVals={setASCVals} ascVals={ascVals} 
            setDSCVals={setDSCVals} dscVals={dscVals} 
            setICVals={setICVals} icVals={icVals} 
            setMCVals={setMCVals} mcVals={mcVals}
            setDegreeSym={setDegreeSym}  DegreeSym={DegreeSym}
            setSignVals={setSignVals} signVals={signVals}
            tid={tid} lat={lat} long={long}
            ptid={ptid}
            allowedToFetch={allowedToFetch} setAllowedToFetch={setAllowedToFetch}
            dayCount={dayCount} setDefaultDate={setDefaultDate} defaultDate={defaultDate}
            setSelectedSectionType={setSelectedSectionType} 
            selectedSections={selectedSections} 
            inputRef={inputRef}

        />
        <CircleArrowBack moVals={moVals} setMoVals={setMoVals}  
            meVals={meVals} setMeVals={setMeVals} 
            veVals={veVals} setVeVals={setVeVals} 
            suVals={suVals} setSuVals={setSuVals} 
            setMaVals={setMaVals} maVals={maVals}
            setJuVals={setJuVals} juVals={juVals}
            setSaVals={setSaVals} saVals={saVals}  
            setUrVals={setUrVals} urVals={urVals}
            setNeVals={setNeVals} neVals={neVals}   
            setPlVals={setPlVals} plVals={plVals}
            setRaVals={setRaVals} raVals={raVals}  
            setKeVals={setKeVals} keVals={keVals}  
            setChVals={setChVals} chVals={chVals}  
            setASCVals={setASCVals} ascVals={ascVals} 
            setDSCVals={setDSCVals} dscVals={dscVals} 
            setICVals={setICVals} icVals={icVals} 
            setMCVals={setMCVals} mcVals={mcVals}
            setDegreeSym={setDegreeSym}  DegreeSym={DegreeSym}
            setSignVals={setSignVals} signVals={signVals}
            tid={tid} lat={lat} long={long}
            ptid={ptid}
            allowedToFetch={allowedToFetch} setAllowedToFetch={setAllowedToFetch}
            dayCount={dayCount} setDefaultDate={setDefaultDate} defaultDate={defaultDate}
            setSelectedSectionType={setSelectedSectionType} 
            selectedSections={selectedSections} 
            inputRef={inputRef}

        />
        </div>
        <DateTimeInput 
        moVals={moVals} setMoVals={setMoVals} 
        meVals={meVals} setMeVals={setMeVals} 
        veVals={veVals} setVeVals={setVeVals} 
        suVals={suVals} setSuVals={setSuVals} 
        setMaVals={setMaVals} maVals={maVals}
        setJuVals={setJuVals} juVals={juVals}
        setSaVals={setSaVals} saVals={saVals}  
        setUrVals={setUrVals} urVals={urVals}
        setNeVals={setNeVals} neVals={neVals}   
        setPlVals={setPlVals} plVals={plVals}
        setRaVals={setRaVals} raVals={raVals}  
        setKeVals={setKeVals} keVals={keVals}  
        setChVals={setChVals} chVals={chVals}  
        setASCVals={setASCVals} ascVals={ascVals} 
        setDSCVals={setDSCVals} dscVals={dscVals} 
        setICVals={setICVals} icVals={icVals} 
        setMCVals={setMCVals} mcVals={mcVals}
        setDegreeSym={setDegreeSym}  DegreeSym={DegreeSym}
        setSignVals={setSignVals} signVals={signVals}
        defaultDate={defaultDate} setDefaultDate={setDefaultDate} tid={tid} 
        lat={lat} long={long}
        allowedToFetch={allowedToFetch} setAllowedToFetch={setAllowedToFetch} refetch={refetch}
        getDateInput={getDateInput}
        setSelectedSectionType={setSelectedSectionType} selectedSections={selectedSections} 
        inputRef={inputRef}>
        </DateTimeInput > 
    
        <div className='arrow-forward-group'>
        <CircleArrowForward moVals={moVals} setMoVals={setMoVals}  
            meVals={meVals} setMeVals={setMeVals} 
            veVals={veVals} setVeVals={setVeVals} 
            suVals={suVals} setSuVals={setSuVals} 
            setMaVals={setMaVals} maVals={maVals}
            setJuVals={setJuVals} juVals={juVals}
            setSaVals={setSaVals} saVals={saVals}  
            setUrVals={setUrVals} urVals={urVals}
            setNeVals={setNeVals} neVals={neVals}   
            setPlVals={setPlVals} plVals={plVals}
            setRaVals={setRaVals} raVals={raVals}  
            setKeVals={setKeVals} keVals={keVals}  
            setChVals={setChVals} chVals={chVals}  
            setASCVals={setASCVals} ascVals={ascVals} 
            setDSCVals={setDSCVals} dscVals={dscVals} 
            setICVals={setICVals} icVals={icVals} 
            setMCVals={setMCVals} mcVals={mcVals}
            setDegreeSym={setDegreeSym}  DegreeSym={DegreeSym}
            setSignVals={setSignVals} signVals={signVals}
            tid={tid} lat={lat} long={long}
            ptid={ptid}
            allowedToFetch={allowedToFetch} setAllowedToFetch={setAllowedToFetch}
            dayCount={dayCount} setDefaultDate={setDefaultDate} defaultDate={defaultDate}
            setSelectedSectionType={setSelectedSectionType} 
            selectedSections={selectedSections} 
            inputRef={inputRef}

        />
        <DoubleArrowForward moVals={moVals} setMoVals={setMoVals}  
            meVals={meVals} setMeVals={setMeVals} 
            veVals={veVals} setVeVals={setVeVals} 
            suVals={suVals} setSuVals={setSuVals} 
            setMaVals={setMaVals} maVals={maVals}
            setJuVals={setJuVals} juVals={juVals}
            setSaVals={setSaVals} saVals={saVals}  
            setUrVals={setUrVals} urVals={urVals}
            setNeVals={setNeVals} neVals={neVals}   
            setPlVals={setPlVals} plVals={plVals}
            setRaVals={setRaVals} raVals={raVals}  
            setKeVals={setKeVals} keVals={keVals}  
            setChVals={setChVals} chVals={chVals}  
            setASCVals={setASCVals} ascVals={ascVals} 
            setDSCVals={setDSCVals} dscVals={dscVals} 
            setICVals={setICVals} icVals={icVals} 
            setMCVals={setMCVals} mcVals={mcVals}
            setSignVals={setSignVals} signVals={signVals}
            tid={tid} lat={lat} long={long}
            ptid={ptid}
            allowedToFetch={allowedToFetch} setAllowedToFetch={setAllowedToFetch}
            dayCount={dayCount} setDefaultDate={setDefaultDate} defaultDate={defaultDate}
            setSelectedSectionType={setSelectedSectionType} 
            selectedSections={selectedSections} 
            inputRef={inputRef}

        />
        <ArrowForward moVals={moVals} setMoVals={setMoVals}  
            meVals={meVals} setMeVals={setMeVals} 
            veVals={veVals} setVeVals={setVeVals} 
            suVals={suVals} setSuVals={setSuVals} 
            setMaVals={setMaVals} maVals={maVals}
            setJuVals={setJuVals} juVals={juVals}
            setSaVals={setSaVals} saVals={saVals}  
            setUrVals={setUrVals} urVals={urVals}
            setNeVals={setNeVals} neVals={neVals}   
            setPlVals={setPlVals} plVals={plVals}
            setRaVals={setRaVals} raVals={raVals}  
            setKeVals={setKeVals} keVals={keVals}  
            setChVals={setChVals} chVals={chVals}  
            setASCVals={setASCVals} ascVals={ascVals} 
            setDSCVals={setDSCVals} dscVals={dscVals} 
            setICVals={setICVals} icVals={icVals} 
            setMCVals={setMCVals} mcVals={mcVals}
            setSignVals={setSignVals} signVals={signVals}
            tid={tid} lat={lat} long={long}
            ptid={ptid}
            allowedToFetch={allowedToFetch} setAllowedToFetch={setAllowedToFetch}
            dayCount={dayCount} setDefaultDate={setDefaultDate} defaultDate={defaultDate}
            setSelectedSectionType={setSelectedSectionType} 
            selectedSections={selectedSections} 
            inputRef={inputRef}

        />

        </div>
        </div>
        <div className='transit-chart'>
  
        <Chart moVals={moVals} 
            meVals={meVals}  
            veVals={veVals} 
            suVals={suVals} 
            maVals={maVals}
            juVals={juVals}
            saVals={saVals}  
            urVals={urVals}
            neVals={neVals}   
            plVals={plVals}
            raVals={raVals}  
            keVals={keVals}  
            chVals={chVals}  
            ascVals={ascVals} 
            dscVals={dscVals} 
            icVals={icVals} 
            mcVals={mcVals}
            signVals={signVals}
            DegreeSym={DegreeSym}
        
        />
        </div>

    </>
        
    )
}