import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Container, IconButton } from '@mui/material';
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from 'react';
import { fetchNewPlanets } from '../api/fetchNewPlanets';
import dayjs from 'dayjs';
import SkipNextIcon from '@mui/icons-material/SkipNext';

export const CircleArrowForward = (props:any) => {

    let varTid = ''

    let MoPlanetX = ""
    let MoPlanetY = ""
    let MoSignX =""
    let MoSignY =""
    let MoDegree = ""
    let MoDegreeX = ""
    let MoDegreeY = ""
    let MoMinute = ""
    let MoMinuteX = ""
    let MoMinuteY = ""
    let MePlanetX = ""
    let MePlanetY = ""
    let MeSignX =""
    let MeSignY =""
    let MeDegree = ""
    let MeDegreeX = ""
    let MeDegreeY = ""
    let MeMinute = ""
    let MeMinuteX = ""
    let MeMinuteY = ""
    let MeRX = ""
    let MeRXX = true
    let MeRXY = ""
    let VePlanetX = ""
    let VePlanetY = ""
    let VeSignX =""
    let VeSignY =""
    let VeDegree = ""
    let VeDegreeX = ""
    let VeDegreeY = ""
    let VeMinute = ""
    let VeMinuteX = ""
    let VeMinuteY = ""
    let VeRX = false
    let VeRXX = ""
    let VeRXY = ""
    let SuPlanetX = ""
    let SuPlanetY = ""
    let SuSignX =""
    let SuSignY =""
    let SuDegree = ""
    let SuDegreeX = ""
    let SuDegreeY = ""
    let SuMinute = ""
    let SuMinuteX = ""
    let SuMinuteY = ""
    let MaPlanetX = ""
    let MaPlanetY = ""
    let MaSignX =""
    let MaSignY =""
    let MaDegree = ""
    let MaDegreeX = ""
    let MaDegreeY = ""
    let MaMinute = ""
    let MaMinuteX = ""
    let MaMinuteY = ""
    let MaRX = false
    let MaRXX = ""
    let MaRXY = ""
    let JuPlanetX = ""
    let JuPlanetY = ""
    let JuSignX =""
    let JuSignY =""
    let JuDegree = ""
    let JuDegreeX = ""
    let JuDegreeY = ""
    let JuMinute = ""
    let JuMinuteX = ""
    let JuMinuteY = ""
    let JuRX = false
    let JuRXX = ""
    let JuRXY = ""
    let SaPlanetX = ""
    let SaPlanetY = ""
    let SaSignX =""
    let SaSignY =""
    let SaDegree = ""
    let SaDegreeX = ""
    let SaDegreeY = ""
    let SaMinute = ""
    let SaMinuteX = ""
    let SaMinuteY = ""
    let SaRX = false
    let SaRXX = ""
    let SaRXY = ""
    let UrPlanetX = ""
    let UrPlanetY = ""
    let UrSignX =""
    let UrSignY =""
    let UrDegree = ""
    let UrDegreeX = ""
    let UrDegreeY = ""
    let UrMinute = ""
    let UrMinuteX = ""
    let UrMinuteY = ""
    let UrRX = false
    let UrRXX = ""
    let UrRXY = ""
    let NePlanetX = ""
    let NePlanetY = ""
    let NeSignX =""
    let NeSignY =""
    let NeDegree = ""
    let NeDegreeX = ""
    let NeDegreeY = ""
    let NeMinute = ""
    let NeMinuteX = ""
    let NeMinuteY = ""
    let NeRX = false
    let NeRXX = ""
    let NeRXY = ""
    let PlPlanetX = ""
    let PlPlanetY = ""
    let PlSignX =""
    let PlSignY =""
    let PlDegree = ""
    let PlDegreeX = ""
    let PlDegreeY = ""
    let PlMinute = ""
    let PlMinuteX = ""
    let PlMinuteY = ""
    let PlRX = false
    let PlRXX = ""
    let PlRXY = ""
    let RaPlanetX = ""
    let RaPlanetY = ""
    let RaSignX =""
    let RaSignY =""
    let RaDegree = ""
    let RaDegreeX = ""
    let RaDegreeY = ""
    let RaMinute = ""
    let RaMinuteX = ""
    let RaMinuteY = ""
    let RaRX = true
    let RaRXX = ""
    let RaRXY = ""
    let KePlanetX = ""
    let KePlanetY = ""
    let KeSignX =""
    let KeSignY =""
    let KeDegree = ""
    let KeDegreeX = ""
    let KeDegreeY = ""
    let KeMinute = ""
    let KeMinuteX = ""
    let KeMinuteY = ""
    let KeRX = true
    let KeRXX = ""
    let KeRXY = ""
    let ChPlanetX = ""
    let ChPlanetY = ""
    let ChSignX =""
    let ChSignY =""
    let ChDegree = ""
    let ChDegreeX = ""
    let ChDegreeY = ""
    let ChMinute = ""
    let ChMinuteX = ""
    let ChMinuteY = ""
    let ChRX = false
    let ChRXX = ""
    let ChRXY = ""
    let ASCPlanetX = ""
    let ASCPlanetY = ""
    let ASCSignX =""
    let ASCSignY =""
    let ASCDegree = ""
    let ASCDegreeX = ""
    let ASCDegreeY = ""
    let ASCMinute = ""
    let ASCMinuteX = ""
    let ASCMinuteY = ""
    let DSCPlanetX = ""
    let DSCPlanetY = ""
    let DSCSignX =""
    let DSCSignY =""
    let DSCDegree = ""
    let DSCDegreeX = ""
    let DSCDegreeY = ""
    let DSCMinute = ""
    let DSCMinuteX = ""
    let DSCMinuteY = ""
    let ICPlanetX = ""
    let ICPlanetY = ""
    let ICSignX =""
    let ICSignY =""
    let ICDegree = ""
    let ICDegreeX = ""
    let ICDegreeY = ""
    let ICMinute = ""
    let ICMinuteX = ""
    let ICMinuteY = ""
    let MCPlanetX = ""
    let MCPlanetY = ""
    let MCSignX =""
    let MCSignY =""
    let MCDegree = ""
    let MCDegreeX = ""
    let MCDegreeY = ""
    let MCMinute = ""
    let MCMinuteX = ""
    let MCMinuteY = ""
 

    let planet = null
    let degree = null
    let sign = null
    let minute = null
    let rx = null
   


    interface ChartInfo  {
    
        utc_date: any;
        signs: any;
        planets: any;
    
  }
  
    async function fetchNewPlanets(direction: string, interval: string, tid: string, lat: string, long: string): Promise<ChartInfo> {
      const response = 
      (process.env.NODE_ENV=='production') ?
      await fetch(`http://planetgazer.net:8000/${direction}/${interval}?tid=${tid}&lat=${lat}&long=${long}`)
      :
      await fetch(`http://localhost:8000/${direction}/${interval}?tid=${tid}&lat=${lat}&long=${long}`);
  
      //await fetch(`http://localhost/api/${direction}/${interval}?tid=${tid}&lat=${lat}&long=${long}`);
      return response.json();
    }
      
    const {data: stransits , error, isLoading, isSuccess, refetch} = useQuery<ChartInfo>(
      { 
      // placeholderData: "",
      queryKey: ['stransits','forward','day',varTid],
      queryFn: () => fetchNewPlanets('forward','day',varTid, props.lat.current, props.long.current),
      enabled: false,
      staleTime: Infinity
  
      });

   
    const handleOnClick  = async  () => { 

        props.setSelectedSectionType("day");
        // props.dayCount.current -= 
        props.setDefaultDate(props.defaultDate.add(1, 'day'))
        varTid=(dayjs(props.defaultDate).add(1, 'day').toISOString()) 
        props.setAllowedToFetch(false)
        await refetch()
            
       };


    useEffect(() => {

        if (stransits !== null || stransits !== undefined)
            {props.tid.current = stransits?.utc_date}
 

        console.log("ptid:" + props.ptid.current)
        console.log("tid:" + varTid)

        console.log(stransits)

        props.setSignVals({
            AriSignX: stransits?.signs[0].coord[0],
            AriSignY: stransits?.signs[0].coord[1],
            TauSignX: stransits?.signs[1].coord[0],
            TauSignY: stransits?.signs[1].coord[1],
            GemSignX: stransits?.signs[2].coord[0],
            GemSignY: stransits?.signs[2].coord[1],
            CanSignX: stransits?.signs[3].coord[0],
            CanSignY: stransits?.signs[3].coord[1],
            LeoSignX: stransits?.signs[4].coord[0],
            LeoSignY: stransits?.signs[4].coord[1],
            VirSignX: stransits?.signs[5].coord[0],
            VirSignY: stransits?.signs[5].coord[1],
            LibSignX: stransits?.signs[6].coord[0],
            LibSignY: stransits?.signs[6].coord[1],
            ScoSignX: stransits?.signs[7].coord[0],
            ScoSignY: stransits?.signs[7].coord[1],
            SagSignX: stransits?.signs[8].coord[0],
            SagSignY: stransits?.signs[8].coord[1],
            CapSignX: stransits?.signs[9].coord[0],
            CapSignY: stransits?.signs[9].coord[1],
            AquSignX: stransits?.signs[10].coord[0],
            AquSignY: stransits?.signs[10].coord[1],
            PisSignX: stransits?.signs[11].coord[0],
            PisSignY: stransits?.signs[11].coord[1]
    
        })

        
        stransits?.planets.map((pl: any) => { 

    
            if (pl.planet == "Mo") {
                props.setMoVals( {
                    MoPlanetX : pl.coord[0][0], MoPlanetY : pl.coord[0][1], MoSignX : pl.coord[2][0], MoSignY : pl.coord[2][1], MoDegreeX : pl.coord[1][0], MoDegreeY : pl.coord[1][1], MoMinuteX : pl.coord[3][0], MoMinuteY : pl.coord[3][1],
                    MoSign: pl.newSign, MoSignSym: pl.newSignSymbol, MoDegree: pl.newDegree, MoMinute: pl.newMinute } )
            }
    
            
            if (pl.planet == "Me") {
                props.setMeVals( {
                    MePlanetX : pl.coord[0][0], MePlanetY : pl.coord[0][1], MeSignX : pl.coord[2][0], MeSignY : pl.coord[2][1], MeDegreeX : pl.coord[1][0], MeDegreeY : pl.coord[1][1], MeMinuteX : pl.coord[3][0], MeMinuteY : pl.coord[3][1], MeRXX: pl.coord[4][0], MeRXY: pl.coord[4][1],
                    MeSign: pl.newSign, MeSignSym: pl.newSignSymbol, MeDegree: pl.newDegree, MeMinute: pl.newMinute, MeRX:pl.rx } )
            }

            if (pl.planet == "Ve") {
                props.setVeVals( {
                    VePlanetX : pl.coord[0][0], VePlanetY : pl.coord[0][1], VeSignX : pl.coord[2][0], VeSignY : pl.coord[2][1], VeDegreeX : pl.coord[1][0], VeDegreeY : pl.coord[1][1], VeMinuteX : pl.coord[3][0], VeMinuteY : pl.coord[3][1], VeRXX: pl.coord[4][0], VeRXY: pl.coord[4][1],
                    VeSign: pl.newSign, VeSignSym: pl.newSignSymbol, VeDegree: pl.newDegree, VeMinute: pl.newMinute, VeRX:pl.rx } )
            }

            if (pl.planet == "Su") {
                props.setSuVals( {
                    SuPlanetX : pl.coord[0][0], SuPlanetY : pl.coord[0][1], SuSignX : pl.coord[2][0], SuSignY : pl.coord[2][1], SuDegreeX : pl.coord[1][0], SuDegreeY : pl.coord[1][1], SuMinuteX : pl.coord[3][0], SuMinuteY : pl.coord[3][1],
                    SuSign: pl.newSign, SuSignSym: pl.newSignSymbol, SuDegree: pl.newDegree, SuMinute: pl.newMinute } )
            }

            if (pl.planet == "Ma") {
                props.setMaVals( {
                    MaPlanetX : pl.coord[0][0], MaPlanetY : pl.coord[0][1], MaSignX : pl.coord[2][0], MaSignY : pl.coord[2][1], MaDegreeX : pl.coord[1][0], MaDegreeY : pl.coord[1][1], MaMinuteX : pl.coord[3][0], MaMinuteY : pl.coord[3][1], MaRXX: pl.coord[4][0], MaRXY: pl.coord[4][1],
                    MaSign: pl.newSign, MaSignSym: pl.newSignSymbol, MaDegree: pl.newDegree, MaMinute: pl.newMinute, MaRX:pl.rx } )
            }
            if (pl.planet == "Ju") {
                props.setJuVals( {
                    JuPlanetX : pl.coord[0][0], JuPlanetY : pl.coord[0][1], JuSignX : pl.coord[2][0], JuSignY : pl.coord[2][1], JuDegreeX : pl.coord[1][0], JuDegreeY : pl.coord[1][1], JuMinuteX : pl.coord[3][0], JuMinuteY : pl.coord[3][1], JuRXX: pl.coord[4][0], JuRXY: pl.coord[4][1],
                    JuSign: pl.newSign, JuSignSym: pl.newSignSymbol, JuDegree: pl.newDegree, JuMinute: pl.newMinute, JuRX:pl.rx } )
            }
            if (pl.planet == "Sa") {
                props.setSaVals( {
                    SaPlanetX : pl.coord[0][0], SaPlanetY : pl.coord[0][1], SaSignX : pl.coord[2][0], SaSignY : pl.coord[2][1], SaDegreeX : pl.coord[1][0], SaDegreeY : pl.coord[1][1], SaMinuteX : pl.coord[3][0], SaMinuteY : pl.coord[3][1], SaRXX: pl.coord[4][0], SaRXY: pl.coord[4][1],
                    SaSign: pl.newSign, SaSignSym: pl.newSignSymbol, SaDegree: pl.newDegree, SaMinute: pl.newMinute, SaRX:pl.rx } )
            }
            
            if (pl.planet == "Ur") {
                props.setUrVals( {
                    UrPlanetX : pl.coord[0][0], UrPlanetY : pl.coord[0][1], UrSignX : pl.coord[2][0], UrSignY : pl.coord[2][1], UrDegreeX : pl.coord[1][0], UrDegreeY : pl.coord[1][1], UrMinuteX : pl.coord[3][0], UrMinuteY : pl.coord[3][1], UrRXX: pl.coord[4][0], UrRXY: pl.coord[4][1],
                    UrSign: pl.newSign, UrSignSym: pl.newSignSymbol, UrDegree: pl.newDegree, UrMinute: pl.newMinute, UrRX:pl.rx } )
            }
            if (pl.planet == "Ne") {
                props.setNeVals( {
                    NePlanetX : pl.coord[0][0], NePlanetY : pl.coord[0][1], NeSignX : pl.coord[2][0], NeSignY : pl.coord[2][1], NeDegreeX : pl.coord[1][0], NeDegreeY : pl.coord[1][1], NeMinuteX : pl.coord[3][0], NeMinuteY : pl.coord[3][1], NeRXX: pl.coord[4][0], NeRXY: pl.coord[4][1],
                    NeSign: pl.newSign, NeSignSym: pl.newSignSymbol, NeDegree: pl.newDegree, NeMinute: pl.newMinute, NeRX:pl.rx } )
            }
            if (pl.planet == "Pl") {
                props.setPlVals( {
                    PlPlanetX : pl.coord[0][0], PlPlanetY : pl.coord[0][1], PlSignX : pl.coord[2][0], PlSignY : pl.coord[2][1], PlDegreeX : pl.coord[1][0], PlDegreeY : pl.coord[1][1], PlMinuteX : pl.coord[3][0], PlMinuteY : pl.coord[3][1], PlRXX: pl.coord[4][0], PlRXY: pl.coord[4][1],
                    PlSign: pl.newSign, PlSignSym: pl.newSignSymbol, PlDegree: pl.newDegree, PlMinute: pl.newMinute, PlRX:pl.rx } )
            }
            if (pl.planet == "Ra") {
                props.setRaVals( {
                    RaPlanetX : pl.coord[0][0], RaPlanetY : pl.coord[0][1], RaSignX : pl.coord[2][0], RaSignY : pl.coord[2][1], RaDegreeX : pl.coord[1][0], RaDegreeY : pl.coord[1][1], RaMinuteX : pl.coord[3][0], RaMinuteY : pl.coord[3][1], RaRXX: pl.coord[4][0], RaRXY: pl.coord[4][1],
                    RaSign: pl.newSign, RaSignSym: pl.newSignSymbol, RaDegree: pl.newDegree, RaMinute: pl.newMinute} )
            }
            if (pl.planet == "Ke") {
                props.setKeVals( {
                    KePlanetX : pl.coord[0][0], KePlanetY : pl.coord[0][1], KeSignX : pl.coord[2][0], KeSignY : pl.coord[2][1], KeDegreeX : pl.coord[1][0], KeDegreeY : pl.coord[1][1], KeMinuteX : pl.coord[3][0], KeMinuteY : pl.coord[3][1], KeRXX: pl.coord[4][0], KeRXY: pl.coord[4][1],
                    KeSign: pl.newSign, KeSignSym: pl.newSignSymbol, KeDegree: pl.newDegree, KeMinute: pl.newMinute } )
            }
            if (pl.planet == "Ch") {
                props.setChVals( {
                    ChPlanetX : pl.coord[0][0], ChPlanetY : pl.coord[0][1], ChSignX : pl.coord[2][0], ChSignY : pl.coord[2][1], ChDegreeX : pl.coord[1][0], ChDegreeY : pl.coord[1][1], ChMinuteX : pl.coord[3][0], ChMinuteY : pl.coord[3][1], ChRXX: pl.coord[4][0], ChRXY: pl.coord[4][1],
                    ChSign: pl.newSign, ChSignSym: pl.newSignSymbol, ChDegree: pl.newDegree, ChMinute: pl.newMinute, ChRX:pl.rx } )
            }
            if (pl.planet == "ASC") {
                props.setASCVals( {
                    ASCPlanetX : pl.coord[0][0], ASCPlanetY : pl.coord[0][1], ASCSignX : pl.coord[2][0], ASCSignY : pl.coord[2][1], ASCDegreeX : pl.coord[1][0], ASCDegreeY : pl.coord[1][1], ASCMinuteX : pl.coord[3][0], ASCMinuteY : pl.coord[3][1],
                    ASCSign: pl.newSign, ASCSignSym: pl.newSignSymbol, ASCDegree: pl.newDegree, ASCMinute: pl.newMinute } )
            }
            if (pl.planet == "DSC") {
                props.setDSCVals( {
                    DSCPlanetX : pl.coord[0][0], DSCPlanetY : pl.coord[0][1], DSCSignX : pl.coord[2][0], DSCSignY : pl.coord[2][1], DSCDegreeX : pl.coord[1][0], DSCDegreeY : pl.coord[1][1], DSCMinuteX : pl.coord[3][0], DSCMinuteY : pl.coord[3][1],
                    DSCSign: pl.newSign, DSCSignSym: pl.newSignSymbol, DSCDegree: pl.newDegree, DSCMinute: pl.newMinute } )
            }
            if (pl.planet == "IC") {
                props.setICVals( {
                    ICPlanetX : pl.coord[0][0], ICPlanetY : pl.coord[0][1], ICSignX : pl.coord[2][0], ICSignY : pl.coord[2][1], ICDegreeX : pl.coord[1][0], ICDegreeY : pl.coord[1][1], ICMinuteX : pl.coord[3][0], ICMinuteY : pl.coord[3][1],
                    ICSign: pl.newSign, ICSignSym: pl.newSignSymbol, ICDegree: pl.newDegree, ICMinute: pl.newMinute } )
            }
            if (pl.planet == "MC") {
                props.setMCVals( {
                    MCPlanetX : pl.coord[0][0], MCPlanetY : pl.coord[0][1], MCSignX : pl.coord[2][0], MCSignY : pl.coord[2][1], MCDegreeX : pl.coord[1][0], MCDegreeY : pl.coord[1][1], MCMinuteX : pl.coord[3][0], MCMinuteY : pl.coord[3][1],
                    MCSign: pl.newSign, MCSignSym: pl.newSignSymbol, MCDegree: pl.newDegree, MCMinute: pl.newMinute } )
            }
            

            // let planet = document.getElementById('Ve-planet');
            // if (planet != null) planet.setAttribute("transform", `translate(3, 33)`);
            planet = document.getElementById(pl.planet + "-planet");
            degree = document.getElementById(pl.planet + "-degree");
            sign = document.getElementById(pl.planet + "-sign");
            minute = document.getElementById(pl.planet + "-minute");
            rx = document.getElementById(pl.planet + "-rx");
            if (planet != null) planet.setAttribute("transform", "translate(" + pl.coord_changed[0][0] + "," + pl.coord_changed[0][1] + ")");
            if (degree != null) degree.setAttribute("transform", "translate(" + pl.coord_changed[1][0] + "," + pl.coord_changed[1][1] + ")");
            if (sign != null) sign.setAttribute("transform", "translate(" + pl.coord_changed[2][0] + "," + pl.coord_changed[2][1] + ")");
            if (minute != null) minute.setAttribute("transform", "translate(" + pl.coord_changed[3][0] + "," + pl.coord_changed[3][1] + ")");
            if (rx != null) rx.setAttribute("transform", "translate(" + pl.coord_changed[4][0] + "," + pl.coord_changed[4][1] + ")"); 
           
        
            // if (planet != null && pl_coordX != '0') console.log("'" + pl_name + " degree'")
            // if (planet != null && pl_coordX != '0') console.log(degree)
            // if (planet != null && pl_coordX != '0') console.log("`translate(" + deg_coordX + "," + deg_coordY + ")'")
    
                       
        })

    },[stransits])

   
   
    return <div className="circle-arrow-forward">
        <Container> 
            <IconButton onClick={handleOnClick} aria-label="forward">
            <SkipNextIcon/>
            </IconButton>
        </Container>

    </div>
    


    // if(isLoading){
    //     return <h3>Loading...</h3>
    // }

    // if(error){
    //     return <pre>{JSON.stringify(error)}</pre>
    // }



    // const { data, isLoading, isSuccess, mutate} = useMutation( 
        
    //     { 

    //     mutationFn: (delta: string) => 
    //         fetch('http://localhost/api/' + {delta},{
    //             method: "POST",
    //             body: JSON.stringify(delta)
    //         })
    //     .then((res) => res.json())

    // });

    // if(isLoading){

    //     console.log("is Loading")
         // return <h3>Loading...</h3>
    // }
    // if(error){
    //     return <pre>{JSON.stringify(error)}</pre>
    // }
    // console.log(data)
    
}


