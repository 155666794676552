import * as React from "react"


interface Props {
  centerX: string,
  centerY: string,
  signRadius: string,
  signBorderRadius: string,
  planetRadius: string,
  houseRadius: string,
  centerRadius: string,
  DegreeSym: string,
  AriSignX: string,
  AriSignY: string,
  TauSignX: string,
  TauSignY: string,
  GemSignX: string,
  GemSignY: string,
  CanSignX: string,
  CanSignY: string,
  LeoSignX: string,
  LeoSignY: string,
  VirSignX: string,
  VirSignY: string,
  LibSignX: string,
  LibSignY: string,
  ScoSignX: string,
  ScoSignY: string,
  SagSignX: string,
  SagSignY: string,
  CapSignX: string,
  CapSignY: string,
  AquSignX: string,
  AquSignY: string,
  PisSignX: string,
  PisSignY: string,
  RXSym: string,
  MoPlanetX: string,
  MoPlanetY: string,
  MoSym:string,
  MoSign: string,
  MoSignSym: string,
  MoSignX: string,
  MoSignY: string,
  MoDegree: string,
  MoDegreeX: string,
  MoDegreeY: string,
  MoMinute: string,
  MoMinuteX: string,
  MoMinuteY: string,
  VeSym:string,
  VePlanetX: string,
  VePlanetY: string,
  VeSign: string,
  VeSignSym: string,
  VeSignX: string,
  VeSignY: string,
  VeDegree: string,
  VeDegreeX: string,
  VeDegreeY: string,
  VeMinute: string,
  VeMinuteX: string,
  VeMinuteY: string,
  VeRX: boolean,
  VeRXX: string,
  VeRXY: string,
  ASCSym:string,
  ASCPlanetX: string,
  ASCPlanetY: string,
  ASCSign: string,
  ASCSignSym: string,
  ASCSignX: string,
  ASCSignY: string,
  ASCDegree: string,
  ASCDegreeX: string,
  ASCDegreeY: string,
  ASCMinute: string,
  ASCMinuteX: string,
  ASCMinuteY: string,
  RaSym:string,
  RaPlanetX: string,
  RaPlanetY: string,
  RaSign: string,
  RaSignSym: string,
  RaSignX: string,
  RaSignY: string,
  RaDegree: string,
  RaDegreeX: string,
  RaDegreeY: string,
  RaMinute: string,
  RaMinuteX: string,
  RaMinuteY: string,
  RaRX: boolean,
  RaRXX: string,
  RaRXY: string,
  SuSym:string,
  SuPlanetX: string,
  SuPlanetY: string,
  SuSign: string,
  SuSignSym: string,
  SuSignX: string,
  SuSignY: string,
  SuDegree: string,
  SuDegreeX: string,
  SuDegreeY: string,
  SuMinute: string,
  SuMinuteX: string,
  SuMinuteY: string,
  ChSym:string,
  ChPlanetX: string,
  ChPlanetY: string,
  ChSign: string,
  ChSignSym: string,
  ChSignX: string,
  ChSignY: string,
  ChDegree: string,
  ChDegreeX: string,
  ChDegreeY: string,
  ChMinute: string,
  ChMinuteX: string,
  ChMinuteY: string,
  ChRX: boolean,
  ChRXX: string,
  ChRXY: string,
  MeSym:string,
  MePlanetX: string,
  MePlanetY: string,
  MeSign: string,
  MeSignSym: string,
  MeSignX: string,
  MeSignY: string,
  MeDegree: string,
  MeDegreeX: string,
  MeDegreeY: string,
  MeMinute: string,
  MeMinuteX: string,
  MeMinuteY: string,
  MeRX: boolean,
  MeRXX: string,
  MeRXY: string,
  JuSym:string,
  JuPlanetX: string,
  JuPlanetY: string,
  JuSign: string,
  JuSignSym: string,
  JuSignX: string,
  JuSignY: string,
  JuDegree: string,
  JuDegreeX: string,
  JuDegreeY: string,
  JuMinute: string,
  JuMinuteX: string,
  JuMinuteY: string,
  JuRX: boolean,
  JuRXX: string,
  JuRXY: string,
  UrSym:string,
  UrPlanetX: string,
  UrPlanetY: string,
  UrSign: string,
  UrSignSym: string,
  UrSignX: string,
  UrSignY: string,
  UrDegree: string,
  UrDegreeX: string,
  UrDegreeY: string,
  UrMinute: string,
  UrMinuteX: string,
  UrMinuteY: string,
  UrRX: boolean,
  UrRXX: string,
  UrRXY: string,
  ICSym:string,
  ICPlanetX: string,
  ICPlanetY: string,
  ICSign: string,
  ICSignSym: string,
  ICSignX: string,
  ICSignY: string,
  ICDegree: string,
  ICDegreeX: string,
  ICDegreeY: string,
  ICMinute: string,
  ICMinuteX: string,
  ICMinuteY: string,
  DSCSym:string,
  DSCPlanetX: string,
  DSCPlanetY: string,
  DSCSign: string,
  DSCSignSym: string,
  DSCSignX: string,
  DSCSignY: string,
  DSCDegree: string,
  DSCDegreeX: string,
  DSCDegreeY: string,
  DSCMinute: string,
  DSCMinuteX: string,
  DSCMinuteY: string,
  KeSym:string,
  KePlanetX: string,
  KePlanetY: string,
  KeSign: string,
  KeSignSym: string,
  KeSignX: string,
  KeSignY: string,
  KeDegree: string,
  KeDegreeX: string,
  KeDegreeY: string,
  KeMinute: string,
  KeMinuteX: string,
  KeMinuteY: string,
  KeRX: boolean,
  KeRXX: string,
  KeRXY: string,
  MCSym:string,
  MCPlanetX: string,
  MCPlanetY: string,
  MCSign: string,
  MCSignSym: string,
  MCSignX: string,
  MCSignY: string,
  MCDegree: string,
  MCDegreeX: string,
  MCDegreeY: string,
  MCMinute: string,
  MCMinuteX: string,
  MCMinuteY: string,
  PlSym:string,
  PlPlanetX: string,
  PlPlanetY: string,
  PlSign: string,
  PlSignSym: string,
  PlSignX: string,
  PlSignY: string,
  PlDegree: string,
  PlDegreeX: string,
  PlDegreeY: string,
  PlMinute: string,
  PlMinuteX: string,
  PlMinuteY: string,
  PlRX: boolean,
  PlRXX: string,
  PlRXY: string,
  MaSym:string,
  MaPlanetX: string,
  MaPlanetY: string,
  MaSign: string,
  MaSignSym: string,
  MaSignX: string,
  MaSignY: string,
  MaDegree: string,
  MaDegreeX: string,
  MaDegreeY: string,
  MaMinute: string,
  MaMinuteX: string,
  MaMinuteY: string,
  MaRX: boolean,
  MaRXX: string,
  MaRXY: string,
  SaSym:string,
  SaPlanetX: string,
  SaPlanetY: string,
  SaSign: string,
  SaSignSym: string,
  SaSignX: string,
  SaSignY: string,
  SaDegree: string,
  SaDegreeX: string,
  SaDegreeY: string,
  SaMinute: string,
  SaMinuteX: string,
  SaMinuteY: string,
  SaRX: boolean,
  SaRXX: string,
  SaRXY: string,
  NeSym:string,
  NePlanetX: string,
  NePlanetY: string,
  NeSign: string,
  NeSignSym: string,
  NeSignX: string,
  NeSignY: string,
  NeDegree: string,
  NeDegreeX: string,
  NeDegreeY: string,
  NeMinute: string,
  NeMinuteX: string,
  NeMinuteY: string,
  NeRX: boolean,
  NeRXX: string,
  NeRXY: string,

}

export const ChartSvg = ({
  centerX = '475',
  centerY = '475',
  signRadius = '525',
  signBorderRadius = '526',
  planetRadius = '400',
  houseRadius = '300',
  centerRadius = '50',
  DegreeSym = '°',
  AriSignX = '-46',
  AriSignY = '614',
  TauSignX = '93',
  TauSignY = '856',
  GemSignX = '335',
  GemSignY = '996',
  CanSignX = '614',
  CanSignY = '996',
  LeoSignX = '856',
  LeoSignY = '856',
  VirSignX = '996',
  VirSignY = '614',
  LibSignX = '996',
  LibSignY = '335',
  ScoSignX = '856',
  ScoSignY = '93',
  SagSignX = '614',
  SagSignY = '-46',
  CapSignX = '335',
  CapSignY = '-46',
  AquSignX = '93',
  AquSignY = '93',
  PisSignX = '-46',
  PisSignY = '335',
  RXSym = 'M',
  MoPlanetX = '25',
  MoPlanetY = '503',
  MoSym = 'R',
  MoSign = 'Ari',
  MoSignSym = 'A',
  MoSignX = '110',
  MoSignY = '497',
  MoDegree = '1',
  MoDegreeX = '75',
  MoDegreeY = '500',
  MoMinute = '22',
  MoMinuteX = '140',
  MoMinuteY = '495',
  VePlanetX = '29',
  VePlanetY = '536',
  VeSym = 'T',
  VeSign = 'Ari',
  VeSignSym = 'A',
  VeSignX = '113',
  VeSignY = '525',
  VeDegree = '2',
  VeDegreeX = '78',
  VeDegreeY = '529',
  VeMinute = '58',
  VeMinuteX = '143',
  VeMinuteY = '520',
  VeRX = false,
  VeRXX = '0',
  VeRXY = '0',
  ASCSym= 'a',
  ASCPlanetX = '35',
  ASCPlanetY = '570',
  ASCSign = 'Ari',
  ASCSignSym = 'A',
  ASCSignX = '118',
  ASCSignY = '552',
  ASCDegree = '14',
  ASCDegreeX = '84',
  ASCDegreeY = '559',
  ASCMinute = '47',
  ASCMinuteX = '147',
  ASCMinuteY = '545',
  RaSym = '<',
  RaPlanetX = '43',
  RaPlanetY = '602',
  RaSign = 'Ari',
  RaSignSym = 'A',
  RaSignX = '125',
  RaSignY = '578',
  RaDegree = '15',
  RaDegreeX = '91',
  RaDegreeY = '588',
  RaMinute = '38',
  RaMinuteX = '153',
  RaMinuteY = '570',
  RaRX = true,
  RaRXX = '0',
  RaRXY = '0',
  SuSym = 'Q',
  SuPlanetX = '54',
  SuPlanetY = '634',
  SuSign = 'Ari',
  SuSignSym = 'A',
  SuSignX = '133',
  SuSignY = '604',
  SuDegree = '18',
  SuDegreeX = '101',
  SuDegreeY = '617',
  SuMinute = '13',
  SuMinuteX = '161',
  SuMinuteY = '593',
  ChSym = 't',
  ChPlanetX = '67',
  ChPlanetY = '665',
  ChSign = 'Ari',
  ChSignSym = 'A',
  ChSignX = '144',
  ChSignY = '629',
  ChDegree = '19',
  ChDegreeX = '112',
  ChDegreeY = '644',
  ChMinute = '20',
  ChMinuteX = '171',
  ChMinuteY = '617',
  ChRX = false,
  ChRXX = '0',
  ChRXY = '0',
  MeSym = 'S',
  MePlanetX = '82',
  MePlanetY = '695',
  MeSign = 'Ari',
  MeSignSym = 'A',
  MeSignX = '157',
  MeSignY = '654',
  MeDegree = '25',
  MeDegreeX = '126',
  MeDegreeY = '671',
  MeMinute = '31',
  MeMinuteX = '183',
  MeMinuteY = '639',
  MeRX = true,
  MeRXX = '209',
  MeRXY = '624',
  JuSym = 'V',
  JuPlanetX = '165',
  JuPlanetY = '802',
  JuSign = 'Tau',
  JuSignSym = 'B',
  JuSignX = '224',
  JuSignY = '740',
  JuDegree = '18',
  JuDegreeX = '200',
  JuDegreeY = '765',
  JuMinute = '47',
  JuMinuteX = '244',
  JuMinuteY = '718',
  JuRX = false,
  JuRXX = '0',
  JuRXY = '0',
  UrSym = 'X',
  UrPlanetX = '200',
  UrPlanetY = '831',
  UrSign = 'Tau',
  UrSignSym = 'B',
  UrSignX = '252',
  UrSignY = '763',
  UrDegree = '21',
  UrDegreeX = '230',
  UrDegreeY = '791',
  UrMinute = '7',
  UrMinuteX = '270',
  UrMinuteY = '740',
  UrRX = false,
  UrRXX = '0',
  UrRXY = '0',
  ICSym= 'c',
  ICPlanetX = '539',
  ICPlanetY = '920',
  ICSign = 'Can',
  ICSignSym = 'D',
  ICSignX = '527',
  ICSignY = '836',
  ICDegree = '7',
  ICDegreeX = '532',
  ICDegreeY = '870',
  ICMinute = '1',
  ICMinuteX = '523',
  ICMinuteY = '806',
  DSCSym = 'DC',
  DSCPlanetX = '916',
  DSCPlanetY = '385',
  DSCSign = 'Lib',
  DSCSignSym = 'G',
  DSCSignX = '832',
  DSCSignY = '402',
  DSCDegree = '14',
  DSCDegreeX = '867',
  DSCDegreeY = '395',
  DSCMinute = '47',
  DSCMinuteX = '803',
  DSCMinuteY = '408',
  KeSym = '>',
  KePlanetX = '908',
  KePlanetY = '352',
  KeSign = 'Lib',
  KeSignSym = 'G',
  KeSignX = '826',
  KeSignY = '375',
  KeDegree = '15',
  KeDegreeX = '859',
  KeDegreeY = '366',
  KeMinute = '38',
  KeMinuteX = '797',
  KeMinuteY = '383',
  KeRX = true,
  KeRXX = '0',
  KeRXY = '0',
  MCSym = 'b',
  MCPlanetX = '416',
  MCPlanetY = '28',
  MCSign = 'Cap',
  MCSignSym = 'J',
  MCSignX = '427',
  MCSignY = '113',
  MCDegree = '7',
  MCDegreeX = '422',
  MCDegreeY = '78',
  MCMinute = '1',
  MCMinuteX = '431',
  MCMinuteY = '142',
  PlSym = 'Z',
  PlPlanetX = '227',
  PlPlanetY = '99',
  PlSign = 'Aqu',
  PlSignSym = 'K',
  PlSignX = '274',
  PlSignY = '170',
  PlDegree = '1',
  PlDegreeX = '255',
  PlDegreeY = '140',
  PlMinute = '57',
  PlMinuteX = '290',
  PlMinuteY = '195',
  PlRX = false,
  PlRXX = '0',
  PlRXY = '0',
  MaSym = 'U',
  MaPlanetX = '46',
  MaPlanetY = '336',
  MaSign = 'Pis',
  MaSignSym = 'L',
  MaSignX = '127',
  MaSignY = '362',
  MaDegree = '12',
  MaDegreeX = '94',
  MaDegreeY = '351',
  MaMinute = '7',
  MaMinuteX = '156',
  MaMinuteY = '371',
  MaRX = false,
  MaRXX = '0',
  MaRXY = '0',
  SaSym = 'W',
  SaPlanetX = '37',
  SaPlanetY = '368',
  SaSign = 'Pis',
  SaSignSym = 'L',
  SaSignX = '120',
  SaSignY = '389',
  SaDegree = '14',
  SaDegreeX = '86',
  SaDegreeY = '380',
  SaMinute = '20',
  SaMinuteX = '149',
  SaMinuteY = '396',
  SaRX = false,
  SaRXX = '0',
  SaRXY = '0',
  NeSym = 'Y',
  NePlanetX = '25',
  NePlanetY = '458',
  NeSign = 'Pis',
  NeSignSym = 'L',
  NeSignX = '110',
  NeSignY = '461',
  NeDegree = '28',
  NeDegreeX = '75',
  NeDegreeY = '459',
  NeMinute = '9',
  NeMinuteX = '140',
  NeMinuteY = '462',
  NeRX = false,
  NeRXX = '0',
  NeRXY = '0',
 
}: Props
  ) => (
  //props: SVGProps<SVGSVGElement> 
  <svg viewBox="-120, -120, 1190, 1190" className="chart" >
    {/* {...props}  */}
    <circle
      id="sign-ring"
      className="sign-ring"
      fill="currentColor"
      cx={centerX}
      cy={centerY}
      r={signRadius}
    />
    <g id="sign-ring" className="sign-ring__content">
    <text
        id="Ari-sign"
        fill="currentColor"
        x={AriSignX}
        y={AriSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + AriSignX + ',' + AriSignY + ')'}
      >
        {"A"}
      </text>
      <text
        id="Tau-sign"
        fill="currentColor"
        x={TauSignX}
        y={TauSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + TauSignX + ',' + TauSignY+ ')'}
      >
        {"B"}
      </text>
      <text
        id="Gem-sign"
        fill="currentColor"
        x={GemSignX}
        y={GemSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + GemSignX+ ',' + GemSignY + ')'}
      >
        {"C"}
      </text>
      <text
        id="Can-sign"
        fill="currentColor"
        x={CanSignX}
        y={CanSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + CanSignX + ',' + CanSignY + ')'}
        >
        {"D"}
      </text>
      <text
        id="Leo-sign"
        fill="currentColor"
        x={LeoSignX}
        y={LeoSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + LeoSignX + ',' + LeoSignY + ')'}
        >
        {"E"}
        </text>
        <text
        id="Vir-sign"
        fill="currentColor"
        x={VirSignX}
        y={VirSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + VirSignX + ',' + VirSignY + ')'}
        >
        {"F"}
        </text>
        <text
        id="Lib-sign"
        fill="currentColor"
        x={LibSignX}
        y={LibSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + LibSignX + ',' + LibSignY + ')'}
        >
        {"G"}
        </text>
        <text
        id="Sco-sign"
        fill="currentColor"
        x={ScoSignX}
        y={ScoSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + ScoSignX + ',' + ScoSignY + ')'}
        >
        {"H"}
        </text>
        <text
        id="Sag-sign"
        fill="currentColor"
        x={SagSignX}
        y={SagSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + SagSignX + ',' + SagSignY + ')'}
        >
        {"I"}
      </text>
      <text
        id="Cap-sign"
        fill="currentColor"
        x={CapSignX}
        y={CapSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + CapSignX + ',' + CapSignY + ')'}
        >
        {"J"}
      </text>
      <text
        id="Aqu-sign"
        fill="currentColor"
        x={AquSignX}
        y={AquSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + AquSignX + ',' + AquSignY + ')'}
        >
        {"K"}
      </text>
      <text
        id="Pis-sign"
        fill="currentColor"
        x={PisSignX}
        y={PisSignY}
        textAnchor="middle"
        dominantBaseline="central"
        transform={'rotate(0,' + PisSignX + ',' + PisSignY + ')'}
        >
        {"L"}
      </text>
    </g>
    <circle
      id="planet-ring"
      className="planet-ring"
      fill="currentColor"
      cx={centerX}
      cy={centerY}
      r={planetRadius}
    />
  

  <g id="axes" className="axes">
    <path id="1-axis" className="1-axis" d="M372 475 L-100 475 Z" />
    <path id="2-axis" className="2-axis" d="M386 526 L-22 762 Z" />
    <path id="3-axis" className="3-axis" d="M423 563 L187 972 Z" />
    <path id="4-axis" className="4-axis" d="M475 577 L475 1050 Z" />
    <path id="5-axis" className="5-axis" d="M526 563 L762 972 Z" />
    <path id="6-axis" className="6-axis" d="M563 526 L972 762 Z" />
    <path id="7-axis" className="7-axis" d="M577 475 L1050 475 Z" />
    <path id="8-axis" className="8-axis" d="M563 423 L972 187 Z" />
    <path id="9-axis" className="9-axis" d="M526 386 L762 -22 Z" />
    <path id="10-axis" className="10-axis" d="M475 372 L475 -100 Z" />
    <path id="11-axis" className="11-axis" d="M423 386 L187 -22 Z" />
    <path id="12-axis" className="12-axis" d="M386 423 L-22 187 Z" />
  </g>

  <circle
    id="house-ring"
    className="house-ring"
    fill="currentColor"
    cx={centerX}
    cy={centerY}
    r={houseRadius}
    />
    <circle
    id="center-ring"
    className="center-ring"
    fill="currentColor"
    cx={centerX}
    cy={centerY}
    r={centerRadius}
    />


    {/* <g id="axes" className="axes">
      <path id="1-axis" className="1-axis" d="M365 475 L0 475 Z" />
      <path id="2-axis" className="2-axis" d="M379 530 L63 712 Z" />
      <path id="3-axis" className="3-axis" d="M420 570 L237 886 Z" />
      <path id="4-axis" className="4-axis" d="M475 585 L475 950 Z" />
      <path id="5-axis" className="5-axis" d="M530 570 L712 886 Z" />
      <path id="6-axis" className="6-axis" d="M570 530 L886 712 Z" />
      <path id="7-axis" className="7-axis" d="M585 475 L950 475 Z" />
      <path id="8-axis" className="8-axis" d="M570 420 L886 237 Z" />
      <path id="9-axis" className="9-axis" d="M530 379 L712 63 Z" />
      <path id="10-axis" className="10-axis" d="M475 365 L475 0 Z" />
      <path id="11-axis" className="11-axis" d="M420 379 L237 63 Z" />
      <path id="12-axis" className="12-axis" d="M379 420 L63 237 Z" />
    </g> */}


<g id="axes" className="house-axes">
  <path id="1-axis" className="1-axis" d="M420 475 L270 475 Z" />
  <path id="2-axis" className="2-axis" d="M427 502 L297 577 Z" />
  <path id="3-axis" className="3-axis" d="M447 522 L372 652 Z" />
  <path id="4-axis" className="4-axis" d="M475 530 L475 680 Z" />
  <path id="5-axis" className="5-axis" d="M502 522 L577 652 Z" />
  <path id="6-axis" className="6-axis" d="M522 502 L652 577 Z" />
  <path id="7-axis" className="7-axis" d="M530 475 L680 475 Z" />
  <path id="8-axis" className="8-axis" d="M522 447 L652 372 Z" />
  <path id="9-axis" className="9-axis" d="M502 427 L577 297 Z" />
  <path id="10-axis" className="10-axis" d="M475 420 L475 270 Z" />
  <path id="11-axis" className="11-axis" d="M447 427 L372 297 Z" />
  <path id="12-axis" className="12-axis" d="M427 447 L297 372 Z" />
</g>
    {/* <g id="starburst" className="starburst">
      <path
        id="2-bisect-axis"
        className="bisect-axis"
        transform="rotate(2.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="4-bisect-axis"
        className="bisect-axis"
        transform="rotate(4.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="6-bisect-axis"
        className="bisect-axis"
        transform="rotate(6.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="8-bisect-axis"
        className="bisect-axis"
        transform="rotate(8.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="10-bisect-axis"
        className="bisect-axis"
        transform="rotate(10.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="12-bisect-axis"
        className="bisect-axis"
        transform="rotate(12.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="14-bisect-axis"
        className="bisect-axis"
        transform="rotate(14.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="16-bisect-axis"
        className="bisect-axis"
        transform="rotate(16.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="18-bisect-axis"
        className="bisect-axis"
        transform="rotate(18.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="20-bisect-axis"
        className="bisect-axis"
        transform="rotate(20.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="22-bisect-axis"
        className="bisect-axis"
        transform="rotate(22.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="24-bisect-axis"
        className="bisect-axis"
        transform="rotate(24.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="26-bisect-axis"
        className="bisect-axis"
        transform="rotate(26.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="28-bisect-axis"
        className="bisect-axis"
        transform="rotate(28.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="30-bisect-axis"
        className="bisect-axis"
        transform="rotate(30.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="32-bisect-axis"
        className="bisect-axis"
        transform="rotate(32.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="34-bisect-axis"
        className="bisect-axis"
        transform="rotate(34.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="36-bisect-axis"
        className="bisect-axis"
        transform="rotate(36.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="38-bisect-axis"
        className="bisect-axis"
        transform="rotate(38.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="40-bisect-axis"
        className="bisect-axis"
        transform="rotate(40.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="42-bisect-axis"
        className="bisect-axis"
        transform="rotate(42.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="44-bisect-axis"
        className="bisect-axis"
        transform="rotate(44.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="46-bisect-axis"
        className="bisect-axis"
        transform="rotate(46.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="48-bisect-axis"
        className="bisect-axis"
        transform="rotate(48.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="50-bisect-axis"
        className="bisect-axis"
        transform="rotate(50.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="52-bisect-axis"
        className="bisect-axis"
        transform="rotate(52.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="54-bisect-axis"
        className="bisect-axis"
        transform="rotate(54.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="56-bisect-axis"
        className="bisect-axis"
        transform="rotate(56.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="58-bisect-axis"
        className="bisect-axis"
        transform="rotate(58.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="60-bisect-axis"
        className="bisect-axis"
        transform="rotate(60.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="62-bisect-axis"
        className="bisect-axis"
        transform="rotate(62.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="64-bisect-axis"
        className="bisect-axis"
        transform="rotate(64.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="66-bisect-axis"
        className="bisect-axis"
        transform="rotate(66.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="68-bisect-axis"
        className="bisect-axis"
        transform="rotate(68.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="70-bisect-axis"
        className="bisect-axis"
        transform="rotate(70.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="72-bisect-axis"
        className="bisect-axis"
        transform="rotate(72.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="74-bisect-axis"
        className="bisect-axis"
        transform="rotate(74.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="76-bisect-axis"
        className="bisect-axis"
        transform="rotate(76.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="78-bisect-axis"
        className="bisect-axis"
        transform="rotate(78.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="80-bisect-axis"
        className="bisect-axis"
        transform="rotate(80.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="82-bisect-axis"
        className="bisect-axis"
        transform="rotate(82.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="84-bisect-axis"
        className="bisect-axis"
        transform="rotate(84.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="86-bisect-axis"
        className="bisect-axis"
        transform="rotate(86.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="88-bisect-axis"
        className="bisect-axis"
        transform="rotate(88.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="90-bisect-axis"
        className="bisect-axis"
        transform="rotate(90.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="92-bisect-axis"
        className="bisect-axis"
        transform="rotate(92.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="94-bisect-axis"
        className="bisect-axis"
        transform="rotate(94.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="96-bisect-axis"
        className="bisect-axis"
        transform="rotate(96.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="98-bisect-axis"
        className="bisect-axis"
        transform="rotate(98.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="100-bisect-axis"
        className="bisect-axis"
        transform="rotate(100.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="102-bisect-axis"
        className="bisect-axis"
        transform="rotate(102.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="104-bisect-axis"
        className="bisect-axis"
        transform="rotate(104.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="106-bisect-axis"
        className="bisect-axis"
        transform="rotate(106.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="108-bisect-axis"
        className="bisect-axis"
        transform="rotate(108.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="110-bisect-axis"
        className="bisect-axis"
        transform="rotate(110.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="112-bisect-axis"
        className="bisect-axis"
        transform="rotate(112.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="114-bisect-axis"
        className="bisect-axis"
        transform="rotate(114.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="116-bisect-axis"
        className="bisect-axis"
        transform="rotate(116.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="118-bisect-axis"
        className="bisect-axis"
        transform="rotate(118.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="120-bisect-axis"
        className="bisect-axis"
        transform="rotate(120.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="122-bisect-axis"
        className="bisect-axis"
        transform="rotate(122.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="124-bisect-axis"
        className="bisect-axis"
        transform="rotate(124.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="126-bisect-axis"
        className="bisect-axis"
        transform="rotate(126.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="128-bisect-axis"
        className="bisect-axis"
        transform="rotate(128.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="130-bisect-axis"
        className="bisect-axis"
        transform="rotate(130.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="132-bisect-axis"
        className="bisect-axis"
        transform="rotate(132.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="134-bisect-axis"
        className="bisect-axis"
        transform="rotate(134.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="136-bisect-axis"
        className="bisect-axis"
        transform="rotate(136.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="138-bisect-axis"
        className="bisect-axis"
        transform="rotate(138.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="140-bisect-axis"
        className="bisect-axis"
        transform="rotate(140.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="142-bisect-axis"
        className="bisect-axis"
        transform="rotate(142.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="144-bisect-axis"
        className="bisect-axis"
        transform="rotate(144.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="146-bisect-axis"
        className="bisect-axis"
        transform="rotate(146.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="148-bisect-axis"
        className="bisect-axis"
        transform="rotate(148.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="150-bisect-axis"
        className="bisect-axis"
        transform="rotate(150.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="152-bisect-axis"
        className="bisect-axis"
        transform="rotate(152.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="154-bisect-axis"
        className="bisect-axis"
        transform="rotate(154.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="156-bisect-axis"
        className="bisect-axis"
        transform="rotate(156.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="158-bisect-axis"
        className="bisect-axis"
        transform="rotate(158.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="160-bisect-axis"
        className="bisect-axis"
        transform="rotate(160.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="162-bisect-axis"
        className="bisect-axis"
        transform="rotate(162.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="164-bisect-axis"
        className="bisect-axis"
        transform="rotate(164.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="166-bisect-axis"
        className="bisect-axis"
        transform="rotate(166.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="168-bisect-axis"
        className="bisect-axis"
        transform="rotate(168.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="170-bisect-axis"
        className="bisect-axis"
        transform="rotate(170.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="172-bisect-axis"
        className="bisect-axis"
        transform="rotate(172.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="174-bisect-axis"
        className="bisect-axis"
        transform="rotate(174.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="176-bisect-axis"
        className="bisect-axis"
        transform="rotate(176.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="178-bisect-axis"
        className="bisect-axis"
        transform="rotate(178.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="180-bisect-axis"
        className="bisect-axis"
        transform="rotate(180.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="182-bisect-axis"
        className="bisect-axis"
        transform="rotate(182.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="184-bisect-axis"
        className="bisect-axis"
        transform="rotate(184.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="186-bisect-axis"
        className="bisect-axis"
        transform="rotate(186.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="188-bisect-axis"
        className="bisect-axis"
        transform="rotate(188.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="190-bisect-axis"
        className="bisect-axis"
        transform="rotate(190.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="192-bisect-axis"
        className="bisect-axis"
        transform="rotate(192.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="194-bisect-axis"
        className="bisect-axis"
        transform="rotate(194.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="196-bisect-axis"
        className="bisect-axis"
        transform="rotate(196.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="198-bisect-axis"
        className="bisect-axis"
        transform="rotate(198.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="200-bisect-axis"
        className="bisect-axis"
        transform="rotate(200.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="202-bisect-axis"
        className="bisect-axis"
        transform="rotate(202.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="204-bisect-axis"
        className="bisect-axis"
        transform="rotate(204.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="206-bisect-axis"
        className="bisect-axis"
        transform="rotate(206.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="208-bisect-axis"
        className="bisect-axis"
        transform="rotate(208.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="210-bisect-axis"
        className="bisect-axis"
        transform="rotate(210.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="212-bisect-axis"
        className="bisect-axis"
        transform="rotate(212.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="214-bisect-axis"
        className="bisect-axis"
        transform="rotate(214.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="216-bisect-axis"
        className="bisect-axis"
        transform="rotate(216.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="218-bisect-axis"
        className="bisect-axis"
        transform="rotate(218.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="220-bisect-axis"
        className="bisect-axis"
        transform="rotate(220.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="222-bisect-axis"
        className="bisect-axis"
        transform="rotate(222.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="224-bisect-axis"
        className="bisect-axis"
        transform="rotate(224.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="226-bisect-axis"
        className="bisect-axis"
        transform="rotate(226.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="228-bisect-axis"
        className="bisect-axis"
        transform="rotate(228.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="230-bisect-axis"
        className="bisect-axis"
        transform="rotate(230.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="232-bisect-axis"
        className="bisect-axis"
        transform="rotate(232.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="234-bisect-axis"
        className="bisect-axis"
        transform="rotate(234.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="236-bisect-axis"
        className="bisect-axis"
        transform="rotate(236.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="238-bisect-axis"
        className="bisect-axis"
        transform="rotate(238.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="240-bisect-axis"
        className="bisect-axis"
        transform="rotate(240.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="242-bisect-axis"
        className="bisect-axis"
        transform="rotate(242.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="244-bisect-axis"
        className="bisect-axis"
        transform="rotate(244.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="246-bisect-axis"
        className="bisect-axis"
        transform="rotate(246.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="248-bisect-axis"
        className="bisect-axis"
        transform="rotate(248.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="250-bisect-axis"
        className="bisect-axis"
        transform="rotate(250.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="252-bisect-axis"
        className="bisect-axis"
        transform="rotate(252.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="254-bisect-axis"
        className="bisect-axis"
        transform="rotate(254.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="256-bisect-axis"
        className="bisect-axis"
        transform="rotate(256.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="258-bisect-axis"
        className="bisect-axis"
        transform="rotate(258.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="260-bisect-axis"
        className="bisect-axis"
        transform="rotate(260.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="262-bisect-axis"
        className="bisect-axis"
        transform="rotate(262.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="264-bisect-axis"
        className="bisect-axis"
        transform="rotate(264.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="266-bisect-axis"
        className="bisect-axis"
        transform="rotate(266.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="268-bisect-axis"
        className="bisect-axis"
        transform="rotate(268.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="270-bisect-axis"
        className="bisect-axis"
        transform="rotate(270.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="272-bisect-axis"
        className="bisect-axis"
        transform="rotate(272.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="274-bisect-axis"
        className="bisect-axis"
        transform="rotate(274.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="276-bisect-axis"
        className="bisect-axis"
        transform="rotate(276.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="278-bisect-axis"
        className="bisect-axis"
        transform="rotate(278.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="280-bisect-axis"
        className="bisect-axis"
        transform="rotate(280.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="282-bisect-axis"
        className="bisect-axis"
        transform="rotate(282.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="284-bisect-axis"
        className="bisect-axis"
        transform="rotate(284.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="286-bisect-axis"
        className="bisect-axis"
        transform="rotate(286.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="288-bisect-axis"
        className="bisect-axis"
        transform="rotate(288.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="290-bisect-axis"
        className="bisect-axis"
        transform="rotate(290.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="292-bisect-axis"
        className="bisect-axis"
        transform="rotate(292.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="294-bisect-axis"
        className="bisect-axis"
        transform="rotate(294.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="296-bisect-axis"
        className="bisect-axis"
        transform="rotate(296.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="298-bisect-axis"
        className="bisect-axis"
        transform="rotate(298.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="300-bisect-axis"
        className="bisect-axis"
        transform="rotate(300.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="302-bisect-axis"
        className="bisect-axis"
        transform="rotate(302.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="304-bisect-axis"
        className="bisect-axis"
        transform="rotate(304.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="306-bisect-axis"
        className="bisect-axis"
        transform="rotate(306.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="308-bisect-axis"
        className="bisect-axis"
        transform="rotate(308.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="310-bisect-axis"
        className="bisect-axis"
        transform="rotate(310.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="312-bisect-axis"
        className="bisect-axis"
        transform="rotate(312.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="314-bisect-axis"
        className="bisect-axis"
        transform="rotate(314.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="316-bisect-axis"
        className="bisect-axis"
        transform="rotate(316.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="318-bisect-axis"
        className="bisect-axis"
        transform="rotate(318.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="320-bisect-axis"
        className="bisect-axis"
        transform="rotate(320.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="322-bisect-axis"
        className="bisect-axis"
        transform="rotate(322.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="324-bisect-axis"
        className="bisect-axis"
        transform="rotate(324.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="326-bisect-axis"
        className="bisect-axis"
        transform="rotate(326.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="328-bisect-axis"
        className="bisect-axis"
        transform="rotate(328.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="330-bisect-axis"
        className="bisect-axis"
        transform="rotate(330.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="332-bisect-axis"
        className="bisect-axis"
        transform="rotate(332.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="334-bisect-axis"
        className="bisect-axis"
        transform="rotate(334.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="336-bisect-axis"
        className="bisect-axis"
        transform="rotate(336.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="338-bisect-axis"
        className="bisect-axis"
        transform="rotate(338.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="340-bisect-axis"
        className="bisect-axis"
        transform="rotate(340.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="342-bisect-axis"
        className="bisect-axis"
        transform="rotate(342.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="344-bisect-axis"
        className="bisect-axis"
        transform="rotate(344.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="346-bisect-axis"
        className="bisect-axis"
        transform="rotate(346.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="348-bisect-axis"
        className="bisect-axis"
        transform="rotate(348.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="350-bisect-axis"
        className="bisect-axis"
        transform="rotate(350.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="352-bisect-axis"
        className="bisect-axis"
        transform="rotate(352.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="354-bisect-axis"
        className="bisect-axis"
        transform="rotate(354.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="356-bisect-axis"
        className="bisect-axis"
        transform="rotate(356.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="358-bisect-axis"
        className="bisect-axis"
        transform="rotate(358.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="360-bisect-axis"
        className="bisect-axis"
        transform="rotate(360.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="362-bisect-axis"
        className="bisect-axis"
        transform="rotate(362.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="364-bisect-axis"
        className="bisect-axis"
        transform="rotate(364.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="366-bisect-axis"
        className="bisect-axis"
        transform="rotate(366.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="368-bisect-axis"
        className="bisect-axis"
        transform="rotate(368.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="370-bisect-axis"
        className="bisect-axis"
        transform="rotate(370.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="372-bisect-axis"
        className="bisect-axis"
        transform="rotate(372.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="374-bisect-axis"
        className="bisect-axis"
        transform="rotate(374.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="376-bisect-axis"
        className="bisect-axis"
        transform="rotate(376.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="378-bisect-axis"
        className="bisect-axis"
        transform="rotate(378.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="380-bisect-axis"
        className="bisect-axis"
        transform="rotate(380.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="382-bisect-axis"
        className="bisect-axis"
        transform="rotate(382.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
      <path
        id="384-bisect-axis"
        className="bisect-axis"
        transform="rotate(384.5, 475,475)"
        d="M475 475 L335 475  Z"
      />
    </g> */}
  
    {/* <g id="house-numbers" className="house-ring__content">
      <text
        id="house-1"
        fill="currentColor"
        x={359}
        y={506}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,359,506)"
      >
        {"1"}
      </text>
      <text
        id="house-2"
        fill="currentColor"
        x={390}
        y={559}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,390,559)"
      >
        {"2"}
      </text>
      <text
        id="house-3"
        fill="currentColor"
        x={443}
        y={590}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,443,590)"
      >
        {"3"}
      </text>
      <text
        id="house-4"
        fill="currentColor"
        x={506}
        y={590}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,506,590)"
      >
        {"4"}
      </text>
      <text
        id="house-5"
        fill="currentColor"
        x={559}
        y={559}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,559,559)"
      >
        {"5"}
      </text>
      <text
        id="house-6"
        fill="currentColor"
        x={590}
        y={506}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,590,506)"
      >
        {"6"}
      </text>
      <text
        id="house-7"
        fill="currentColor"
        x={590}
        y={443}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,590,443)"
      >
        {"7"}
      </text>
      <text
        id="house-8"
        fill="currentColor"
        x={559}
        y={390}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,559,390)"
      >
        {"8"}
      </text>
      <text
        id="house-9"
        fill="currentColor"
        x={506}
        y={359}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,506,359)"
      >
        {"9"}
      </text>
      <text
        id="house-10"
        fill="currentColor"
        x={443}
        y={359}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,443,359)"
      >
        {"10"}
      </text>
      <text
        id="house-11"
        fill="currentColor"
        x={390}
        y={390}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,390,390)"
      >
        {"11"}
      </text>
      <text
        id="house-12"
        fill="currentColor"
        x={359}
        y={443}
        textAnchor="middle"
        dominantBaseline="central"
        transform="rotate(0,359,443)"
      >
        {"12"}
      </text>
    </g> */}
  <g id="house numbers" className="house-ring__content">
  <text id="house-1"
    fill="currentColor"
    x="320"
    y="516"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,312,518)"
  >
    {"1"}
  </text>
  <text id="house-2"
    fill="currentColor"
    x="361"
    y="588"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,356,593)"
  >
    {"2"}
  </text>
  <text id="house-3"
    fill="currentColor"
    x="433"
    y="629"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,431,637)"
  >
    {"3"}
  </text>
  <text id="house-4"
    fill="currentColor"
    x="516"
    y="629"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,518,637)"
  >
    {"4"}
  </text>
  <text id="house-5"
    fill="currentColor"
    x="588"
    y="588"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,593,593)"
  >
    {"5"}
  </text>
  <text id="house-6"
    fill="currentColor"
    x="629"
    y="516"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,637,518)"
  >
    {"6"}
  </text>
  <text id="house-7"
    fill="currentColor"
    x="629"
    y="433"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,637,431)"
  >
    {"7"}
  </text>
  <text id="house-8"
    fill="currentColor"
    x="588"
    y="361"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,593,356)"
  >
    {"8"}
  </text>
  <text id="house-9"
    fill="currentColor"
    x="516"
    y="320"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,518,312)"
  >
    {"9"}
  </text>
  <text id="house-10"
    fill="currentColor"
    x="433"
    y="320"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,431,312)"
  >
    {"10"}
  </text>
  <text id="house-11"
    fill="currentColor"
    x="361"
    y="361"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,356,356)"
  >
    {"11"}
  </text>
  <text id="house-12"
    fill="currentColor"
    x="320"
    y="431"
    textAnchor="middle"
    dominantBaseline="central"
    transform="rotate(0,312,431)"
  >
    {"12"}
  </text>
</g>

<g className="planet-ring__content">
<g id="Mo-p-group">
<text
        id="Mo-planet"
        className="Mo planet-ring__planet"
        fill="currentColor"
        x={MoPlanetX}
        y={MoPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MoPlanetX==null ? '0' : MoPlanetX + ',' + MoPlanetY==null ? '0' : MoPlanetY + ')'}
        >
        {MoSym}
</text>
<text
        id="Mo-sign"
        className={MoSign + " planet-ring__sign"}
        fill="currentColor"
        x={MoSignX}
        y={MoSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MoSignX==null ? '0' : MoSignX + ',' + MoSignY==null ? '0' : MoSignY + ')'}
        >
        {MoSignSym}
</text>
<text
        id="Mo-degree"
        fill="currentColor"
        x={MoDegreeX}
        y={MoDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MoDegreeX + ',' + MoDegreeY + ')'}
        >
        {MoDegree}{MoDegree == '' ? null : DegreeSym}
</text>
<text
        id="Mo-minute"
        fill="currentColor"
        x={MoMinuteX}
        y={MoMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MoMinuteX + ',' + MoMinuteY + ')'}
        >
        {MoMinute}
</text>
</g>
<g id="Ve-p-group">
<text
        id="Ve-planet"
        className="Ve planet-ring__planet"
        fill="currentColor"
        x={VePlanetX}
        y={VePlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + VePlanetX + ',' + VePlanetY + ')'}
        >
        {VeSym}
</text>
<text
        id="Ve-sign"
        className={VeSign + " planet-ring__sign"}
        fill="currentColor"
        x={VeSignX}
        y={VeSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + VeSignX + ',' + VeSignY + ')'}
        >
        {VeSignSym}
</text>
<text
        id="Ve-degree"
        className={VeRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={VeDegreeX}
        y={VeDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + VeDegreeX + ',' + VeDegreeY + ')'}
        >
        {VeDegree}{VeDegree == '' ? null : DegreeSym}
</text>
<text
        id="Ve-minute"
        className={VeRX === true ? "minute rx" : "minute"}
        fill="currentColor"
        x={VeMinuteX}
        y={VeMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + VeMinuteX + ',' + VeMinuteY + ')'}
        >
        {VeMinute}
</text>
        {VeRX === false ? null: <text
        id="Ve-rx"
        className="Ve planet-ring__planet rx"
        fill="currentColor"
        x={VeRXX}
        y={VeRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + VeRXX + ',' + VeRXY + ')'}
        >
        {RXSym}
</text>}
</g>
<g id="ASC-p-group">
<text
        id="ASC-planet"
        className="ASC planet-ring__planet"
        fill="currentColor"
        x={ASCPlanetX}
        y={ASCPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ASCPlanetX + ',' + ASCPlanetY + ')'}
        >
        {ASCSym}
</text>
<text
        id="ASC-sign"
        className={ASCSign + " planet-ring__sign"}
        fill="currentColor"
        x={ASCSignX}
        y={ASCSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ASCSignX + ',' + ASCSignY + ')'}
        >
        {ASCSignSym}
</text>
<text
        id="ASC-degree"
        fill="currentColor"
        x={ASCDegreeX}
        y={ASCDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ASCDegreeX + ',' + ASCDegreeY + ')'}
        >
        {ASCDegree}{ASCDegree == '' ? null : DegreeSym}
</text>
<text
        id="ASC-minute"
        fill="currentColor"
        x={ASCMinuteX}
        y={ASCMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ASCMinuteX + ',' + ASCMinuteY + ')'}
        >
        {ASCMinute}
</text>
</g>
<g id="Ra-p-group">
<text
        id="Ra-planet"
        className="Ra planet-ring__planet"
        fill="currentColor"
        x={RaPlanetX}
        y={RaPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + RaPlanetX + ',' + RaPlanetY + ')'}
        >
        {RaSym}
</text>
<text
        id="Ra-sign"
        className={RaSign + " planet-ring__sign"}
        fill="currentColor"
        x={RaSignX}
        y={RaSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + RaSignX + ',' + RaSignY + ')'}
        >
        {RaSignSym}
</text>
<text
        id="Ra-degree"
        className={RaRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={RaDegreeX}
        y={RaDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + RaDegreeX + ',' + RaDegreeY + ')'}
        >
        {RaDegree}{RaDegree == '' ? null : DegreeSym}
</text>
<text
        id="Ra-minute"
        className={RaRX === true ? "minute rx" : "minute"}
        fill="currentColor"
        x={RaMinuteX}
        y={RaMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + RaMinuteX + ',' + RaMinuteY + ')'}
        >
        {RaMinute}
</text>
        {RaRX === false ? null: <text
        id="Ra-rx"
        className="Ra planet-ring__planet rx"
        fill="currentColor"
        x={RaRXX}
        y={RaRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + RaRXX + ',' + RaRXY + ')'}
        >
        {RXSym}
</text>}
</g>
<g id="Su-p-group">
<text
        id="Su-planet"
        className="Su planet-ring__planet"
        fill="currentColor"
        x={SuPlanetX}
        y={SuPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + SuPlanetX + ',' + SuPlanetY + ')'}
        >
        {SuSym}
</text>
<text
        id="Su-sign"
        className={SuSign + " planet-ring__sign"}
        fill="currentColor"
        x={SuSignX}
        y={SuSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + SuSignX + ',' + SuSignY + ')'}
        >
        {SuSignSym}
</text>
<text
        id="Su-degree"
        fill="currentColor"
        x={SuDegreeX}
        y={SuDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + SuDegreeX + ',' + SuDegreeY + ')'}
        >
        {SuDegree}{SuDegree == '' ? null : DegreeSym}
</text>
<text
        id="Su-minute"
        fill="currentColor"
        x={SuMinuteX}
        y={SuMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + SuMinuteX + ',' + SuMinuteY + ')'}
        >
        {SuMinute}
</text>
</g>
<g id="Ch-p-group">
<text
        id="Ch-planet"
        className="Ch planet-ring__planet"
        fill="currentColor"
        x={ChPlanetX}
        y={ChPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ChPlanetX + ',' + ChPlanetY + ')'}
        >
        {ChSym}
</text>
<text
        id="Ch-sign"
        className={ChSign + " planet-ring__sign"}
        fill="currentColor"
        x={ChSignX}
        y={ChSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ChSignX + ',' + ChSignY + ')'}
        >
        {ChSignSym}
</text>
<text
        id="Ch-degree"
        className={ChRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={ChDegreeX}
        y={ChDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ChDegreeX + ',' + ChDegreeY + ')'}
        >
        {ChDegree}{ChDegree == '' ? null : DegreeSym}
</text>
<text
        id="Ch-minute"
        className={ChRX === true ? "minute rx" : "minute"}
        fill="currentColor"
        x={ChMinuteX}
        y={ChMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ChMinuteX + ',' + ChMinuteY + ')'}
        >
        {ChMinute}
</text>
        {ChRX === false ? null : <text
        id="Ch-rx"
        className="Ch planet-ring__planet rx"
        fill="currentColor"
        x={ChRXX}
        y={ChRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ChRXX + ',' + ChRXY + ')'}
        >
        {RXSym}
</text>}
</g>
<g id="Me-p-group">
<text
        id="Me-planet"
        className="Me planet-ring__planet"
        fill="currentColor"
        x={MePlanetX}
        y={MePlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MePlanetX + ',' + MePlanetY + ')'}
        >
        {MeSym}
</text>
<text
        id="Me-sign"
        className={MeSign + " planet-ring__sign"}
        fill="currentColor"
        x={MeSignX}
        y={MeSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MeSignX + ',' + MeSignY + ')'}
        >
        {MeSignSym}
</text>
<text
        id="Me-degree"
        className={MeRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={MeDegreeX}
        y={MeDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MeDegreeX + ',' + MeDegreeY + ')'}
        >
        {MeDegree}{MeDegree == '' ? null : DegreeSym}
</text>
<text
        id="Me-minute"
        className={MeRX === true ? "minute rx" : "minute"}
        fill="currentColor"
        x={MeMinuteX}
        y={MeMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MeMinuteX + ',' + MeMinuteY + ')'}
        >
        {MeMinute}
</text>
        {MeRX === false ? null : <text
        id="Me-rx"
        className="Me planet-ring__planet rx"
        fill="currentColor"
        x={MeRXX}
        y={MeRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MeRXX + ',' + MeRXY + ')'}
        >
        {RXSym}
</text>}
</g>
<g id="Ju-p-group">
<text
        id="Ju-planet"
        className="Ju planet-ring__planet"
        fill="currentColor"
        x={JuPlanetX}
        y={JuPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + JuPlanetX + ',' + JuPlanetY + ')'}
        >
        {JuSym}
</text>
<text
        id="Ju-sign"
        className={JuSign + " planet-ring__sign"}
        fill="currentColor"
        x={JuSignX}
        y={JuSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + JuSignX + ',' + JuSignY + ')'}
        >
        {JuSignSym}
</text>
<text
        id="Ju-degree"
        className={JuRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={JuDegreeX}
        y={JuDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + JuDegreeX + ',' + JuDegreeY + ')'}
        >
        {JuDegree}{JuDegree == '' ? null : DegreeSym}
</text>
<text
        id="Ju-minute"
        className={JuRX === true ? "minute rx" : "minute"}
        fill="currentColor"
        x={JuMinuteX}
        y={JuMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + JuMinuteX + ',' + JuMinuteY + ')'}
        >
        {JuMinute}
</text>
        {JuRX === false ? null : <text
        id="Ju-rx"
        className="Ju planet-ring__planet rx"
        fill="currentColor"
        x={JuRXX}
        y={JuRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + JuRXX + ',' + JuRXY + ')'}
        >
        {RXSym}
</text>}
</g>
<g id="Ur-p-group">
<text
        id="Ur-planet"
        className="Ur planet-ring__planet"
        fill="currentColor"
        x={UrPlanetX}
        y={UrPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + UrPlanetX + ',' + UrPlanetY + ')'}
        >
        {UrSym}
</text>
<text
        id="Ur-sign"
        className={UrSign + " planet-ring__sign"}
        fill="currentColor"
        x={UrSignX}
        y={UrSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + UrSignX + ',' + UrSignY + ')'}
        >
        {UrSignSym}
</text>
<text
        id="Ur-degree"
        className={UrRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={UrDegreeX}
        y={UrDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + UrDegreeX + ',' + UrDegreeY + ')'}
        >
        {UrDegree}{UrDegree == '' ? null : DegreeSym}
</text>
<text
        id="Ur-minute"
        className={UrRX === true ? "minute rx" : "minute"}
        fill="currentColor"
        x={UrMinuteX}
        y={UrMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + UrMinuteX + ',' + UrMinuteY + ')'}
        >
        {UrMinute}
</text>
        {UrRX === false ? null : <text
        id="Ur-rx"
        className="Ur planet-ring__planet rx"
        fill="currentColor"
        x={UrRXX}
        y={UrRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + UrRXX + ',' + UrRXY + ')'}
        >
        {RXSym}
</text>}
</g>
<g id="IC-p-group">
<text
        id="IC-planet"
        className="IC planet-ring__planet"
        fill="currentColor"
        x={ICPlanetX}
        y={ICPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ICPlanetX + ',' + ICPlanetY + ')'}
        >
        {ICSym}
</text>
<text
        id="IC-sign"
        className={ICSign + " planet-ring__sign"}
        fill="currentColor"
        x={ICSignX}
        y={ICSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ICSignX + ',' + ICSignY + ')'}
        >
        {ICSignSym}
</text>
<text
        id="IC-degree"
        fill="currentColor"
        x={ICDegreeX}
        y={ICDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ICDegreeX + ',' + ICDegreeY + ')'}
        >
        {ICDegree}{ICDegree == '' ? null : DegreeSym}
</text>
<text
        id="IC-minute"
        fill="currentColor"
        x={ICMinuteX}
        y={ICMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + ICMinuteX + ',' + ICMinuteY + ')'}
        >
        {ICMinute}
</text>
</g>
<g id="DSC-p-group">
<text
        id="DSC-planet"
        className="DSC planet-ring__planet"
        fill="currentColor"
        x={DSCPlanetX}
        y={DSCPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + DSCPlanetX + ',' + DSCPlanetY + ')'}
        >
        {DSCSym}
</text>
<text
        id="DSC-sign"
        className={DSCSign + " planet-ring__sign"}
        fill="currentColor"
        x={DSCSignX}
        y={DSCSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + DSCSignX + ',' + DSCSignY + ')'}
        >
        {DSCSignSym}
</text>
<text
        id="DSC-degree"
        fill="currentColor"
        x={DSCDegreeX}
        y={DSCDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + DSCDegreeX + ',' + DSCDegreeY + ')'}
        >
        {DSCDegree}{DSCDegree == '' ? null : DegreeSym}
</text>
<text
        id="DSC-minute"
        fill="currentColor"
        x={DSCMinuteX}
        y={DSCMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + DSCMinuteX + ',' + DSCMinuteY + ')'}
        >
        {DSCMinute}
</text>
</g>
<g id="Ke-p-group">
<text
        id="Ke-planet"
        className="Ke planet-ring__planet"
        fill="currentColor"
        x={KePlanetX}
        y={KePlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + KePlanetX + ',' + KePlanetY + ')'}
        >
        {KeSym}
</text>
<text
        id="Ke-sign"
        className={KeSign + " planet-ring__sign"}
        fill="currentColor"
        x={KeSignX}
        y={KeSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + KeSignX + ',' + KeSignY + ')'}
        >
        {KeSignSym}
</text>
<text
        id="Ke-degree"
        className={KeRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={KeDegreeX}
        y={KeDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + KeDegreeX + ',' + KeDegreeY + ')'}
        >
        {KeDegree}{KeDegree == '' ? null : DegreeSym}
</text>
<text
        id="Ke-minute"
        className={KeRX === true ? "Ke minute rx" : "Ke minute"}
        fill="currentColor"
        x={KeMinuteX}
        y={KeMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + KeMinuteX + ',' + KeMinuteY + ')'}
        >
        {KeMinute}
</text>
        {KeRX === false ? null : <text
        id="Ke-rx"
        className="Ke planet-ring__planet rx"
        fill="currentColor"
        x={KeRXX}
        y={KeRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + KeRXX + ',' + KeRXY + ')'}
        >
        {RXSym}
</text>}
</g>
<g id="MC-p-group">
<text
        id="MC-planet"
        className="MC planet-ring__planet"
        fill="currentColor"
        x={MCPlanetX}
        y={MCPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MCPlanetX + ',' + MCPlanetY + ')'}
        >
        {MCSym}
</text>
<text
        id="MC-sign"
        className={MCSign + " planet-ring__sign"}
        fill="currentColor"
        x={MCSignX}
        y={MCSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MCSignX + ',' + MCSignY + ')'}
        >
        {MCSignSym}
</text>
<text
        id="MC-degree"
        fill="currentColor"
        x={MCDegreeX}
        y={MCDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MCDegreeX + ',' + MCDegreeY + ')'}
        >
        {MCDegree}{MCDegree == '' ? null : DegreeSym}
</text>
<text
        id="MC-minute"
        fill="currentColor"
        x={MCMinuteX}
        y={MCMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MCMinuteX + ',' + MCMinuteY + ')'}
        >
        {MCMinute}
</text>
</g>
<g id="Pl-p-group">
<text
        id="Pl-planet"
        className="Pl planet-ring__planet"
        fill="currentColor"
        x={PlPlanetX}
        y={PlPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + PlPlanetX + ',' + PlPlanetY + ')'}
        >
        {PlSym}
</text>
<text
        id="Pl-sign"
        className={PlSign + " planet-ring__sign"}
        fill="currentColor"
        x={PlSignX}
        y={PlSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + PlSignX + ',' + PlSignY + ')'}
        >
        {PlSignSym}
</text>
<text
        id="Pl-degree"
        className={PlRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={PlDegreeX}
        y={PlDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + PlDegreeX + ',' + PlDegreeY + ')'}
        >
        {PlDegree}{PlDegree == '' ? null : DegreeSym}
</text>
<text
        id="Pl-minute"
        className={PlRX === true ? "minute rx" : "minute"}
        fill="currentColor"
        x={PlMinuteX}
        y={PlMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + PlMinuteX + ',' + PlMinuteY + ')'}
        >
        {PlMinute}
</text>
        {PlRX === false ? null : <text
        id="Pl-rx"
        className="Pl planet-ring__planet rx"
        fill="currentColor"
        x={PlRXX}
        y={PlRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + PlRXX + ',' + PlRXY + ')'}
        >
        {RXSym}
</text>}
</g>
<g id="Ma-p-group">
<text
        id="Ma-planet"
        className="Ma planet-ring__planet"
        fill="currentColor"
        x={MaPlanetX}
        y={MaPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MaPlanetX + ',' + MaPlanetY + ')'}
        >
        {MaSym}
</text>
<text
        id="Ma-sign"
        className={MaSign + " planet-ring__sign"}
        fill="currentColor"
        x={MaSignX}
        y={MaSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MaSignX + ',' + MaSignY + ')'}
        >
        {MaSignSym}
</text>
<text
        id="Ma-degree"
        className={MaRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={MaDegreeX}
        y={MaDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MaDegreeX + ',' + MaDegreeY + ')'}
        >
        {MaDegree}{MaDegree == '' ? null : DegreeSym}
</text>
<text
        id="Ma-minute"
        className={MaRX === true ? "minute rx" : "minute"}
        fill="currentColor"
        x={MaMinuteX}
        y={MaMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MaMinuteX + ',' + MaMinuteY + ')'}
        >
        {MaMinute}
</text>
        {MaRX === false ? null : <text
        id="Ma-rx"
        className="Ma planet-ring__planet rx"
        fill="currentColor"
        x={MaRXX}
        y={MaRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + MaRXX + ',' + MaRXY + ')'}
        >
        {RXSym}
</text>}
</g>
<g id="Sa-p-group">
<text
        id="Sa-planet"
        className="Sa planet-ring__planet"
        fill="currentColor"
        x={SaPlanetX}
        y={SaPlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + SaPlanetX + ',' + SaPlanetY + ')'}
        >
        {SaSym}
</text>
<text
        id="Sa-sign"
        className={SaSign + " planet-ring__sign"}
        fill="currentColor"
        x={SaSignX}
        y={SaSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + SaSignX + ',' + SaSignY + ')'}
        >
        {SaSignSym}
</text>
<text
        id="Sa-degree"
        className={SaRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={SaDegreeX}
        y={SaDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + SaDegreeX + ',' + SaDegreeY + ')'}
        >
        {SaDegree}{SaDegree == '' ? null : DegreeSym}
</text>
<text
        id="Sa-minute"
        className={SaRX === true ? "minute rx" : "minute"}
        fill="currentColor"
        x={SaMinuteX}
        y={SaMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + SaMinuteX + ',' + SaMinuteY + ')'}
        >
        {SaMinute}
</text>
        {SaRX === false ? null : <text
        id="Sa-rx"
        className="Sa planet-ring__planet rx"
        fill="currentColor"
        x={SaRXX}
        y={SaRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + SaRXX + ',' + SaRXY + ')'}
        >
        {RXSym}
</text>}
</g>
<g id="Ne-p-group">
<text
        id="Ne-planet"
        className="Ne planet-ring__planet"
        fill="currentColor"
        x={NePlanetX}
        y={NePlanetY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + NePlanetX + ',' + NePlanetY + ')'}
        >
        {NeSym}
</text>
<text
        id="Ne-sign"
        className={NeSign + " planet-ring__sign"}
        fill="currentColor"
        x={NeSignX}
        y={NeSignY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + NeSignX + ',' + NeSignY + ')'}
        >
        {NeSignSym}
</text>
<text
        id="Ne-degree"
        className={NeRX === true ? "degree rx" : "degree"}
        fill="currentColor"
        x={NeDegreeX}
        y={NeDegreeY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + NeDegreeX + ',' + NeDegreeY + ')'}
        >
        {NeDegree}{NeDegree == '' ? null : DegreeSym}
</text>
<text
        id="Ne-minute"
        className={NeRX === true ? "minute rx" : "minute"}
        fill="currentColor"
        x={NeMinuteX}
        y={NeMinuteY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + NeMinuteX + ',' + NeMinuteY + ')'}
        >
        {NeMinute}
</text>
        {NeRX === false ? null : <text
        id="Ne-rx"
        className="Ne planet-ring__planet rx"
        fill="currentColor"
        x={NeRXX}
        y={NeRXY}
        textAnchor="middle"
        dominantBaseline="central"
        // transform={'rotate(0,' + NeRXX + ',' + NeRXY + ')'}
        >
        {RXSym}
</text>}
</g>
</g>

<g>
    <path
      transform="translate(-22,-22) scale(1.02 1.02)"
      d="M432.83,393.14c-16.51,9.68-30.26,23.54-39.82,40.13L0,205.74C49.75,120.84,120.67,49.85,205.5,0l227.33,393.14h0Z"
      className="hover-house" id="house-11" 
    />
  </g>
  <g>
  <path 
    transform="translate(-100,-100)"
    d="M862.75,77.54l-232.73,403.23c-16.03-9.16-34.59-14.39-54.37-14.41l.83-465h.07c104.2,0,201.92,27.72,286.2,76.18Z"
    className="hover-house" id="house-9"
      />
  </g>
  <g>
  <path 
    transform="translate(-100,-100)"
    d="M575.05.86v465.16c-1.01-.04-2.03-.05-3.04-.05-19.37,0-37.57,5.03-53.45,13.89L288.05,76.86C372.78,28.48,470.71.86,575.05.86Z"
    className="hover-house" id="house-10"
      />
  </g>
  <g>
  <path 
    transform="translate(-102,-100)"
    d="M684.61,574.09c.04-1.04.05-2.07.05-3.11,0-17.84-4.19-34.69-11.66-49.6l400.45-230.87c48.06,83.81,75.83,180.87,76.55,284.39l-465.39-.8Z"
    className="hover-house" id="house-7"
    /> 
  </g>
  <g>
  <path
    transform="translate(-100,-100)" 
    d="M1072.7,287.29l-402.31,233.32s-.02-.03-.03-.05c-9.73-16.52-23.66-30.26-40.32-39.78l232.68-402.68c86.91,50.2,159.47,122.48,209.98,209.19Z"
    className="hover-house" id="house-8"
    />
  </g>

  <g>
  <path 
    transform="translate(-100,-100)"
    d="M1151.55,576.36c0,104.83-28.05,203.1-77.07,287.73l-403.58-232.84c9.32-16.16,14.65-34.9,14.65-54.89h466Z"
    className="hover-house" id="house-6"
    />
  </g>
  <g>
  <path 
    transform="translate(-100,-100)"
    d="M630.75,671.17c16.9-9.92,30.97-24.12,40.76-41.12l402.24,233.16c-50.92,87-123.51,159.75-210.33,210.84l-232.67-402.88h0Z"
    className="hover-house" id="house-5"
    />
  </g>
  <g>
  <path 
    transform="translate(-100,-100)"
    d="M575.32,1149.8v-465.16c1.01.04,2.03.05,3.04.05,19.37,0,37.57-5.03,53.45-13.89l230.51,403c-84.73,48.38-182.66,76-287,76Z"
    className="hover-house" id="house-4"
    />
    </g>
    <g>
  <path 
    transform="translate(-100,-100)"
    d="M288.22,1073.37l232.73-403.23c16.03,9.16,34.59,14.39,54.37,14.41l-.83,465h-.07c-104.2,0-201.92-27.72-286.2-76.18h0Z"
    className="hover-house" id="house-3"
    />
    </g>
    <g>
    <path 
    transform="translate(-100,-100)"
    d="M77.76,863.89l402.31-233.32s.02.03.03.05c9.73,16.52,23.66,30.26,40.32,39.78l-232.68,402.68c-86.91-50.2-159.47-122.48-209.98-209.19Z"
    className="hover-house" id="house-2"
    />
    </g>
    <g>
    <path 
    transform="translate(-100,-100)"
    d="M466.87,576.09c-.04,1.05-.04,2.1-.04,3.15.06,18.07,4.32,35.12,11.85,50.19l-400.5,235.14C29.74,779.86,1.58,681.67.51,576.84l466.36-.76h0Z"
    className="hover-house" id="house-1"
    />
    </g>
    <g>
    <path 
    transform="translate(-100,-100)"
    d="M.5,574.55c0-104.83,28.05-203.1,77.07-287.73l403.58,232.84c-9.32,16.16-14.65,34.9-14.65,54.89H.5s0,0,0,0Z"
    className="hover-house" id="house-12"
    />
    </g>
    {/* <circle 
      className="hover-chart-border"
      cx={centerX}
      cy={centerY}
      r={signBorderRadius}
      fill="currentColor"
      /> */}

   
  </svg>
)
export { ChartSvg as ReactComponent }
