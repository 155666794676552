import '../../App.css'
import '../../css/birth-entry.css'
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from '@hookform/resolvers//yup'
import { useMutation, useQuery } from "@tanstack/react-query";
//import { useQuery, useMutation } from "@tanstack/react-query";
import Axios from "axios";
import { LocationInput } from "../../components/placeinput-nom";
import { useEffect, useState } from "react";
import { Autocomplete, Box, Button, FormControl, InputLabel, ListItemText, MenuItem, Select, Stack, TextField, TextFieldProps, ThemeProvider, createTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
//import * as isLeapYear from 'dayjs/plugin/isLeapYear'


import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isLeapYear from 'dayjs/plugin/isLeapYear'
import { Fieldset } from '../../components/fieldset';
import { Chart } from '../../components/chart';
import { Today } from '@mui/icons-material';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isLeapYear)


interface Place {
    place_id: number;
    display_name: string;
    lat: string;
    lon: string;

  }

type FormData = {
    name: string;
    month: object,
    day: {day:string},
    year: {year:string},
    time: string;
    location: Place | null;
    chart_type: string;
    timzeone: string
    solar_year: string

}

//   const theme = createTheme({
//       components: {
//         // Name of the component

//         MuiAutocomplete: {
//           styleOverrides: {
//             // Name of the slot
//             root: {
//               "& .MuiAutocomplete-hasPopupIcon":{
//               // Some CSS
//               width:'33.33%', 
//             }
//           }
//         }
//     }
//     }
//   });

export const NewForm= (props:any) =>{

    const [allowedToFetch, setAllowedToFetch] = useState(true)
    const [chartType, setChartType] = useState('');
    const [place, setPlace] = useState<Place | null>(null)

    const [selectedMonth, setSelectedMonth] = useState<{month: null | string, number: null | string}>({ month: null, number: null });
    const [selectedYear, setSelectedYear] = useState<{year: null | string}>({ year: null});
    const [selectedDay, setSelectedDay] = useState<{day: null | string}>({ day: null});
    const [selectedSolarYear, setSelectedSolarYear] = useState<string> ('');

    //let chart_type=""

    function daysArray (n: number) {
        let arr = []
        for (var val of Array.from(Array(n).keys())) {
            if (val === 0){continue}
            arr.push({"day": val.toString()})
        }
        return arr
    }

    function yearsArray(): any{ 
        let arr = []
        let n = dayjs().year() - 1900
        for (var val of Array.from(Array(n+101).keys()).map(i => i + 1900)){
            arr.push({"year": val.toString()})
            
        }
        return arr
    }

    function solarYearsArray(): any{ 
        let arr = []
        let n = dayjs().year() - 1900
        for (var val of Array.from(Array(n+101).keys()).map(i => i + 1900)){
            arr.push(val.toString())
        }
        return arr
    }

    const dates = [
        { month: "January", number: 1 },
        { month: "February", number: 2  },
        { month: "March" , number: 3 },
        { month: "April", number: 4},
        { month: "May", number: 5 },
        { month: "June", number: 6},
        { month: "July", number: 7},
        { month: "August", number: 8},
        { month: "September", number: 9},
        { month: "October", number: 10},
        { month: "November", number: 11},
        { month: "December", number: 12}
    ]

      const years = yearsArray()
      const solar_years = solarYearsArray()

    const filterOptions = () => {
        let dayOptions:{day:string}[] = []
        let n = 0

        if(selectedMonth.month == "February")
            if (selectedYear.year !== null)
                if(!(dayjs(selectedYear.year).isLeapYear()))
                    {n = 29}
                else{n=30}
            else {n=30}
        else
            switch(selectedMonth.month){
                case "January":
                    n = 32
                    break;
                case "March":
                    n = 32
                    break;
                case "April":
                    n = 31
                    break;
                case "May":
                    n = 32
                    break;
                case "June":
                    n = 31
                    break;
                case "July":
                    n = 32
                    break;
                case "August":
                    n = 32
                    break;
                case "September":
                    n = 31
                    break;
                case "October":
                    n = 32
                    break;
                case "November":
                    n = 31
                    break;
                case "December":
                    n = 32
                    break; 
                default:
                    n = 32

            }
        

        dayOptions = daysArray(n)
        return dayOptions
    }
    
    const leapYearError= () : boolean =>{

        if (selectedMonth.month === "February" && selectedYear.year !== null)
            if (!(dayjs(selectedYear.year).isLeapYear()) && selectedDay.day === "29")
                {return true}
            else
                {return false}
        else
            {return false}
        
        }

        const days = 
        //daysArray(32)
        filterOptions()

    

    const schema = yup.object().shape({
        name: yup.string().required(),
        month: yup.object().shape({
            month: yup.string(),
            number: yup.number()
          }),
        day: yup.object().shape({
            day: yup.string()
          }),
        year: yup.object().shape({
            year: yup.string()
          }),
        //date: yup.string().required(),
        time: yup.string().required(),
        // location: yup.mixed<Place>().required()
        chart_type: yup.string().required(),
        solar_year: yup.string()
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
      } = useForm<FormData>({
        resolver: yupResolver(schema)
      });


    const {data: geoname, error: err2, isLoading: load} = useQuery(
        {
        queryKey:['geoname'],
        queryFn: () => getGeoName(),
        enabled: allowedToFetch && place?.lat !== undefined && place?.lon !== undefined
        }
    )
    
    const getGeoName = () => fetch(`http://api.geonames.org/timezoneJSON?lat=${place?.lat}&lng=${place?.lon}&username=service.planetgazer`).then((res) => res.json())
    

    const { data: chart, isSuccess, mutate } = useMutation({

        mutationFn: (chartData: any) => 
            (process.env.NODE_ENV=='production') ?
    fetch(`http://planetgazer.net:8000/chart/${chartData.chart_type}/${selectedSolarYear}`,
        {
        method:"POST",
        body: JSON.stringify(chartData),
        headers: {"Content-type": "application/json; charset=UTF-8"}
    }).then((res) => res.json())
    :
    fetch(`http://localhost:8000/chart/${chartData.chart_type}/${selectedSolarYear}`,
        {
        method:"POST",
        body: JSON.stringify(chartData),
        headers: {"Content-type": "application/json; charset=UTF-8"}
    }).then((res) => res.json())
            
    });

            // fetch(`http://localhost/api/chart/${chartData.chart_type}/${selectedSolarYear}`,
            // {
            //     method:"POST",
            //     body: JSON.stringify(chartData),
            //     headers: {"Content-type": "application/json; charset=UTF-8"}
            // }).then((res) => res.json()),

    const onSubmit = (formData: FormData) => {
        
        
        const monthObj:any  = formData.month
        const dayObj:any = formData.day
        const yearObj:any = formData.year
        const time: any = formData.time

        const local_datetime = `${yearObj.year}-${monthObj.number}-${dayObj.day}T${time}:00`
        const chartData = {name: formData.name, datetime: local_datetime, 
            location: place, timezone: geoname?.timezoneId, chart_type: formData.chart_type}
    
        console.log(formData)
        console.log(chartData);

        mutate(chartData)

    }
    console.log(place)
    console.log(chart)

    useEffect(() => {
        if (isSuccess){
            props.getSuccess()
            props.getChartSummary({
                name: chart?.info.name,
                utc: chart?.info.utc_datetime,
                local: chart?.info.local_datetime,
                location: chart?.info.location,
                chart_type: chart?.info.chart_type,
                lat: chart?.info.lat,
                long: chart?.info.long,
                timezone: chart?.info.timezone
        })
        if(!!chart.info.solar_return)
            props.setIsSolarReturn(true)
            props.getSolarReturnSummary({
                solar_return: chart?.info.solar_return,
                utc: chart?.info.solar_utc,
                local: chart?.info.solar_datetime,
                location: chart?.info.location
    })
    }
    props.setDegreeSym('°')
    if (chart !== null || chart !== undefined)
       
    props.setSignVals({
        AriSignX: chart?.signs[0].coord[0],
        AriSignY: chart?.signs[0].coord[1],
        TauSignX: chart?.signs[1].coord[0],
        TauSignY: chart?.signs[1].coord[1],
        GemSignX: chart?.signs[2].coord[0],
        GemSignY: chart?.signs[2].coord[1],
        CanSignX: chart?.signs[3].coord[0],
        CanSignY: chart?.signs[3].coord[1],
        LeoSignX: chart?.signs[4].coord[0],
        LeoSignY: chart?.signs[4].coord[1],
        VirSignX: chart?.signs[5].coord[0],
        VirSignY: chart?.signs[5].coord[1],
        LibSignX: chart?.signs[6].coord[0],
        LibSignY: chart?.signs[6].coord[1],
        ScoSignX: chart?.signs[7].coord[0],
        ScoSignY: chart?.signs[7].coord[1],
        SagSignX: chart?.signs[8].coord[0],
        SagSignY: chart?.signs[8].coord[1],
        CapSignX: chart?.signs[9].coord[0],
        CapSignY: chart?.signs[9].coord[1],
        AquSignX: chart?.signs[10].coord[0],
        AquSignY: chart?.signs[10].coord[1],
        PisSignX: chart?.signs[11].coord[0],
        PisSignY: chart?.signs[11].coord[1]

    })

    chart?.planets.map((p: any) => { 


        if (p.planet == "Mo") {
            props.setMoVals({  
                MoSym:"R", 
                MoPlanetX: p.coord[0][0],
                MoPlanetY: p.coord[0][1],
                MoSign: p.sign,
                MoSignSym: p.signSymbol,
                MoSignX: p.coord[2][0],
                MoSignY: p.coord[2][1],
                MoDegree: p.degree,
                MoDegreeX: p.coord[1][0],
                MoDegreeY: p.coord[1][1],
                MoMinute: p.minute,
                MoMinuteX: p.coord[3][0],
                MoMinuteY: p.coord[3][1],
            })
        }
        if (p.planet == "Me") { 
            props.setMeVals({
                MeSym:"S",          
                MePlanetX: p.coord[0][0],
                MePlanetY: p.coord[0][1],
                MeSign: p.sign,
                MeSignSym: p.signSymbol,
                MeSignX: p.coord[2][0],
                MeSignY: p.coord[2][1],
                MeDegree: p.degree,
                MeDegreeX: p.coord[1][0],
                MeDegreeY: p.coord[1][1],
                MeMinute: p.minute,
                MeMinuteX: p.coord[3][0],
                MeMinuteY: p.coord[3][1],
                MeRX:  p.rx,
                MeRXX: p.coord[4][0],
                MeRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ve") { 
            props.setVeVals({    
                VeSym:"T",      
                VePlanetX: p.coord[0][0],
                VePlanetY: p.coord[0][1],
                VeSign: p.sign,
                VeSignSym: p.signSymbol,
                VeSignX: p.coord[2][0],
                VeSignY: p.coord[2][1],
                VeDegree: p.degree,
                VeDegreeX: p.coord[1][0],
                VeDegreeY: p.coord[1][1],
                VeMinute: p.minute,
                VeMinuteX: p.coord[3][0],
                VeMinuteY: p.coord[3][1],
                VeRX:  p.rx,
                VeRXX: p.coord[4][0],
                VeRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Su") { 
            props.setSuVals({ 
                SuSym:p.planetSymbol,         
                SuPlanetX: p.coord[0][0],
                SuPlanetY: p.coord[0][1],
                SuSign: p.sign,
                SuSignSym: p.signSymbol,
                SuSignX: p.coord[2][0],
                SuSignY: p.coord[2][1],
                SuDegree: p.degree,
                SuDegreeX: p.coord[1][0],
                SuDegreeY: p.coord[1][1],
                SuMinute: p.minute,
                SuMinuteX: p.coord[3][0],
                SuMinuteY: p.coord[3][1],
                SuRX: p.coord[4][0],
                SuRY: p.coord[4][1]
            })
        }
        
        if (p.planet == "Ma") { 
            props.setMaVals({
                MaSym:"U",          
                MaPlanetX: p.coord[0][0],
                MaPlanetY: p.coord[0][1],
                MaSign: p.sign,
                MaSignSym: p.signSymbol,
                MaSignX: p.coord[2][0],
                MaSignY: p.coord[2][1],
                MaDegree: p.degree,
                MaDegreeX: p.coord[1][0],
                MaDegreeY: p.coord[1][1],
                MaMinute: p.minute,
                MaMinuteX: p.coord[3][0],
                MaMinuteY: p.coord[3][1],
                MaRX: p.rx,
                MaRXX: p.coord[4][0],
                MaRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ju") { 
            props.setJuVals({ 
                JuSym:"V",         
                JuPlanetX: p.coord[0][0],
                JuPlanetY: p.coord[0][1],
                JuSign: p.sign,
                JuSignSym: p.signSymbol,
                JuSignX: p.coord[2][0],
                JuSignY: p.coord[2][1],
                JuDegree: p.degree,
                JuDegreeX: p.coord[1][0],
                JuDegreeY: p.coord[1][1],
                JuMinute: p.minute,
                JuMinuteX: p.coord[3][0],
                JuMinuteY: p.coord[3][1],
                JuRX: p.rx,
                JuRXX: p.coord[4][0],
                JuRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Sa") { 
            props.setSaVals({
                SaSym:"W",          
                SaPlanetX: p.coord[0][0],
                SaPlanetY: p.coord[0][1],
                SaSign: p.sign,
                SaSignSym: p.signSymbol,
                SaSignX: p.coord[2][0],
                SaSignY: p.coord[2][1],
                SaDegree: p.degree,
                SaDegreeX: p.coord[1][0],
                SaDegreeY: p.coord[1][1],
                SaMinute: p.minute,
                SaMinuteX: p.coord[3][0],
                SaMinuteY: p.coord[3][1],
                SaRX: p.rx,
                SaRXX: p.coord[4][0],
                SaRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ur") { 
            props.setUrVals({  
                UrSym:"X",        
                UrPlanetX: p.coord[0][0],
                UrPlanetY: p.coord[0][1],
                UrSign: p.sign,
                UrSignSym: p.signSymbol,
                UrSignX: p.coord[2][0],
                UrSignY: p.coord[2][1],
                UrDegree: p.degree,
                UrDegreeX: p.coord[1][0],
                UrDegreeY: p.coord[1][1],
                UrMinute: p.minute,
                UrMinuteX: p.coord[3][0],
                UrMinuteY: p.coord[3][1],
                UrRX: p.rx,
                UrRXX: p.coord[4][0],
                UrRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ne") { 
            props.setNeVals({  
                NeSym:"Y",        
                NePlanetX: p.coord[0][0],
                NePlanetY: p.coord[0][1],
                NeSign: p.sign,
                NeSignSym: p.signSymbol,
                NeSignX: p.coord[2][0],
                NeSignY: p.coord[2][1],
                NeDegree: p.degree,
                NeDegreeX: p.coord[1][0],
                NeDegreeY: p.coord[1][1],
                NeMinute: p.minute,
                NeMinuteX: p.coord[3][0],
                NeMinuteY: p.coord[3][1],
                NeRX: p.rx,
                NeRXX: p.coord[4][0],
                NeRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Pl") { 
            props.setPlVals({   
                PlSym:"Z",       
                PlPlanetX: p.coord[0][0],
                PlPlanetY: p.coord[0][1],
                PlSign: p.sign,
                PlSignSym: p.signSymbol,
                PlSignX: p.coord[2][0],
                PlSignY: p.coord[2][1],
                PlDegree: p.degree,
                PlDegreeX: p.coord[1][0],
                PlDegreeY: p.coord[1][1],
                PlMinute: p.minute,
                PlMinuteX: p.coord[3][0],
                PlMinuteY: p.coord[3][1],
                PlRX: p.rx,
                PlRXX: p.coord[4][0],
                PlRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ra") { 
            props.setRaVals({
                RaSym:"<",          
                RaPlanetX: p.coord[0][0],
                RaPlanetY: p.coord[0][1],
                RaSign: p.sign,
                RaSignSym: p.signSymbol,
                RaSignX: p.coord[2][0],
                RaSignY: p.coord[2][1],
                RaDegree: p.degree,
                RaDegreeX: p.coord[1][0],
                RaDegreeY: p.coord[1][1],
                RaMinute: p.minute,
                RaMinuteX: p.coord[3][0],
                RaMinuteY: p.coord[3][1],
                RaRX: p.rx,
                RaRXX: p.coord[4][0],
                RaRXY: p.coord[4][1],
                RXSym: 'M' 
            })
        }
        if (p.planet == "Ke") { 
            props.setKeVals({ 
                KeSym:">",         
                KePlanetX: p.coord[0][0],
                KePlanetY: p.coord[0][1],
                KeSign: p.sign,
                KeSignSym: p.signSymbol,
                KeSignX: p.coord[2][0],
                KeSignY: p.coord[2][1],
                KeDegree: p.degree,
                KeDegreeX: p.coord[1][0],
                KeDegreeY: p.coord[1][1],
                KeMinute: p.minute,
                KeMinuteX: p.coord[3][0],
                KeMinuteY: p.coord[3][1],
                KeRX: p.rx,
                KeRXX: p.coord[4][0],
                KeRXY: p.coord[4][1]
            })
        }
        if (p.planet == "Ch") { 
            props.setChVals({  
                ChSym:"t",        
                ChPlanetX: p.coord[0][0],
                ChPlanetY: p.coord[0][1],
                ChSign: p.sign,
                ChSignSym: p.signSymbol,
                ChSignX: p.coord[2][0],
                ChSignY: p.coord[2][1],
                ChDegree: p.degree,
                ChDegreeX: p.coord[1][0],
                ChDegreeY: p.coord[1][1],
                ChMinute: p.minute,
                ChMinuteX: p.coord[3][0],
                ChMinuteY: p.coord[3][1],
                ChRX: p.rx,
                ChRXX: p.coord[4][0],
                ChRXY: p.coord[4][1]
            })
        }
        if (p.planet == "ASC") { 
            props.setASCVals({
                ASCSym:"a",          
                ASCPlanetX: p.coord[0][0],
                ASCPlanetY: p.coord[0][1],
                ASCSign: p.sign,
                ASCSignSym: p.signSymbol,
                ASCSignX: p.coord[2][0],
                ASCSignY: p.coord[2][1],
                ASCDegree: p.degree,
                ASCDegreeX: p.coord[1][0],
                ASCDegreeY: p.coord[1][1],
                ASCMinute: p.minute,
                ASCMinuteX: p.coord[3][0],
                ASCMinuteY: p.coord[3][1]
            })

        }
        if (p.planet == "DSC") { 
            props.setDSCVals({
                DSCSym:"DC",          
                DSCPlanetX: p.coord[0][0],
                DSCPlanetY: p.coord[0][1],
                DSCSign: p.sign,
                DSCSignSym: p.signSymbol,
                DSCSignX: p.coord[2][0],
                DSCSignY: p.coord[2][1],
                DSCDegree: p.degree,
                DSCDegreeX: p.coord[1][0],
                DSCDegreeY: p.coord[1][1],
                DSCMinute: p.minute,
                DSCMinuteX: p.coord[3][0],
                DSCMinuteY: p.coord[3][1]
            })
        }
        if (p.planet == "IC") { 
            props.setICVals({ 
                ICSym:"c",         
                ICPlanetX: p.coord[0][0],
                ICPlanetY: p.coord[0][1],
                ICSign: p.sign,
                ICSignSym: p.signSymbol,
                ICSignX: p.coord[2][0],
                ICSignY: p.coord[2][1],
                ICDegree: p.degree,
                ICDegreeX: p.coord[1][0],
                ICDegreeY: p.coord[1][1],
                ICMinute: p.minute,
                ICMinuteX: p.coord[3][0],
                ICMinuteY: p.coord[3][1]
            })
        }
        if (p.planet == "MC") { 
            props.setMCVals({ 
                MCSym:"b",         
                MCPlanetX: p.coord[0][0],
                MCPlanetY: p.coord[0][1],
                MCSign: p.sign,
                MCSignSym: p.signSymbol,
                MCSignX: p.coord[2][0],
                MCSignY: p.coord[2][1],
                MCDegree: p.degree,
                MCDegreeX: p.coord[1][0],
                MCDegreeY: p.coord[1][1],
                MCMinute: p.minute,
                MCMinuteX: p.coord[3][0],
                MCMinuteY: p.coord[3][1]
            })
        }
   
    })

    },[chart])

    return (
        <>
        {/* <ThemeProvider theme={theme}> */}
        {/* <FormControl onSubmit={handleSubmit(onSubmit)} sx={{width: '35%', m:2}}> */}
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextField className="newForm" label="Name/Event" required {...register("name")} InputLabelProps={{ shrink: true }}  sx={{ m: 1, width: '100%'}}/>
            <Fieldset className='date-group'
                title={"Date"}
                titleSize="0.75rem"
                borderColor="initial"
                color = "rgba(0, 0, 0, 0.6)"
                sx ={{p:3, m:1, width:'89%'}}
            >
                    <Controller 
                        name="month"
                        control={control}
                        
                        render={({field: { ref, ...fieldProps }}) =>  (
                            <Autocomplete  
                                disableClearable
                                getOptionLabel={(date) => date.month }
                                options={dates}
                                isOptionEqualToValue={(option, value) => option.month === value.month}
                                sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" }, width: '30%' }}
                                renderInput={(params) => 
                                    <TextField {...params} label="Month"
                                    required 
                                    
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ }}
                                    
                            />}
                                onChange={(e, newValue:any) => {
                                    setSelectedMonth(newValue)
                                    fieldProps.onChange(newValue)
                                }}
                            
                            />
                        )}
                        />
                    <Controller 
                        name="day"
                        control={control}
                        
                        render={({field: { ref, ...fieldProps }}) =>  (
                   
                            <Autocomplete 
                                {...fieldProps}
                                disableClearable
                                options={days}
                                isOptionEqualToValue={(option, value) => option.day === value.day}
                                getOptionLabel={(day) => day.day}
                                //filterOptions={filterOptions}
                                sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" } , width: '30%'}}
                                //isOptionEqualToValue={(option, selectedMonth) => option.month === selectedMonth.month}
                        //         renderOption={(props, option) => (
                        //             <li {...props} key={option.day}>
                        //               <ListItemText>{option.day}</ListItemText>
                        //             </li>
                        //   )}
                                renderInput={(params) => 
                                    <TextField {...params} label="Day"
                                    required 
                                    //fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    sx={{}}
                                    error={leapYearError()}
                                    helperText={leapYearError() ? "Invalid date" : ""}
                                    />}
                                onChange={(e, newValue:any) => {
                                    setSelectedDay(newValue)
                                    fieldProps.onChange(newValue)
                                 }}
                                />
                            )}
                            />
                    <Controller 
                        name="year"
                        control={control}
                        
                        render={({field: { ref, ...fieldProps }}) =>  (
                   
                            <Autocomplete  
                                {...fieldProps}
                                filterSelectedOptions
                                disableClearable
                                options={years}
                                getOptionLabel={(year) => year.year }
                                isOptionEqualToValue={(option, value) => option.year === value.year}
                                sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" }, width: '30%' }}
                                renderInput={(params) => 
                                    <TextField {...params} label="Year"
                                    required 
                                    //fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    sx={{}}

                                    />}
                                onChange={(e, newValue:any) => {
                                    setSelectedYear(newValue)
                                    fieldProps.onChange(newValue)
                                }}
                                
                            />
                        )}
                        />
               
            </Fieldset>
            <TextField type="time" label="Time" required placeholder="Time" {...register("time")}
            InputLabelProps={{ shrink: true }}
            sx={{ m: 1, width:'100%'}}
            /> 
            
            <Controller 
             name="location"
             control={control}
             
             render={({field: { ref, ...fieldProps }}) =>  (
                <LocationInput 
                //{...register("location")}
                
                inputRef={ref}
                {...fieldProps}
                setPlace={setPlace}
                />
                
                    )}
            
           
             />
       
             <Controller 
             name="chart_type"
             control={control}
            render={({field: { ref, ...fieldProps }}) =>  (
            <FormControl sx={{ m: 1, width:'100%'}} placeholder="Select"  id="form-control-chart" >
            <InputLabel shrink id="chart-type-label">Chart Type</InputLabel>
            <Select
            required 
            notched 
            displayEmpty
            inputRef={ref}
            {...fieldProps}
            id="chart-type"
            value={chartType}
            label="Chart Type"
            onChange={(event: any) => {
                setChartType(event.target.value)
                fieldProps.onChange(event.target.value)
                
            }}
            renderValue={(value) => (value ? <span>{value}</span> : <span style={{color: 'rgb(150, 150, 150'}}>Select</span>)}
            
        >
            <MenuItem value={"Natal"}>Natal</MenuItem>
            <MenuItem value={"Solar Return"}>Solar Return</MenuItem>

        </Select>
        </FormControl>
            )}
            
           
            />
        <Controller 
            name="solar_year"
            control={control}
            
            render={({field: { ref, ...fieldProps }}) =>  (
        
                <Autocomplete  
                    {...fieldProps}
                    filterSelectedOptions
                    disableClearable
                    options={solar_years}
                    //defaultValue={new Date().getFullYear().toString()} 
                    getOptionLabel={(year) => year }
                    isOptionEqualToValue={(option, value) => option === value}
                    sx={{ m: 1, width:'100%', display: chartType == 'Solar Return' ? 'block' : 'none'}}
                    //sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" }, width: '30%' }}
                    renderInput={(params) => 
                        <TextField {...params} label="Solar Year"
                        //fullWidth
                        InputLabelProps={{ shrink: true }}
                        //sx={{}}

                        />}
                    onChange={(e, newValue:any) => {
                        setSelectedSolarYear(newValue)
                        fieldProps.onChange(newValue)
                    }}
                    
                />
            )}
            />
           
        <Button sx={{ m: 1, width:'20%'}} type="submit" variant="contained" color="primary">Go</Button>

        </form>
        {/* </ThemeProvider> */}
        
        </>
        )}
    

        


    // const dates = [
    //     { month: "January", day: Array.from(Array(1,31).keys()) },
    //     { month: "February", day: Array.from(Array(1,28+1).keys()) },
    //     { month: "March", day: Array.from(Array(1,31).keys()) },
    //     { month: "April", day: Array.from(Array(1,30).keys()) },
    //   ];

    /*const {register, handleSubmit} = useForm<FormData>({
        resolver: yupResolver(schema)
    });


    
       
    // const {data: chart, error, isLoading} = useQuery(
    //     { 
    //     queryKey: ['chart'],
    //     queryFn: () => loadPlanets(),
    //     enabled: !!ipq && !!geoloc && allowedToFetch
    //     });
    
    // const loadPlanets = () =>
    //     fetch(`http://localhost/api/chart/?date=${utc_datetime}&dst=${dst}&lat=${lat.current}&long=${long.current}`).then((res) => res.json()
    // )


    // const newChartMutation = useMutation({
    //     mutationFn: (chartData: FormData) => {
    //         return Axios.post("http://localhost/api/api/v1/getchart/", chartData).then((res) => console.log(res.data))
    //     },

    // });

    if(newChartMutation.isLoading){
        return <div>Loading...</div>
    }


    if(chartQuery.isError){
        return <pre>{JSON.stringify(chartQuery.error)}</pre>
    }

    function wait(duration: any){
        return new Promise(resolve => setTimeout(resolve, duration))
      }

    const chartQuery = useQuery({
        {  data, isLoading }
        queryKey: ["newchart"],
        queryFn: () => {
        return Axios.get("http://localhost/api").then((res) => console.log(res.data[1][0].description));
        return Axios.get("http://localhost/api").then((res) => res.data[1][0])
}});*/

